import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessibilityService } from '../shared/services/accessibility.service';
import { GameService } from '../shared/services/game.service';

@Component({
  selector: 'app-ending-page',
  templateUrl: './ending-page.component.html',
  styleUrls: ['./ending-page.component.scss']
})
export class EndingPageComponent implements OnInit, OnDestroy {
  public gameType: string = this.gameService.getGameType();  
  public isHighContrast = false;
  private subContrast: Subscription;

  constructor(
    private gameService: GameService,
    private accessibilityService: AccessibilityService
  ) {
    this.subContrast = this.accessibilityService.onContrast().subscribe(value => {
      this.isHighContrast = value;      
    });
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy() {
    this.subContrast.unsubscribe();
  }

  goTo() {
    location.replace("https://www.centrumjp2.pl/badanie-podziel-sie-swoja-opinia-o-dzialalnosci-centrum-mysli-jana-pawla-ii/"); 
  }
}
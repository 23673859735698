import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-landing2022',
  templateUrl: './landing2022.component.html',
  styleUrls: ['./landing2022.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Landing2022Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

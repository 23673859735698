import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { GameService } from '../shared/services/game.service';

@Component({
  selector: 'app-map-page',
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss']
})
export class MapPageComponent implements OnInit {
  public isMobile: boolean = false;
  public gameType: string = this.gameService.getGameType();
  public isDescriptionHidden: string = this.gameService.getDesctiptionHidden();
  public showDialogFinish: boolean = false;
  public showDialogYellow: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private gameService: GameService,
    private renderer: Renderer2
  ) {
    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.renderer.addClass(document.body, 'map-page');
    this.renderer.removeClass(document.body, 'game-page');
  }


  // constructor(
  //   private gameService: GameService,

  // ) {
  //   this.renderer.addClass(document.body, 'game-page');
  // }

  ngOnInit(): void {
    this.showDialogFinish = (!this.gameService.isShowedDialog && this.gameService.gamesCompleted());
  }

  markerClicked(point) {
    this.gameService.setDescriptionHidden();
    if (point.id === 6 && !this.gameService.gamesCompleted()) {
      this.showDialogYellow = true;
    } else {
      this.router.navigate(['/game-'+point.id]);
    }
  }

  showDescription() {
    this.isDescriptionHidden = 'false';
  }

  hideDescription() {
    this.isDescriptionHidden = 'true';
  }

  closeDialogFinish(event) {    
    this.gameService.isShowedDialog = true;  
    this.showDialogFinish = false;
  }

  closeDialogYellow(event) {
    this.showDialogYellow = false;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing2017',
  templateUrl: './landing2017.component.html',
  styleUrls: ['./landing2017.component.scss']
})
export class Landing2017Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessibilityService } from 'src/app/shared/services/accessibility.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {
  public subContrast: Subscription;
  public isHighContrast = false;

  constructor(private accessibilityService: AccessibilityService) {
    this.subContrast = this.accessibilityService.onContrast().subscribe(value => {
      this.isHighContrast = value;      
    });
  }

  ngOnDestroy() {
    this.subContrast.unsubscribe();
  }

  ngOnInit(): void {
  }

}

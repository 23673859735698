import { Component, OnInit, Renderer2 } from '@angular/core';
import { AccessibilityService } from '../../services/accessibility.service';

@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss']
})
export class AccessibilityComponent implements OnInit {
  public letterActive = this.accessibillityService.read('letter');
  public contrastActive = this.accessibillityService.read('contrast');

  constructor(
    public accessibillityService: AccessibilityService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this._updateClasses();
    // public letterActive = this.accessibillityService.read('letter');
    // public contrastActive = this.accessibillityService.read('contrast');
  }

  changeLetter() {
    this.letterActive = !this.letterActive;
    this.accessibillityService.write('letter', this.letterActive.toString());
    this._updateClasses();    
  }  

  changeContrast() {
    this.contrastActive = !this.contrastActive;
    this.accessibillityService.write('contrast', this.contrastActive.toString());
    this._updateClasses();
  } 
  
  private _updateClasses() {
    if (this.contrastActive) {
      this.accessibillityService.setContrast(true);
      this.renderer.addClass(document.body, 'big-contrast');
    } else {
      this.accessibillityService.setContrast(false);
      this.renderer.removeClass(document.body, 'big-contrast');
    }
    if (this.letterActive) {
      this.renderer.addClass(document.body, 'big-letter');
    } else {
      this.renderer.removeClass(document.body, 'big-letter');
    }
  }
}

<div class="game-menu">
    <div class="left-side">
        <h1>Gra o Wolność</h1>
        <h2>edycja 2022</h2>
    </div>
    <div class="center-side">
        <p>
          Czy wiecie, że istnieją takie miejsca, w których idea solidarności jest zupełnie nieznana? W jednym z nich mieszka Norbert,
          bohater Gry o Wolność z 2022 roku. Gracze, w ramach rozrywki przypomnieli sobie wartości związane z solidarnością, jak pomoc,
          szacunek, równość i wspólnota. Wszystko po to, aby móc lepiej wyjaśnić znaczenie pojęcia chłopcu.
        </p>
    </div>
    <div class="right-side">
      <a mat-raised-button
        class="button"
        routerLink="/gra-2022"
        alt="przejście do szerszego opisu edycji"
        title="przejście do szerszego opisu edycji"
        alt="przejście do szerszego opisu edycji"
        color="primary">Dowiedz się więcej</a>
        <!-- <a mat-raised-button
            class="button"
            href="http://edycja2020.graowolnosc.pl/"
            alt="przejście do gry edycja 2020"
            title="przejście do gry edycja 2020"
            color="primary"
            target="_blank">Przejdź do gry</a> -->
    </div>
</div>

<mat-grid-list cols="12">
    <mat-grid-tile [colspan]="6">
        <button mat-raised-button 
            color="primary"
            (click)="goGameAtHome();">Gra w domu</button>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="6">
        <button mat-raised-button 
            color="primary"
            (click)="goGameToOutdoor();">Gra miejska</button>
    </mat-grid-tile>
</mat-grid-list>
<div class="page-game-2017">
    <div class="content-page-header">
        <app-landing-header [showLogo]="false" [isHighContrast]=isHighContrast></app-landing-header>
    </div>
    <div class="content-page main">
        <div class="content-page-image"></div>
        <div class="content-relation">
            <h1>Edycja 2017</h1>
            <p>
                Gra miejska rozegrana rok przed setną rocznicą odzyskania przez Polskę niepodległości miała przybliżyć graczom najważniejsze wydarzenia z historii Polski, które wpisały się w drogę do niepodległości. Podczas rozgrywki uczestnicy mogli przybliżyć sobie 10 wydarzeń, które wyznaczały drogi Polaków do wolności, m.in. Chrzest Polski, Potop Szwedzki, Bitwa Warszawska i Powstanie Warszawskie. Zapoznali się także ze spojrzeniem Jana Pawła II na sprawę wolności i historii narodu polskiego.
            </p>
            <p>
                Wśród zadań stojących przed uczestnikami gry były m.in. zagranie i odgadnięcie melodii pieśni „Legiony”, ułożenie puzzli z mapą rozbiorów Polski, skompletowanie ekwipunku żołnierza Bitwy Warszawskiej, opatrzenie rannego Powstańca czy wstawienie brakujących słów do tablicy z postulatami strajkujących stoczniowców 1980 roku.
            </p>
            <p>
                Gra zrealizowana została w 99. rocznicę odzyskania przez Polskę niepodległości, w sobotę, 11 listopada 2017 r., w śródmieściu Warszawy.
            </p>

        </div>

        <div class="content-button center">
<!--
            <a mat-raised-button
                class="button"
                href="../../assets/game-2017/kartygry.zip"
                target="_blank"
                alt="pobierz karty gry edycja 2017"
                title="pobierz karty gry edycja 2017"
                color="primary">Pobierz karty gry</a> -->

              <a mat-raised-button
                class="button"
                routerLink="/"
                alt="powrót na stronę główną"
                title="powrót na stronę główną"
                color="primary">Powrót do strony głównej</a>
        </div>

        <!-- <div class="content-foto">
            <img *ngIf="showImage(1)" src="./assets/game-2017/1200-1.jpg" alt=""/>
            <img *ngIf="showImage(2)" src="./assets/game-2017/1200-2.jpg" alt=""/>
            <img *ngIf="showImage(3)" src="./assets/game-2017/1200-3.jpg" alt=""/>
        </div> -->
        <div class="content-fotos">
            <div>
            <!-- <div (click)="setImage(1);"> -->
                <img src="./assets/game-2017/400-1.jpg" alt="Cztery osoby w kurtkach stoją przed keyboardem. Jedna z nich gra na nim."/>
            </div>
            <div>
            <!-- <div (click)="setImage(2);"> -->
                <img src="./assets/game-2017/400-2.jpg" alt="Kobieta w stroju osiemnastowiecznym i peruce stoi przed drewnianą planszą stojącą na stole. Tłumaczy coś stojącym przed nią osobom w kurtkach."/>
            </div>
            <div>
            <!-- <div (click)="setImage(3);"> -->
                <img src="./assets/game-2017/400-3.jpg" alt="Grupa ludzi stoi wokół znaku Polski Walczącej, który przyczepiony jest do tablicy. Część osób ubrana jest w stroje wojskowe. Mają biało-czerwone opaski na ramionach. Pozostałe osoby stoją w kurtkach."/>
            </div>
        </div>
    </div>
    <div class="content-page footer">
        <img *ngIf="!isHighContrast" src="./assets/game-2017/logosy.svg" />
        <img *ngIf="isHighContrast" src="./assets/game-2017/logosy_ud.svg" />
    </div>
</div>

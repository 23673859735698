<div class="content-container">
    <!-- <div class="content-pre-head" *ngIf="showPre"></div> -->
    <app-logo></app-logo>
    <div class="content content-pre" *ngIf="showPre">
        <div>
            <h1 title="Liceum Ogólnokształcące – zadanie lokalizacyjne">Liceum Ogólnokształcące</h1>
            <ng-container *ngIf="gameType !== 'outdoor_team'">
                <p>
                    Po dojściu do wskazanego na mapie miejsca mijasz właśnie ucznia, który wyszedł z pobliskiej szkoły i wydaje się być zagubiony.
                    <br />W końcu zagaduje Cię:
                </p>
                <p>
                    <strong>– Przepraszam, jak się nazywa najbliższy przystanek?</strong>
                </p>
                <p>
                    Co mu odpowiesz?
                </p>
            </ng-container>
            <ng-container *ngIf="gameType === 'outdoor_team'">
                <p>
                    Po dojściu do wskazanego na mapie miejsca mijacie właśnie ucznia, który wyszedł z pobliskiej szkoły i
                    wydaje się być zagubiony.
                    <br />W końcu zagaduje Was:
                </p>
                <p>
                    <strong>– Przepraszam, jak się nazywa najbliższy przystanek?</strong>
                </p>
                <p>
                    Co mu odpowiecie?
                </p>
            </ng-container>

            <app-check-answer answer='nowy swiat'
                (isCorrect)="checkIsCorrect($event)"></app-check-answer>
        </div>
    </div>

    <div class="content"*ngIf="!showPre && !started">
        <div>
            <h1 title="Liceum Ogólnokształcące – wprowadzenie">Liceum Ogólnokształcące</h1>
            <div class="column center" *ngIf="isMobile">
                <img src="../../assets/images/game1_black.png"
                    alt="grafika przedstawiająca czarno-białe zdjęcie smutnego nastolatka, w tle śmiejąca się z niego dziewczyna"
                    title="grafika przedstawiająca czarno-białe zdjęcie smutnego nastolatka, w tle śmiejąca się z niego dziewczyna" />
            </div>
            <div class="column">
                <p *ngIf="gameType !== 'outdoor_team'">
                    Przy wejściu do szkoły stoi Nastolatek. Wygląda na smutnego. Podchodzisz do niego, zaczynasz z nim
                    rozmawiać i dowiadujesz się, że ma problemy w szkole ze znajomymi. Nie jest akceptowany w klasie,
                    ponieważ nie podporządkowuje się trendom panującym wśród swoich rówieśników – słucha innej
                    muzyki, inaczej się ubiera, ma odmienne zainteresowania. Dlatego jest odrzucony przez klasę. Jest
                    mu z tym źle. W połączeniu z kłopotami rodzinnymi sprawia to, że ma bardzo niską samoocenę.
                    Rodzice ignorują jego problemy. Nie może spać, dręczą go koszmary. Nie chce chodzić do szkoły,
                    przestaje się uczyć. Nauczyciele także nie interesują się jego problemami. Coraz częściej myśli o tym,
                    że być może wolność postępowania w zgodzie ze sobą, wierność sobie i swoim zainteresowaniom
                    jest zbyt kosztowna, skoro nikt go nie rozumie. Dowiedz się, jak możesz mu pomóc.
                </p>
                <p *ngIf="gameType === 'outdoor_team'">
                    Przy wejściu do szkoły stoi Nastolatek. Wygląda na smutnego. Podchodzicie do niego, zaczynacie z
                    nim rozmawiać i dowiadujecie się, że ma problemy w szkole ze znajomymi. Nie jest akceptowany w
                    klasie, ponieważ nie podporządkowuje się trendom panującym wśród swoich rówieśników – słucha
                    innej muzyki, inaczej się ubiera, ma odmienne zainteresowania. Dlatego jest odrzucony przez klasę.
                    Jest mu z tym źle. W połączeniu z kłopotami rodzinnymi sprawia to, że ma bardzo niską samoocenę.
                    Rodzice ignorują jego problemy. Nie może spać, dręczą go koszmary. Nie chce chodzić do szkoły,
                    przestaje się uczyć. Nauczyciele także nie interesują się jego problemami. Coraz częściej myśli o tym,
                    że być może wolność postępowania w zgodzie ze sobą, wierność sobie i swoim zainteresowaniom
                    jest zbyt kosztowna, skoro nikt go nie rozumie. Dowiedzcie się, jak możecie mu pomóc.
                </p>
            </div>
            <div class="column center" *ngIf="!isMobile">
                <img src="../../assets/images/game1_black.png"
                    alt="grafika przedstawiająca czarno-białe zdjęcie smutnego nastolatka, w tle śmiejąca się z niego dziewczyna"
                    title="grafika przedstawiająca czarno-białe zdjęcie smutnego nastolatka, w tle śmiejąca się z niego dziewczyna"/>
            </div>
            <div class="actions">
                <button (click)="startGame()"
                    alt="przejście dalej"
                    title="przejście dalej"
                    mat-raised-button
                    color="primary">PRZEJDŹ DALEJ</button>
            </div>
        </div>
    </div>

    <div class="content" *ngIf="!showPre && started">
        <app-game1></app-game1>
    </div>
</div>

<div class="content-page landing-footer">
  <img *ngIf="!isHighContrast" src="./assets/game-2023/logotypy.png"
    alt="logotypy Centrum Myśli Jana Pawła II, Instytucja kultury miasta stołecznego Warszawy, Stypendiów m.st. Warszawy im. Jana Pawła II, Fundacji BGK"
    title="logotypy Centrum Myśli Jana Pawła II, Instytucja kultury miasta stołecznego Warszawy, Stypendiów m.st. Warszawy im. Jana Pawła II, Fundacji BGK"/>
  <img *ngIf="isHighContrast" src="./assets/game-2023/logotypy_ud.png"
    alt="logotypy Centrum Myśli Jana Pawła II, Instytucja kultury miasta stołecznego Warszawy, Stypendiów m.st. Warszawy im. Jana Pawła II, Fundacji BGK"
    title="logotypy Centrum Myśli Jana Pawła II, Instytucja kultury miasta stołecznego Warszawy, Stypendiów m.st. Warszawy im. Jana Pawła II, Fundacji BGK"/>
</div>
<!-- <div class="content-page">
    <br /><br />
    <div class="logos">
        <div>
            <img *ngIf="!isHighContrast" src="../../assets/images/logo_cmjp.png"
                alt="logotyp Centrum Myśli Jana Pawła II"
                title="logotyp Centrum Myśli Jana Pawła II"/>
            <img *ngIf="isHighContrast" src="../../assets/images/logo_cmjp_u.png"
                alt="logotyp Centrum Myśli Jana Pawła II"
                title="logotyp Centrum Myśli Jana Pawła II"/>
        </div>
        <div>
          <img *ngIf="!isHighContrast" src="../../assets/images/syrenka_nowa_kolor.png"
              alt="logotyp Instytucja kultury miasta stołecznego Warszawy"
              title="logotyp Instytucja kultury miasta stołecznego Warszawy" />
          <img *ngIf="isHighContrast" src="../../assets/images/syrenka_nowa_zolta.png"
              alt="logotyp Instytucja kultury miasta stołecznego Warszawy"
              title="logotyp Instytucja kultury miasta stołecznego Warszawy" />
        </div>
        <div>
            <img *ngIf="!isHighContrast" src="../../assets/images/logo_s.png"
                alt="logotyp Stypendiów m.st. Warszawy im. Jana Pawła II"
                title="logotyp Stypendiów m.st. Warszawy im. Jana Pawła II" />
            <img *ngIf="isHighContrast" src="../../assets/images/logo_s_u.png"
                alt="logotyp Stypendiów m.st. Warszawy im. Jana Pawła II"
                title="logotyp Stypendiów m.st. Warszawy im. Jana Pawła II" />
        </div>
    </div>
    <div class="logo-second-line">
        <div>
            <img *ngIf="!isHighContrast" src="../../assets/images/bgk_finansowanie.png"
                title="logotyp Fundacji BGK"
                alt="logotyp Fundacji BGK" />
            <img *ngIf="isHighContrast" src="../../assets/images/bgk_finansowanie_u.png"
                title="logotyp Fundacji BGK"
                alt="logotyp Fundacji BGK" />
        </div>
    </div>
    <br />
</div> -->
<div class="content-page footer-grey">
    <a routerLink="/deklaracja-dostepnosci" title="Strona: Deklaracja Dostepności">Deklaracja dostępności</a>
    <span>Copyright Centrum Myśli Jana Pawła II</span>
</div>

import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessibilityService } from '../../services/accessibility.service';
import { GameService } from '../../services/game.service';

@Component({
  selector: 'app-game3',
  templateUrl: './game3.component.html',
  styleUrls: ['./game3.component.scss']
})
export class Game3Component implements OnInit, OnDestroy {  
  private _gameNumber = 3;
  public isMobile: boolean = false;
  public isCompleted = this.gameService.getGameCompleted(this._gameNumber);  
  public gameType: string = this.gameService.getGameType();
  public message: string = '';
  public subContrast: Subscription;
  public isHighContrast = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private gameService: GameService,
    private accessibilityService: AccessibilityService
  ) {
    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.subContrast = this.accessibilityService.onContrast().subscribe(value => {
      this.isHighContrast = value;      
    });
  }

  public cart = [];
  public products = [
    {
      id: 1,
      name: "Udziec z indyka 1kg",
      price: 17,
      group: 1,
      alt: "udziec z indyka za 17 zł", 
      title: "udziec z indyka za 17 zł"
    },
    {
      id: 2,
      name: "Udziec z kurczaka 1kg",
      price: 7,
      group: 1,
      alt: "udka z kurczaka za 7 zł", 
      title: "udka z kurczaka za 7 zł"
    },
    {
      id: 3,
      name: "Gotowe nuggetsy 5szt.",
      price: 14,
      group: 1,
      alt: "opakowanie nuggetsów za 14 zł", 
      title: "opakowanie nuggetsów za 14 zł"
    },
    {
      id: 4,
      name: "Szparagi pęczek",
      price: 16,
      group: 2,
      alt: "pęczek szparagów za 16 zł", 
      title: "pęczek szparagów za 16 zł"
    },
    {
      id: 5,
      name: "Buraki 1kg",
      price: 4,
      group: 2,
      alt: "buraki za 4 zł", 
      title: "buraki za 4 zł"
    },
    {
      id: 6,
      name: "Surówka Colesław opakowanie 500g",
      price: 12,
      group: 2,
      alt: "surówka kolesław za 12 zł", 
      title: "surówka kolesław za 12 zł"
    },
    {
      id: 7,
      name: "Woda",
      price: 2,
      group: 3,
      alt: "butelka wody za 2 zł", 
      title: "butelka wody za 2 zł"
    },
    {
      id: 8,
      name: "Coca Cola 1l",
      price: 8,
      group: 3,
      alt: "butelka coli za 8 zł", 
      title: "butelka coli za 8 zł"
    },
    {
      id: 9,
      name: "Sok 1l",
      price: 5,
      group: 3,
      alt: "butelka soku pomarańczowego za 5 zł", 
      title: "butelka soku pomarańczowego za 5 zł"
    },
    {
      id: 10,
      name: "Tarta owocowa",
      price: 15,
      group: 4,
      alt: "tarta truskawkowa za 15 zł", 
      title: "tarta truskawkowa za 15 zł"
    },
    {
      id: 11,
      name: "Czekolada tabliczka 100g",
      price: 4,
      group: 4,
      alt: "czekolada za 4 zł", 
      title: "czekolada za 4 zł"
    },
    {
      id: 12,
      name: "Babeczki z posypką 4szt.",
      price: 8,
      group: 4,
      alt: "dwie babeczki za 8 zł", 
      title: "dwie babeczki za 8 zł"
    }
  ];

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subContrast.unsubscribe();
  }

  public getProductsGroup(group: number) {
    return this.products.filter(i => i.group === group);
  }

  clickAdd(productId: number) {  
    if (this.cart.length < 12) {
      this.cart.push(this.products.find(i => i.id === productId));
    }
  }

  clickRemove(index: number) {
    this.cart.splice(index, 1);
  }

  goNext() {    
    this.message = '';
    if (this._checkProductsPrice() > 35) { 
      this.message = "Przekroczono budżet";
    }
    if (!this._checkProducts()) {
      if (this.message === '') {
        this.message = "Nie uwzględniono produktów z którejś z półek.";
      } else {
        this.message = "Przekroczono budżet i nie uwzględniono produktów z którejś z półek.";
      }
    }
    if (this.message === '') {
      this.gameService.setGameCompleted(this._gameNumber);
      this.isCompleted = this.gameService.getGameCompleted(this._gameNumber);
      window.scroll(0,0);
    }
  }

  private _checkProducts() {
    let groups = [];
    this.cart.forEach((product) => {
      groups.push(product.group);
    });
    let uniqueItems = [...new Set(groups)];
    return (uniqueItems.length >= 4);
  }
  private _checkProductsPrice() {
    let price = 0;
    this.cart.forEach((product) => {
      price += product.price;
    });
    return price;
  }
}

<div class="landing-header" [class.landing-header-no-logo]="showLogo === false">
    <div *ngIf="showLogo" class="logo" routerLink="/">
        <img *ngIf="!isHighContrast" src="../../assets/page-landing/logo.png" title="napis Gra o Wolność" alt="napis Gra o Wolność"/>
        <img *ngIf="isHighContrast" src="../../assets/page-landing/logo_u.png" title="napis Gra o Wolność" alt="napis Gra o Wolność"/>
    </div>
    <div *ngIf="!showLogo" class="logo"></div>
    <div class="menu">
        <div class="menu-item">
            <a routerLink="/o-grze-o-wolnosc" title="Strona: O Grze o Wolność">O Grze o Wolność</a>
            <div class="menu-underline menu-underline-red"></div>
        </div>
        <div class="menu-item">
            <a href="https://www.centrumjp2.pl/stypendia/" target="_blank" title="Strona: O Stypendiach">O Stypendiach</a>
            <div class="menu-underline menu-underline-oragne"></div>
        </div>
        <div class="menu-item">
            <a routerLink="/kontakt" title="Strona: Kontakt">Kontakt</a>
            <div class="menu-underline menu-underline-blue"></div>
        </div>
    </div>
</div>
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GameService } from '../../services/game.service';

@Component({
  selector: 'app-landing2021',
  templateUrl: './landing2021.component.html',
  styleUrls: ['./landing2021.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Landing2021Component implements OnInit {

  constructor(
    private gameService: GameService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  public setGameType(type: string) {
    this.gameService.setGameType(type);
    this.router.navigate(['/letter']);
  }
}

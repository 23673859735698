<div class="page-game-2019">
    <div class="content-page-header">
        <app-landing-header [showLogo]="false" [isHighContrast]=isHighContrast></app-landing-header>
    </div>
    <div class="content-page main">
        <div class="content-page-image"></div>
        <div class="content-relation">
            <h1>Edycja 2019</h1>
            <p>
                Tematem trzeciej edycji gry była pierwsza pielgrzymka Jana Pawła II do Polski, która odbyła się w czerwcu 1979 roku. Uczestnicy podczas rozgrywki mogli dowiedzieli się, jak wyglądała wówczas sytuacja społeczno-polityczna i jakie było znaczenie przyjazdu papieża do kraju. Gra przybliżała też znaczenie wizyty papieża dla przemian w Polsce w latach 80. i 90. XX wieku oraz pokazywała, jak aktualne również obecnie są słowa wypowiedziane przez papieża 40 lat wcześniej.
            </p>
            <p>
                Gracze, przy pomocy umieszczonych na planszy kodów QR, odkrywali zadania, których rozwiązanie wskazywało kolejny etap pielgrzymki, której śladem podążali.
            </p>
            <p>
                Premiera gry (wersja z udziałem animatorów - Stypendystów) odbyła się w niedzielę 2 czerwca 2019 roku na terenie przyległym do „Kawiarni Niepodległa. Miejsce spotkań” w Łazienkach Królewskich w Warszawie. W grze brały udział osoby indywidualne oraz grupy składające się z rodzin i znajomych w różnym wieku.
            </p>
            <p>
                Gra w wersji bez animatorów, z wykorzystaniem wyłącznie kodów QR, dostępna była przez kolejny tydzień na specjalnych planszach w kilku innych miastach Polski. Obecnie plansze gry są dostępne na dziedzińcu Kamienicy Artystycznej przy ul. Foksal 11 w Warszawie.
            </p>
        </div>
        <div class="content-button center">
          <a mat-raised-button
            class="button"
            routerLink="/"
            alt="powrót na stronę główną"
            title="powrót na stronę główną"
            color="primary">Powrót do strony głównej</a>
        </div>
        <div class="content-foto">
            <img *ngIf="showImage(1)" src="./assets/game-2019/1200-1.jpg" alt="Dwie dziewczyny stoją w parku przed dużą planszą z napisem Warszawa – Plac Zwycięstwa. Na planszy jest do przeczytania tekst oraz fotografia krzyża stojącego na Placu Zwycięstwa podczas pielgrzymki papieskiej w 1979 roku."/>
            <img *ngIf="showImage(2)" src="./assets/game-2019/1200-2.jpg" alt="Grupa ludzi stoi dookoła małej dziewczynki. Dziewczynka rzuca piłką do celu."/>
            <img *ngIf="showImage(3)" src="./assets/game-2019/1200-3.jpg" alt="Na pierwszym planie widać krzaki. Zza krzaków widać grupę ludzi pochylającą się nad stołem. Jedna z osób rysuje coś na kartce długopisem 3D."/>
            <img *ngIf="showImage(4)" src="./assets/game-2019/1200-4.jpg" alt="Dorosły mężczyzna z chłopcem mają na sobie gogle zniekształcające obraz. Próbują układać w nich na stole klocki."/>
            <img *ngIf="showImage(6)" src="./assets/game-2019/1200-6.jpg" alt="Dwie dziewczyny stoją tyłem przed planszą, do której przyczepionych jest wiele kopert w kolorach białym i czerwonym. Z jednej z kopert wystaje sylaba ni."/>
            <img *ngIf="showImage(7)" src="./assets/game-2019/1200-7.jpg" alt="Trzy osoby z telefonami w ręku i w kapturach stoją przed dużą planszą z napisem Kraków – Balice. Na planszy jest do przeczytania tekst oraz fotografia, na której znajduje się Jan Paweł Drugi, wchodzący do samolotu i machający ręką."/>
        </div>
        <div class="content-fotos">
            <div (click)="setImage(1);">
                <img src="./assets/game-2019/400-1.jpg" alt="Dwie dziewczyny stoją w parku przed dużą planszą z napisem Warszawa – Plac Zwycięstwa. Na planszy jest do przeczytania tekst oraz fotografia krzyża stojącego na Placu Zwycięstwa podczas pielgrzymki papieskiej w 1979 roku."/>
            </div>
            <div (click)="setImage(2);">
                <img src="./assets/game-2019/400-2.jpg" alt="Grupa ludzi stoi dookoła małej dziewczynki. Dziewczynka rzuca piłką do celu."/>
            </div>
            <div (click)="setImage(3);">
                <img src="./assets/game-2019/400-3.jpg" alt="Na pierwszym planie widać krzaki. Zza krzaków widać grupę ludzi pochylającą się nad stołem. Jedna z osób rysuje coś na kartce długopisem 3D."/>
            </div>
            <div (click)="setImage(4);">
                <img src="./assets/game-2019/400-4.jpg" alt="Dorosły mężczyzna z chłopcem mają na sobie gogle zniekształcające obraz. Próbują układać w nich na stole klocki."/>
            </div>
            <div (click)="setImage(6);">
                <img src="./assets/game-2019/400-6.jpg" alt="Dwie dziewczyny stoją tyłem przed planszą, do której przyczepionych jest wiele kopert w kolorach białym i czerwonym. Z jednej z kopert wystaje sylaba ni."/>
            </div>
            <div (click)="setImage(7);">
                <img src="./assets/game-2019/400-7.jpg" alt="Trzy osoby z telefonami w ręku i w kapturach stoją przed dużą planszą z napisem Kraków – Balice. Na planszy jest do przeczytania tekst oraz fotografia, na której znajduje się Jan Paweł Drugi, wchodzący do samolotu i machający ręką."/>
            </div>
        </div>
    </div>
    <div class="content-page footer">
        <img *ngIf="!isHighContrast" src="./assets/game-2019/logotypy.svg" />
        <img *ngIf="isHighContrast" src="./assets/game-2019/logotypy_ud.svg" />
    </div>
</div>


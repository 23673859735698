<div class="page-game-2018">
    <div class="content-page-header">
        <app-landing-header [showLogo]="false" [isHighContrast]=isHighContrast></app-landing-header>
    </div>
    <div class="content-page main">
        <div class="content-page-image"></div>
        <div class="content-relation">
            <h1>Edycja 2018</h1>
            <p>
                Uczestnicy w ramach gry przenieśli się do czasów współczesnych – wieku XX i XXI. Gracze otrzymali specjalny pamiętnik ze słowami Jana Pawła II, który był dla nich drogowskazem na etapie kolejnych działań.
            </p>
            <p>
                Rozmaite konkurencje, które wykonywali gracze dotyczyły zarówno wydarzeń kluczowych dla historii Polski, jak i tych, składających się na życie codzienne każdego Polaka. Uczestnicy gry odkrywali je przez pryzmat czterech perspektyw zdefiniowanych w hasłach: jednostka, społeczeństwo, naród, państwo. Gracze m.in. składali własną gazetę studencką, odczytywali tajne szyfry w bunkrze wojennym, jako detektywi tropili ślady słów Jana Pawła II czy odwiedzali sekretarza Papieża w Krakowie. Gra przeznaczona była dla rodzin i grup przyjaciół.
            </p>
            <p>
                Gra zrealizowana została w niedzielę 2 grudnia 2018 r. w formule rozgrywki miejskiej w ścisłym centrum Warszawy.
            </p>
        </div>
        <div class="content-button center">
            <a mat-raised-button
              class="button"
              routerLink="/"
              alt="powrót na stronę główną"
              title="powrót na stronę główną"
              color="primary">Powrót do strony głównej</a>
        </div>

        <div class="video-container">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/LEDVTIdIZ3A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>


        <div class="content-foto">
            <img *ngIf="showImage(1)" src="./assets/game-2018/1200-1.jpg" alt="Troje młodych ludzi w strojach z lat trzydziestych dwudziestego wieku siedzi pochylona nad gazetami. Znajdują się w piwnicy."/>
            <img *ngIf="showImage(2)" src="./assets/game-2018/1200-2.jpg" alt="Grupa ludzi stoi wokół broszury leżącej na stole. Wspólnie wklejają do niej fragmenty tekstu."/>
            <img *ngIf="showImage(3)" src="./assets/game-2018/1200-3.jpg" alt="Dwoje ludzi stoi w ciemnym pomieszczeniu. Dziewczyna trzyma w ręku książkę. Chłopak za pomocą latarki ultrafioletowej próbuje odczytać tekst zapisany w książce niewidzialnym długopisem."/>
            <img *ngIf="showImage(5)" src="./assets/game-2018/1200-5.jpg" alt="Grupa młodzieży ubrana w stroje wojskowe siedzi pochylona przy stole. Na stole oraz półkach obok znajdują się różne stare przedmioty, m.in. hełm, karabin oraz stary zegar."/>
            <img *ngIf="showImage(6)" src="./assets/game-2018/1200-6.jpg" alt="Dorosła kobieta wpisuje coś do broszury, która leży na stole. Obok stoi mała dziewczynka, która przygląda się temu co zapisuje kobieta. Na stole poza broszurą znajdują się flamastry, marker oraz bilet."/>
            <img *ngIf="showImage(7)" src="./assets/game-2018/1200-7.jpg" alt="Plakat z napisem: Gra o Wolność, Warszawa, 2 grudnia 2018 roku. Plakat zawiera hasło przewodnie gry oraz podstawowe informacje na temat zapisów. Obok plakatu leży kilkanaście zdjęć z instaxa. Na każdym zdjęciu jest po kilka osób."/>
        </div>
        <div class="content-fotos">
            <div (click)="setImage(1);">
                <img src="./assets/game-2018/400-1.jpg" alt="Troje młodych ludzi w strojach z lat trzydziestych dwudziestego wieku siedzi pochylona nad gazetami. Znajdują się w piwnicy." />
            </div>
            <div (click)="setImage(2);">
                <img src="./assets/game-2018/400-2.jpg" alt="Grupa ludzi stoi wokół broszury leżącej na stole. Wspólnie wklejają do niej fragmenty tekstu."/>
            </div>
            <div (click)="setImage(3);">
                <img src="./assets/game-2018/400-3.jpg" alt="Dwoje ludzi stoi w ciemnym pomieszczeniu. Dziewczyna trzyma w ręku książkę. Chłopak za pomocą latarki ultrafioletowej próbuje odczytać tekst zapisany w książce niewidzialnym długopisem."/>
            </div>
            <div (click)="setImage(5);">
                <img src="./assets/game-2018/400-5.jpg" alt="Grupa młodzieży ubrana w stroje wojskowe siedzi pochylona przy stole. Na stole oraz półkach obok znajdują się różne stare przedmioty, m.in. hełm, karabin oraz stary zegar."/>
            </div>
            <div (click)="setImage(6);">
                <img src="./assets/game-2018/400-6.jpg" alt="Dorosła kobieta wpisuje coś do broszury, która leży na stole. Obok stoi mała dziewczynka, która przygląda się temu co zapisuje kobieta. Na stole poza broszurą znajdują się flamastry, marker oraz bilet."/>
            </div>
            <div (click)="setImage(7);">
                <img src="./assets/game-2018/400-7.jpg" alt="Plakat z napisem: Gra o Wolność, Warszawa, 2 grudnia 2018 roku. Plakat zawiera hasło przewodnie gry oraz podstawowe informacje na temat zapisów. Obok plakatu leży kilkanaście zdjęć z instaxa. Na każdym zdjęciu jest po kilka osób."/>
            </div>
        </div>
    </div>
    <div class="content-page footer">
        <img *ngIf="!isHighContrast" src="./assets/game-2018/logo_str2.svg" />
        <img *ngIf="isHighContrast" src="./assets/game-2018/logo_str2_ud.svg" />
    </div>
</div>

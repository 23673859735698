import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { AccessibilityService } from './shared/services/accessibility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'app-gramiejska';
  isMobile: boolean = false;

  public accessibillityClassName: string;

  constructor(
    public accessibillityService: AccessibilityService,
    private breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
    // ...
}

  ngOnInit(): void {
    this.accessibillityService.className.subscribe(
      value => this.accessibillityClassName = value
    );
  }
  
}

import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  @ViewChild('cookieClose') cookieClose: MatButton;
  @Output() isClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isMobile: boolean = false;

  constructor(
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    // console.log(this.cookieClose);
    // setTimeout(()=>{ // this will make the execution after the above boolean has changed
    //   this.cookieClose.nativeElement.focus();
    // },0);
  }
  ngAfterViewInit() {
    console.log('ngAfterViewInit');
    console.log(this.cookieClose);
    setTimeout(()=>{ 
      this.cookieClose.focus();
      // this.cookieClose.nativeElement.focus();
    }, 2);
    // console.log('on after view init', );
    // this returns null
  }

  close() {
    this.isClose.emit(true);
  }
}

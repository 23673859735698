<div *ngIf="!answerState">
    <p class="incorrect">
        Odpowiedź jest niepoprawna.
    </p>
</div>
<div [ngClass]="isMobile ? 'mobile' : ''">
    <input #input type="text"
        alt="miejsce na wpisanie hasła"
        title="miejsce na wpisanie hasła"/>
    <button (click)="checkClick()" 
        alt="sprawdzanie poprawności hasła"
        title="sprawdzanie poprawności hasła"
        mat-raised-button 
        color="primary">SPRAWDŹ</button>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessibilityService } from '../shared/services/accessibility.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goGameAtHome() {
    console.log('go game at home');
    this.router.navigate(['/map']);
  }

  goGameToOutdoor() {
    console.log('go game to outdoor');
    this.router.navigate(['/map']);
  }
}

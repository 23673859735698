<div class="content-container">    
    <app-logo></app-logo>
    <div class="content content-ending">    
        <div>
            <h1 title="Podsumowanie">Podsumowanie</h1>
            <ng-container *ngIf="gameType !== 'outdoor_team'">
                <p>
                    Dziękujemy za Twój udział w Grze o Wolność. Mamy nadzieję, że była ona dobrą zabawą! Poprzez tę grę chcieliśmy zwrócić 
                    Twoją uwagę na dwie ważne i jak się okazuje zależne od siebie wartości: wolność i pokój. Inspiracją do stworzenia gry 
                    było dla nas Orędzie Jana Pawła II na XIV Światowy Dzień Pokoju, obchodzony w styczniu dokładnie 30 lat temu, z 
                    którego jasno wynika, że wolność buduje pokój. <a href="https://jp2online.pl/obiekt/oredzie-jana-pawla-ii-na-xiv-swiatowy-dzien-pokoju-(1-stycznia-1981);T2JqZWN0OjM0Nzg=?" target="_blank">Zapraszamy do jego lektury!</a>
                </p>
                <p>
                    Spodobała Ci się Gra o Wolność? Poleć ją znajomym i rodzinie!
                </p>
            </ng-container>

            <ng-container *ngIf="gameType === 'outdoor_team'">
                <p>
                    Dziękujemy za Wasz udział w Grze o Wolność. Mamy nadzieję, że była ona dobrą zabawą! Poprzez tę grę chcieliśmy zwrócić 
                    Waszą uwagę na dwie ważne i jak się okazuje zależne od siebie wartości: wolność i pokój. Inspiracją do stworzenia gry 
                    było dla nas Orędzie Jana Pawła II na XIV Światowy Dzień Pokoju, obchodzony w styczniu dokładnie 30 lat temu, z 
                    którego jasno wynika, że wolność buduje pokój. <a href="https://jp2online.pl/obiekt/oredzie-jana-pawla-ii-na-xiv-swiatowy-dzien-pokoju-(1-stycznia-1981);T2JqZWN0OjM0Nzg=?" target="_blank">Zapraszamy do jego lektury!</a>
                </p>
                <p>
                    Spodobała Wam się Gra o Wolność? Polećcie ją znajomym i rodzinie!
                </p>
            </ng-container>
            
            <div class="actions" style="margin-top: 4%;">
                <ng-container *ngIf="gameType !== 'outdoor_team'">  
                    <p>Jeśli chcesz otrzymać dyplom i nagrodę</p>
                </ng-container>
                <ng-container *ngIf="gameType === 'outdoor_team'">
                    <p>Jeśli chcesz otrzymać dyplom i nagrodę dla zespołu</p>
                </ng-container>
                <button 
                    alt="uzupełnij dane"
                    title="uzupełnij dane"
                    routerLink="/form"
                    mat-raised-button 
                    color="primary">UZUPEŁNIJ</button>
            </div> 
            <div class="actions" style="margin-bottom: 4%;">
                <p>Jeśli nie</p>
                <button  
                    (click)="goTo()"
                    alt="zakończ"
                    title="zakończ"
                    mat-raised-button 
                    color="primary">ZAKOŃCZ</button>                    
            </div>

            <ng-container *ngIf="gameType !== 'outdoor_team'">                
                <p>
                    Jak mogłeś przeczytać na stronie głównej, twórcami Gry o Wolność jesteśmy my – stypendyści m.st. Warszawy im. Jana Pawła II.
                </p>
            </ng-container>

            <ng-container *ngIf="gameType === 'outdoor_team'">                
                <p>
                    Jak mogliście przeczytać na stronie głównej, twórcami Gry o Wolność jesteśmy my – stypendyści m.st. Warszawy im. Jana Pawła II.
                </p>
            </ng-container>

            <p>Tegoroczną grę tworzyli:</p>
            <p>
                <i>
                Mariia Bahinska, Natalia Błażejczyk, Grzegorz Czernyszow, Julia Dąbrowska, Julia Dziadak, Aleksander Figielski, Anna Hajduk,
                Piotr Hall, Marcelina Idźkowska, Julia Jankowska, Nikodem Kaczprzak, Magda Kassim, Jakub Kieliszczyk, Kinga Kuśmierowska,
                Jagna Łoś, Barnaba Łoś, Kosma Łoś, Bolesław Mayzel, Wojciech Mordań, Oliwia Ochap, Gabriela Plechawska, Jędrzej Rzepko,
                Szymon Rzepniewski, Aniela Stachera, Ignacy Stachera, Przemysław Stocki, Bartosz  Wiszniewski, Dominik Zawiślak, Uliana Zelenko.
                </i>
            </p>
            <img src="../../assets/images/team.jpg" alt="zdjecie zespolu tworcow gry" />
            <p>Organizator</p>
            <div class="logo">
                <div>
                    <img *ngIf="!isHighContrast" src="../../assets/images/logo_cmjp.png" 
                        title="logotyp Centrum Myśli Jana Pawła II"
                        alt="logotyp Centrum Myśli Jana Pawła II" />
                    <img *ngIf="isHighContrast" src="../../assets/images/logo_cmjp_u.png" 
                        title="logotyp Centrum Myśli Jana Pawła II"
                        alt="logotyp Centrum Myśli Jana Pawła II" />                        
                </div>
                <div>
                    <img *ngIf="!isHighContrast" src="../../assets/images/logo_s.png" 
                        title="logotyp Stypendiów m.st. Warszawy im. Jana Pawła II"
                        alt="logotyp Stypendiów m.st. Warszawy im. Jana Pawła II"/>
                    <img *ngIf="isHighContrast" src="../../assets/images/logo_s_u.png" 
                        title="logotyp Stypendiów m.st. Warszawy im. Jana Pawła II"
                        alt="logotyp Stypendiów m.st. Warszawy im. Jana Pawła II"/>                        
                </div>
                <div>
                    <img *ngIf="!isHighContrast" src="../../assets/images/logo_zsww.png" 
                        title="logotyp Zakochaj się w Warszawie"
                        alt="logotyp Zakochaj się w Warszawie"/>
                    <img *ngIf="isHighContrast" src="../../assets/images/logo_zsww_u.png" 
                        title="logotyp Zakochaj się w Warszawie"
                        alt="logotyp Zakochaj się w Warszawie"/>                        
                </div>    
            </div>
            <div class="logo-second-line">
                <div>
                    <img *ngIf="!isHighContrast" src="../../assets/images/bgk_finansowanie.png" 
                        title="logotyp Fundacji BGK"
                        alt="logotyp Fundacji BGK" />
                    <img *ngIf="isHighContrast" src="../../assets/images/bgk_finansowanie_u.png" 
                        title="logotyp Fundacji BGK"
                        alt="logotyp Fundacji BGK" />                        
                </div>
            </div>            
        </div>
    </div>
</div>
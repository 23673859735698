import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-landing2020',
  templateUrl: './landing2020.component.html',
  styleUrls: ['./landing2020.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Landing2020Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessibilityService } from '../../services/accessibility.service';
import { GameService } from '../../services/game.service';

@Component({
  selector: 'app-game6',
  templateUrl: './game6.component.html',
  styleUrls: ['./game6.component.scss']
})
export class Game6Component implements OnInit, OnDestroy {  
  private _gameNumber = 6;
  public isMobile: boolean = false;
  public isCompleted = this.gameService.getGameCompleted(this._gameNumber);  
  public gameType: string = this.gameService.getGameType();
  public message: string = '';
  public subContrast: Subscription;
  public isHighContrast = false;

  @ViewChild('input1') input1: ElementRef; 
  @ViewChild('input11') input11: ElementRef; 
  @ViewChild('input2') input2: ElementRef; 
  @ViewChild('input22') input22: ElementRef; 
  @ViewChild('input3') input3: ElementRef; 
  @ViewChild('input33') input33: ElementRef; 
  @ViewChild('input4') input4: ElementRef; 
  @ViewChild('input5') input5: ElementRef; 

  constructor(
    private breakpointObserver: BreakpointObserver,
    private gameService: GameService,
    private accessibilityService: AccessibilityService
  ) {
    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.subContrast = this.accessibilityService.onContrast().subscribe(value => {
      this.isHighContrast = value;      
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.subContrast.unsubscribe();
  }


  goNext() { 
    if ((this.input1.nativeElement.value === "1") 
      && (this.input11.nativeElement.value === "6")
      && (this.input2.nativeElement.value === "1")
      && (this.input22.nativeElement.value === "0")
      && (this.input3.nativeElement.value === "1")
      && (this.input33.nativeElement.value === "9")
      && (this.input4.nativeElement.value === "7")
      && (this.input5.nativeElement.value === "8")) {
        this.gameService.setGameCompleted(this._gameNumber);
        this.isCompleted = this.gameService.getGameCompleted(this._gameNumber);
        window.scroll(0,0);
      } else {
        this.message = "Niepoprawny szyfr."
      } 
  }

  goNext2() {     
    // this.gameService.setGameCompleted(this._gameNumber);
    // this.isCompleted = this.gameService.getGameCompleted(this._gameNumber);    
  }

}

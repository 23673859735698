<div class="dialog dialog2" [ngClass]="isMobile ? 'mobile' : ''">
    <h1>KOMUNIKAT</h1>
    <div class="dialog-content">
        <ng-content></ng-content>
        <div class="actions">
            <button (click)="close()" #cookieClose
                title="OK"
                alt="OK"
                mat-raised-button 
                color="primary">OK</button>
    </div>
</div>
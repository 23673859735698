import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessibilityService } from '../shared/services/accessibility.service';
import { GameService } from '../shared/services/game.service';

@Component({
  selector: 'app-letter',
  templateUrl: './letter.component.html',
  styleUrls: ['./letter.component.scss']
})
export class LetterComponent implements OnInit {
  public isMobile: boolean = false;
  public gameType: string = this.gameService.getGameType();

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private gameService: GameService
  ) {
    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
  }

  goGameAtHome() {
    console.log('go game at home');
    this.router.navigate(['/map']);
  }

  goGameToOutdoor() {
    console.log('go game to outdoor');
    this.router.navigate(['/map']);
  }
}

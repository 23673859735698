import { Component, OnInit, OnDestroy, Renderer2, HostListener, ViewEncapsulation, ElementRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { GameService } from '../shared/services/game.service';
import { Router } from '@angular/router';
import { AccessibilityService } from '../shared/services/accessibility.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingComponent implements OnInit, OnDestroy {
  public isMobile: boolean = false;
  public showDialogCookies = this.readCookie();
  public subContrast: Subscription;
  public isHighContrast = false;
  public sliderHeight = 500;

  constructor(
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver,
    private el: ElementRef,
    private gameService: GameService,
    private accessibilityService: AccessibilityService
  ) {
    this.renderer.addClass(document.body, 'landing-page');

    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.subContrast = this.accessibilityService.onContrast().subscribe(value => {
      this.isHighContrast = value;
    });
  }

  ngAfterViewInit() {
    const sliderRight = this.el.nativeElement.querySelector('.carousel-arrow-next');
    const sliderLeft = this.el.nativeElement.querySelector('.carousel-arrow-prev');
    this.renderer.setAttribute(sliderRight, 'alt', 'Strzałka w prawo, pokaż następny obrazek.');
    this.renderer.setAttribute(sliderLeft, 'alt', 'Strzałka w lewo, pokaż poprzedni obrazek.');
  }

  private _calculateSliderHeight(width: number) {
    if (width < 1200) {
      let ratio = width / 1200;
      this.sliderHeight = Math.round(500 * ratio);
    } else {
      this.sliderHeight = 500;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this._calculateSliderHeight(window.innerWidth);
  }

  readCookie() {
    let cookie = this.gameService.read('cookie');
    return (!cookie || cookie === 'false');
  }
  closeDialogCookies() {
    this.gameService.write('cookie', true);
    this.showDialogCookies = false;
  }

  ngOnInit(): void {
    this.gameService.clearGameData();
    this._calculateSliderHeight(window.innerWidth);
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'landing-page');
    this.subContrast.unsubscribe();
  }
}

<div class="mapka">
    <img class="map" *ngIf="!isHighContrast" src="../../../../assets/images/mapa_bez_kropek.png" />
    <img class="map" *ngIf="isHighContrast" src="../../../../assets/images/mapa_ud_bez_kropek.png" />
    <img class="map-point" *ngFor="let point of points"
        [ngClass]="getClass(point)" 
        [src]="getPointIcon(point)"
        (click)="pointClick(point)"/>
</div>

<!-- <agm-map #AgmMap
    [latitude]="points[0].lat" 
    [longitude]="points[0].lng"
    [styles]="styles">
    <agm-marker *ngFor="let point of points" 
        (markerClick)="markerClick(point)"
        [latitude]="point.lat" 
        [longitude]="point.lng"
        [iconUrl]="point.iconUrl">
    </agm-marker>
</agm-map> -->
<div *ngIf="!isCompleted" [ngClass]="isMobile ? 'mobile' : ''">
    <h1 title="Kamienica artystyczna">Kamienica artystyczna</h1>
    <ng-container *ngIf="gameType !== 'outdoor_team'">
        <p>
            Wchodzisz przez bramę. Z prawej strony widzisz uchylone drzwi prowadzące do niewielkiego pomieszczenia. 
            Nikłe promienie słońca wpadają do środka, rozświetlając panujący mrok. Podłoga wyścielona jest sianem oraz 
            leżącymi wokół piórami. Pod sufitem wisi klatka z Gołębiem, który obudził się na Twój widok. Ostrożnie 
            ją zdejmujesz z haczyka. Niestety, jest zamknięta na kłódkę, na której wymalowano kolorowe prostokąty. 
            Może nie bez powodu mieszkańcy Miasta, którym pomogłeś/aś na swojej trasie, zostawili Ci drobne podarunki?
        </p>        
    </ng-container>
    <ng-container *ngIf="gameType === 'outdoor_team'">
        <p>
            Wchodzicie przez bramę. Z prawej strony widzicie uchylone drzwi prowadzące do niewielkiego
            pomieszczenia. Nikłe promienie słońca wpadają do środka, rozświetlając panujący mrok. Podłoga
            wyścielona jest sianem oraz leżącymi wokół piórami. Pod sufitem wisi klatka z Gołębiem, który
            obudził się na Wasz widok. Ostrożnie ją zdejmujecie z haczyka. Niestety, jest zamknięta na kłódkę, na
            której wymalowano kolorowe prostokąty. Może nie bez powodu mieszkańcy Miasta, którym
            pomogliście na Waszej trasie, zostawili Wam drobne podarunki?
        </p>    
    </ng-container>

    <div class="lock">
        <img src="../../../../assets/images/klodka_b.png" />
        <input #input1 maxlength="1" type="text" class="input-purple" title="pole tekstowe w kolorze fioletowym"/>
        <input #input11 maxlength="1" type="text" class="input-purple second"  title="pole tekstowe w kolorze fioletowym"/>
        <input #input2 maxlength="1" type="text" class="input-green"  title="pole tekstowe w kolorze zielonym"/>
        <input #input22 maxlength="1" type="text" class="input-green second"  title="pole tekstowe w kolorze zielonym"/>
        <input #input3 maxlength="1" type="text" class="input-red"  title="pole tekstowe w kolorze czerwonym"/>
        <input #input33 maxlength="1" type="text" class="input-red second"  title="pole tekstowe w kolorze czerwonym"/>
        <input #input4 maxlength="1" type="text" class="input-yellow"  title="pole tekstowe w kolorze żółtym"/>
        <input #input5 maxlength="1" type="text" class="input-blue"  title="pole tekstowe w kolorze niebieskim"/>
    </div>

    <div class="actions">
        <div>
            <p *ngIf="message" class="incorrect">{{message}}</p>
        </div>
        <button class="button-next" 
            alt="sprawdzanie poprawności hasła"
            title="sprawdzanie poprawności hasła"
            mat-raised-button 
            (click)="goNext()"
            color="primary">SPRAWDŹ</button>   
    </div>
</div>

<div *ngIf="isCompleted">
    <h1 title="Opis z zakończeniem">Kamienica artystyczna</h1> 

    <ng-container *ngIf="gameType !== 'outdoor_team'">
        <p>
            Kod przez Ciebie wprowadzony okazał się poprawny i po jego wpisaniu klatka się otworzyła. 
            Gołąb przygląda Ci się przez chwilę, a następnie, z trudem trzepocąc skrzydłami, wylatuje 
            przez otwarte drzwi, upuszczając przy tym jedno ze swoich piórek. Podnosisz je i wychodzisz. 
            Na zewnątrz widzisz, jak Gołąb przelatuje obok dwóch kłócących się głośno osób, a te natychmiast 
            zamiast kontynuować ostrą wymianę zdań, zaczynają śmiać się i żartować po przyjacielsku. 
            Pewnie w całym mieście panuje teraz podobna atmosfera! 
        </p>
        <p>
            Gratulacje! Udało Ci się uwolnić Gołębia i uratować mieszkańców Miasta!         
        </p>
    </ng-container>
    <ng-container *ngIf="gameType === 'outdoor_team'">
        <p>
            Kod przez Was wprowadzony okazał się poprawny i po jego wpisaniu klatka się otworzyła. Gołąb
            przygląda Wam się przez chwilę, a następnie, z trudem trzepocąc skrzydłami, wylatuje przez otwarte
            drzwi, upuszczając przy tym jedno ze swoich piórek. Podnosicie je i wychodzicie. Na zewnątrz
            widzicie, jak Gołąb przelatuje obok dwóch kłócących się głośno osób, a te natychmiast zamiast
            kontynuować ostrą wymianę zdań, zaczynają śmiać się i żartować po przyjacielsku. Pewnie w całym
            mieście panuje teraz podobna atmosfera!
        </p>
        <p>
            Gratulacje! Udało się Wam uwolnić Gołębia i uratować mieszkańców Miasta!      
        </p>
    </ng-container>

    <div class="image">
        <img *ngIf="!isHighContrast" src="../../../../assets/images/ending.png"
            alt="grafika z białym gołębiem wyfruwającym z klatki"
            title="grafika z białym gołębiem wyfruwającym z klatki" />
        <img *ngIf="isHighContrast" src="../../../../assets/images/ending_u.png"
            alt="grafika z białym gołębiem wyfruwającym z klatki"
            title="grafika z białym gołębiem wyfruwającym z klatki" />            
    </div>
    
    <div class="actions">
        <button class="button-next" 
            alt="przejście dalej"
            title="przejście dalej"
            mat-raised-button 
            routerLink="/ending"
            color="primary">PRZEJDŹ DALEJ</button>   
    </div>   
</div>
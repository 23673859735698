<div *ngIf="!isCompleted" [ngClass]="isMobile ? 'mobile' : ''">
    <h1 title="Plac Grzybowski – zadanie">Plac Grzybowski</h1>
    <p *ngIf="gameType !== 'outdoor_team'">
        Porozmawiaj z właścicielem restauracji i spróbuj go przekonać, aby zatrudnił Samuela i Danielę. 
        Z odpowiedzi pojawiających na ekranie wybierz te, które według Ciebie skłonią właściciela restauracji do zmiany decyzji.
    </p>
    <p *ngIf="gameType === 'outdoor_team'">
        Wybierzcie z Waszego zespołu jedną osobę, która w Waszym imieniu porozmawia z właścicielem
        restauracji i spróbuje go przekonać, aby zatrudnił Samuela i Danielę. Z odpowiedzi pojawiających na
        ekranie wybierzcie te, które według Was skłonią właściciela restauracji do zmiany decyzji.
    </p>    

    <div class="dialog">
        <ng-container *ngIf="getQuestion().id === 1">
            <p class="user active">
                Dzień dobry chciałbym/chciałabym porozmawiać na temat zatrudnienia Samuela i Danieli.
            </p>
        </ng-container>
        <p class="host">{{ getQuestion().host }}</p>
        <ng-container *ngIf="getAnswers().length">
            <p *ngFor="let answer of getAnswers()" 
                class="user"
                title="wybierz właściwy dialog"
                (click)="chooseAnswer(answer.host)">{{answer.user}}</p>            
        </ng-container>
    </div>

    <div *ngIf="!getAnswers().length" class="actions">
        <button class="button-next" 
            alt="przejście dalej"
            title="przejście dalej"
            mat-raised-button 
            (click)="goNext()"
            color="primary">PRZEJDŹ DALEJ</button>   
    </div>
</div>

<div *ngIf="isCompleted">
    <h1 title="Plac Grzybowski – podsumowanie">Plac Grzybowski</h1>
    <div class="column">
        <ng-container *ngIf="gameType !== 'outdoor_team'">
            <p>W podziękowaniu za Twoją pomoc Samuel wysłał Ci wiadomość e-mail.</p>
            <p class="italic">Drogi Przyjacielu/Droga Przyjaciółko,</p>
            <p class="italic" *ngIf="currentHost !== 9">
                dzięki Twojej pomocy właściciel restauracji zatrudnił nas na okres próbny. Dziękujemy gorąco za Twoją pomoc i otwartość!
            </p>
            <p class="italic" *ngIf="currentHost === 9">
                niestety nie udało Ci się przekonać właściciela restauracji, aby nas zatrudnił. Mimo to gorąco dziękuję za Twoją pomoc 
                i otwartość! Daje to nadzieję, że dzięki przychylności osób tak wrażliwych jak Ty nasze poszukiwania zakończą się sukcesem.
            </p>
            <p class="italic">
                Nie znamy dobrze tutejszych zwyczajów, wyrastaliśmy w trochę innej kulturze. Wasze prawo jest inne od naszego, bardziej 
                skomplikowane. Nie znamy do końca języka polskiego i czasem nie jesteśmy dobrze zrozumiani. Niektórzy to tolerują, 
                ale zdarza się, że ktoś się z nas śmieje, bo mamy inny akcent albo przekręcamy jakieś słowa. Słyszeliśmy, że nawet 
                prawie połowa Polaków uważa, że nie powinniśmy otrzymywać wsparcia finansowego od państwa na podtrzymywanie własnej kultury 
                i tradycji. Jesteśmy tu na obcej ziemi, nie w naszej ojczyźnie, ale chcemy się tu osiedlić i żyć, żeby to miejsce było 
                naszym domem, miejscem nam bliskim. Wierzę, że uda się nam to zrobić. To, że nam pomogłeś/aś, pokazało nam, że można być wrażliwym 
                na drugiego, nawet jeśli on wydaje się obcy, bo przecież każdy człowiek ma w sobie coś wartościowego i zasługuje na to, 
                aby go szanować. Pociesza nas to, że są na tym świecie dobrzy ludzie. 
            </p>
            <p class="italic">
                Pozdrawiamy <br />
                Samuel z rodziną            
            </p>
            <p class="italic">
                PS Dołączam do mojej wiadomości zdjęcie, które ostatnio znalazłem w skrzynce pocztowej. Może Ci się przyda!
            </p>
        </ng-container>
        <ng-container *ngIf="gameType === 'outdoor_team'">
            <p>W podziękowaniu za Waszą pomoc Samuel wysłał Wam wiadomość e-mail.</p>
            <p class="italic">Drodzy Przyjaciele,</p>
            <p class="italic" *ngIf="currentHost !== 9">
                dzięki Waszej pomocy właściciel restauracji zatrudnił nas na okres próbny. Dziękujemy gorąco za Waszą pomoc i otwartość!
            </p>
            <p class="italic" *ngIf="currentHost === 9">
                niestety nie udało się Wam przekonać właściciela restauracji, aby nas zatrudnił. Mimo to gorąco
                dziękujemy za Waszą pomoc i otwartość! Daje to nadzieję, że dzięki przychylności osób tak
                wrażliwych jak Wy nasze poszukiwania zakończą się sukcesem.
            </p>
            <p class="italic">
                Nie znamy dobrze tutejszych zwyczajów, wyrastaliśmy w trochę innej kulturze. Wasze prawo jest inne
                od naszego, bardziej skomplikowane. Nie znamy do końca języka polskiego i czasem nie jesteśmy
                dobrze zrozumiani. Niektórzy to tolerują, ale zdarza się, że ktoś się z nas śmieje, bo mamy inny akcent
                albo przekręcamy jakieś słowa. Słyszeliśmy, że nawet prawie połowa Polaków uważa, że nie
                powinniśmy otrzymywać wsparcia finansowego od państwa na podtrzymywanie własnej kultury i
                tradycji. Jesteśmy tu na obcej ziemi, nie w naszej ojczyźnie, ale chcemy się tu osiedlić i żyć, żeby to
                miejsce było naszym domem, miejscem nam bliskim. Wierzę, że uda się nam to zrobić. To, że nam
                pomogliście, pokazało nam, że można być wrażliwym na drugiego, nawet jeśli on wydaje się obcy, bo
                przecież każdy człowiek ma w sobie coś wartościowego i zasługuje na to, aby go szanować. Pociesza
                nas to, że są na tym świecie dobrzy ludzie.
            </p>
            <p class="italic">
                Pozdrawiamy <br />
                Samuel z rodziną            
            </p>
            <p class="italic">
                PS Dołączam do mojej wiadomości zdjęcie, które ostatnio znalazłem w skrzynce pocztowej. Może Wam się przyda!
            </p>
        </ng-container>    
    </div>
    <div *ngIf="!isMobile" class="column center">
        <img src="../../../../assets/images/game2.png"
            alt="grafika przedstawiająca kolorowe zdjęcie małżeństwa"
            title="grafika przedstawiająca kolorowe zdjęcie małżeństwa" />
    </div>
    <div class="complete-award">
        <img *ngIf="!isHighContrast" src="../../../../assets/images/nr_19.png"
            alt="zdjęcie przedstawiające tramwaj z czerwonym numerem 19"
            title="zdjęcie przedstawiające tramwaj z czerwonym numerem 19" />
        <img *ngIf="isHighContrast" src="../../../../assets/images/nr_19_u.png"
            alt="grafika przedstawiająca numer 19"
            title="grafika przedstawiająca numer 19" />            
    </div>
    <div class="actions">
        <button class="button-next" 
            alt="powrót do mapy"
            title="powrót do mapy"
            mat-raised-button 
            routerLink="/map"
            color="primary">WRÓĆ DO MAPY</button>   
    </div>   
</div>
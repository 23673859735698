<div class="page-game-2023">
    <div class="content-page-header">
        <app-landing-header [showLogo]="false" [isHighContrast]=isHighContrast></app-landing-header>
    </div>
    <div class="content-page main">
        <div class="content-page-image"></div>
        <div class="content-relation">
            <h1>Edycja 2023</h1>
            <p>
              Tematem escape roomu „Sąsiedzka herbatka” była prawda o innych i o sobie. Zaprosiliśmy graczy do poszukiwania skrywanych emocji i niezaspokojonych potrzeb.
              Uczestnicy zabawy wcielili się w rolę dobrych przyjaciół z sąsiedztwa, którzy otrzymali zaproszenie na herbatkę do znajomego.
              Niestety mieszkanie okazało się puste, gospodarz nie zostawił żadnej wiadomości. Zadaniem uczestników gry było odnalezienie w pokoju pełnym wspomnień
              i pamiątek tropów prowadzących do rozwiązania zagadki nieobecności gospodarza.
            </p>
            <p>
              Gra była jednym z elementów Nocy Muzeów w murach Centrum Myśli Jana Pawła II, która odbyła się 13 maja 2023 roku.
            </p>
        </div>

        <div class="content-button center">
          <a mat-raised-button
            class="button"
            routerLink="/"
            alt="powrót na stronę główną"
            title="powrót na stronę główną"
            color="primary">Powrót do strony głównej</a>
        </div>

        <div class="video-container">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/vn-5xMSCKPs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div class="content-foto">
            <img *ngIf="showImage(1)" src="./assets/game-2023/1200-1.jpg" alt="Zdjęcie nr 1: Na pierwszym planie stolik z kwiatami, zestawem do przygotowania herbaty, w tle cztery osoby pochylone nad biurkiem i układające jakieś puzzle."/>
            <img *ngIf="showImage(2)" src="./assets/game-2023/1200-2.jpg" alt="Zdjęcie nr 2: Mężczyzna siedzi przy biurku przed laptopem, pisze coś w notatniku. Nad nim stoją pochylone dwie inne osoby."/>
            <img *ngIf="showImage(3)" src="./assets/game-2023/1200-3.jpg" alt="Zdjęcie nr 3: Kobieta próbuje otworzyć kłódkę przyczepioną do drewnianej skrzynki."/>
            <img *ngIf="showImage(4)" src="./assets/game-2023/1200-4.jpg" alt="Zdjęcie nr 4: Cztery osoby zaglądające pod kanapę, jedna z nich wyciąga stamtąd kartkę."/>
            <img *ngIf="showImage(5)" src="./assets/game-2023/1200-5.jpg" alt="Zdjęcie nr 5: Na stole stoi tablet odliczający czas."/>
            <img *ngIf="showImage(6)" src="./assets/game-2023/1200-6.jpg" alt="Zdjęcie nr 6: Na stole leży notatnik, kartka z szyfrem i kłódka. Kobieta stojąca przy stole pisze coś w notatniku. Obok niej stoi mężczyzna."/>
        </div>
        <div class="content-fotos">
            <div (click)="setImage(1);">
                <img src="./assets/game-2023/400-1.jpg" alt="Zdjęcie nr 1: Na pierwszym planie stolik z kwiatami, zestawem do przygotowania herbaty, w tle cztery osoby pochylone nad biurkiem i układające jakieś puzzle."/>
            </div>
            <div (click)="setImage(2);">
                <img src="./assets/game-2023/400-2.jpg" alt="Zdjęcie nr 2: Mężczyzna siedzi przy biurku przed laptopem, pisze coś w notatniku. Nad nim stoją pochylone dwie inne osoby."/>
            </div>
            <div (click)="setImage(3);">
                <img src="./assets/game-2023/400-3.jpg" alt="Zdjęcie nr 3: Kobieta próbuje otworzyć kłódkę przyczepioną do drewnianej skrzynki."/>
            </div>
            <div (click)="setImage(4);">
                <img src="./assets/game-2023/400-4.jpg" alt="Zdjęcie nr 4: Cztery osoby zaglądające pod kanapę, jedna z nich wyciąga stamtąd kartkę."/>
            </div>
            <div (click)="setImage(5);">
                <img src="./assets/game-2023/400-5.jpg" alt="Zdjęcie nr 5: Na stole stoi tablet odliczający czas."/>
            </div>
            <div (click)="setImage(6);">
                <img src="./assets/game-2023/400-6.jpg" alt="Zdjęcie nr 6: Na stole leży notatnik, kartka z szyfrem i kłódka. Kobieta stojąca przy stole pisze coś w notatniku. Obok niej stoi mężczyzna."/>
            </div>
        </div>
    </div>
    <div class="content-page footer">
        <img *ngIf="!isHighContrast" src="./assets/game-2023/logotypy.png" />
        <img *ngIf="isHighContrast" src="./assets/game-2023/logotypy_ud.png" />
    </div>
</div>


<div class="container-wrapper" [ngClass]="isMobile ? 'mobile' : ''">
<div class="container" [ngClass]="accessibillityClassName">  
  <app-accessibility></app-accessibility>  
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <div class="bottom-bars">
    <div class="bar-1"></div>
    <div class="bar-2"></div>
    <div class="bar-3"></div>
  </div>
</div>
</div>

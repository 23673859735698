<div class="page-game-2019">
    <div class="content-page-header">
        <app-landing-header [showLogo]="false" [isHighContrast]=isHighContrast></app-landing-header>
    </div>
    <div class="content-page main">
        <div class="content-page-image"></div>
        <div class="content-relation">
            <h1>Edycja 2020</h1>
            <p>
              W 2020 roku, ze względu na warunki pandemii, niemożliwe było ani przygotowanie,
              ani zrealizowanie klasycznej gry miejskiej, dlatego rozgrywka została przeniesiona tam,
              gdzie można się było wówczas spotykać, czyli do Internetu.
            </p>
            <p>
              Gra w tej edycji to ubiegłoroczna gra, która została przeniesiona w całości do sieci
              i poszerzona o liczne nagrania z pierwszej pielgrzymki do Polski, dzięki którym można było
              usłyszeć samego Jana Pawła II oraz doświadczyć entuzjazmu towarzyszącemu osobom, które wówczas go słuchały.
            </p>
            <p>
              Gra była dostępna online od 18 maja 2020 roku aż do końca 2021 roku.
            </p>
        </div>
        <div class="content-button center">
          <a mat-raised-button
            class="button"
            routerLink="/"
            alt="powrót na stronę główną"
            title="powrót na stronę główną"
            color="primary">Powrót do strony głównej</a>
        </div>
        <div class="content-foto">
            <img *ngIf="showImage(1)" src="./assets/game-2020/1200-1.jpg" alt="Mapa Polski w kolorze brązowym z zaznaczonymi na czerwono wybranymi miastami. W ramach Warszawy wyróżnione są dodatkowo miejsca Okęcie, Belweder i Plac Zwycięstwa. Dwa pierwsze miejsca połączone są strzałką."/>
            <img *ngIf="showImage(2)" src="./assets/game-2020/1200-2.jpg" alt="Napis Warszawa-Okęcie, a pod nim opis zadania. Poniżej labirynt, w którym znajdują się różne litery. Ścieżka prowadząca od początku do końca labiryntu układa się w słowo Polska. Pod labiryntem jest miejsce na wpisanie odpowiedzi."/>
            <img *ngIf="showImage(3)" src="./assets/game-2020/1200-3.jpg" alt="Napis Kalwaria Zebrzydowska, a pod nim opis zadania. Poniżej szyfr do rozwiązania z miejscem na wpisanie odpowiedzi."/>
            <!-- <img *ngIf="showImage(4)" src="./assets/game-2020/1200-4.jpg" alt=""/>
            <img *ngIf="showImage(6)" src="./assets/game-2020/1200-6.jpg" alt=""/> -->
        </div>
        <div class="content-fotos">
            <div (click)="setImage(1);">
                <img src="./assets/game-2020/400-1.jpg" alt="Mapa Polski w kolorze brązowym z zaznaczonymi na czerwono wybranymi miastami. W ramach Warszawy wyróżnione są dodatkowo miejsca Okęcie, Belweder i Plac Zwycięstwa. Dwa pierwsze miejsca połączone są strzałką."/>
            </div>
            <div (click)="setImage(2);">
                <img src="./assets/game-2020/400-2.jpg" alt="Napis Warszawa-Okęcie, a pod nim opis zadania. Poniżej labirynt, w którym znajdują się różne litery. Ścieżka prowadząca od początku do końca labiryntu układa się w słowo Polska. Pod labiryntem jest miejsce na wpisanie odpowiedzi."/>
            </div>
            <div (click)="setImage(3);">
                <img src="./assets/game-2020/400-3.jpg" alt="Napis Kalwaria Zebrzydowska, a pod nim opis zadania. Poniżej szyfr do rozwiązania z miejscem na wpisanie odpowiedzi."/>
            </div>
            <!-- <div (click)="setImage(4);">
                <img src="./assets/game-2020/400-4.jpg" />
            </div>
            <div (click)="setImage(6);">
                <img src="./assets/game-2020/400-6.jpg" />
            </div> -->
        </div>
    </div>
    <div class="content-page footer">
        <img *ngIf="!isHighContrast" src="./assets/game-2020/logotypy.svg" />
        <img *ngIf="isHighContrast" src="./assets/game-2020/logotypy_ud.svg" />
    </div>
</div>


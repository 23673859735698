import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GameService } from '../../services/game.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { AccessibilityService } from '../../services/accessibility.service';

@Component({
  selector: 'app-game5',
  templateUrl: './game5.component.html',
  styleUrls: ['./game5.component.scss']
})
export class Game5Component implements OnInit, OnDestroy {  
  private _gameNumber = 5;
  public isMobile: boolean = false;
  public isPreCompleted = this.gameService.getGameAdditional(this._gameNumber);
  public isCompleted = this.gameService.getGameCompleted(this._gameNumber);  
  public gameType: string = this.gameService.getGameType();
  public subContrast: Subscription;
  public isHighContrast = false;

  public words = [
    {
      id: 1,
      word: 'schody',
      completed: false,
      address: ["00x2", "01x2", "02x2", "03x2", "04x2", "05x2"],
      part: 'ruchome',
      partCompleted: false,
      title: "klocek do przeciągnięcia we właściwe okienko – schody",
      title2: "zdjęcie ruchomych schodów"
    },
    {
      id: 2,
      word: 'chodnik',
      completed: false,
      address:  ["01x2", "01x3", "01x4", "01x5", "01x6", "01x7", "01x8"],
      part: 'nierówny',
      partCompleted: false,
      title: "klocek do przeciągnięcia we właściwe okienko – chodnik",
      title2: "zdjęcie nierównego chodnika"
    },
    {
      id: 3,
      word: 'podjazd',
      completed: false,
      address: ["06x0", "07x0", "08x0", "09x0", "10x0", "11x0", "12x0"],
      part: 'stromy',
      partCompleted: false,
      title: "klocek do przeciągnięcia we właściwe okienko – podjazd",
      title2: "zdjęcie stromego podjazdu"
    },
    {
      id: 4,
      word: 'windy',
      completed: false,
      address: ["07x2", "07x3", "07x4", "07x5", "07x6"],
      part: 'brak',
      partCompleted: false,
      title: "klocek do przeciągnięcia we właściwe okienko – windy",
      title2: "zdjęcie klatki schodowej"
    },
    {
      id: 5,
      word: 'krawężnik',
      completed: false,
      address: ["07x8", "08x8", "09x8", "10x8", "11x8", "12x8", "13x8", "14x8", "15x8"],
      part: 'wysoki',
      partCompleted: false,
      title: "klocek do przeciągnięcia we właściwe okienko – krawężnik",
      title2: "zdjęcie wysokiego krawężnika"
    },
    {
      id: 6,
      word: 'tramwaj',
      completed: false,
      address: ["15x0", "15x1", "15x2", "15x3", "15x4", "15x5", "15x6"],
      part: 'wysokopodłogowy',
      partCompleted: false,
      title: "klocek do przeciągnięcia we właściwe okienko – tramwaj",
      title2: "zdjęcie tramwaju wysokopodłogowego"
    }
  ];

  public wordscopy = [
    {
      id: 4,
      word: 'windy',     
      part: 'brak',
      partCompleted: false,
      title: "klocek do przeciągnięcia we właściwe okienko – windy",
      title2: "zdjęcie klatki schodowej"
    },
    {
      id: 3,
      word: 'podjazd',
      part: 'stromy',
      partCompleted: false,
      title: "klocek do przeciągnięcia we właściwe okienko – podjazd",
      title2: "zdjęcie stromego podjazdu"
    },
    {
      id: 1,
      word: 'schody',      
      part: 'ruchome',
      partCompleted: false,
      title: "klocek do przeciągnięcia we właściwe okienko – windy",
      title2: "zdjęcie klatki schodowej"
    },    
    {
      id: 2,
      word: 'chodnik',      
      part: 'nierówny',
      partCompleted: false,
      title: "klocek do przeciągnięcia we właściwe okienko – chodnik",
      title2: "zdjęcie nierównego chodnika"
    },    
    {
      id: 6,
      word: 'tramwaj',
      part: 'wysokopodłogowy',
      partCompleted: false,
      title: "klocek do przeciągnięcia we właściwe okienko – tramwaj",
      title2: "zdjęcie tramwaju wysokopodłogowego"
    },
    {
      id: 5,
      word: 'krawężnik',
      part: 'wysoki',
      partCompleted: false,
      title: "klocek do przeciągnięcia we właściwe okienko – krawężnik",
      title2: "zdjęcie wysokiego krawężnika"
    }
  ];

  // public wordscopy = Object.assign([], this.words).sort();

  public table = [
    ['o', 'n', 's', 'g', 'i', 'o', 'y', 'w', 'u'], 
    ['i', 'b', 'c', 'h', 'o', 'd', 'n', 'i', 'k'],  
    ['u', 'm', 'h', 'd', 'f', 'a', 'j', 'm', 'g'],  
    ['m', 'l', 'o', 'f', 'k', 'g', 'd', 'ę', 'f'],  
    ['n', 'o', 'd', 'a', 'd', 'n', 'w', 'ł', 'm'],  
    ['b', 't', 'y', 'k', 'a', 'f', 'a', 'a', 'n'],  
    ['p', 'c', 'j', 'g', 'r', 'h', 'c', 'r', 't'],  
    ['o', 'p', 'w', 'i', 'n', 'd', 'y', 'ą', 'k'],  
    ['d', 'l', 'r', 'o', 'a', 's', 'ł', 'ł', 'r'],  
    ['j', 'k', 'f', 's', 'r', 'o', 'm', 't', 'a'],  
    ['a', 'h', 'm', 'a', 'k', 'l', 'f', 'k', 'w'],  
    ['z', 'j', 's', 'g', 'd', 'k', 'o', 't', 'ę'],  
    ['d', 'g', 'w', 'l', 'g', 'f', 'r', 'g', 'ż'],  
    ['z', 'm', 'n', 'b', 'u', 'o', 'p', 'b', 'n'],  
    ['w', 't', 'r', 'e', 'c', 'b', 'n', 'o', 'i'],  
    ['t', 'r', 'a', 'm', 'w', 'a', 'j', 'n', 'k'] 
  ];

  public lines = Array.from(Array(this.table.length),(x,i)=>i);
  public selected = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private gameService: GameService,
    private accessibilityService: AccessibilityService
  ) {
    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.subContrast = this.accessibilityService.onContrast().subscribe(value => {
      this.isHighContrast = value;      
    });
  }

  private getAddressName(i,j) {
    let is = i.toString();
    if (is.length < 2) {
      is = "0"+is;
    }
    return is + "x" + j.toString();
  }

  getParts() {
    return this.words.filter(i => i.partCompleted === false);
  }

  getCompletedParts() {
    return this.words.filter(i => i.partCompleted === true);
  }

  getClass(i:number, j:number) {    
    let cssClass = '';
    if (this.isCompleded(i, j)) {
      cssClass += ' completed';
    }
    if (this.isSelected(i, j)) {
      cssClass += ' selected';
    }
    return cssClass;
  }

  isSelected(i:number, j:number) {
    let address = this.getAddressName(i, j);
    return (this.selected.indexOf(address) > -1);
  }

  isCompleded(i:number, j:number) {
    let address = this.getAddressName(i, j);
    let completedWords = this.words.filter(i => i.completed === true);
    for (const word of completedWords) {    
      if (word.address.includes(address)) {
        return true;      
      }
    };    
    return false;
  }

  gameIsCompleted() {
    return (this.words.filter(i => i.completed === true).length === 6);
  }  

  isNeighbor(i:number, j:number) {
    if (this.selected.length === 0) {
      return true;
    } else if (this.selected.length === 1) {
      let current = this.selected[0].split('x');
      let iC = parseInt(current[0]);  
      let jC = parseInt(current[1])
      return ((((i === iC-1) || (i === iC+1)) && (j === jC)) 
        || (((j === jC-1) || (j === jC+1)) && (i === iC)));
    } else {
      let selected = this.selected.sort();
      let first = selected[0].split('x');
      let last = selected[selected.length - 1].split('x');
      if (first[0] !== last[0]) {
        let iF = parseInt(first[0]);  
        let iL = parseInt(last[0])
        return (((i === iF-1) || (i === iL+1)) && (j === parseInt(first[1])));
      } else {
        let jF = parseInt(first[1]);
        let jL = parseInt(last[1])
        return (((j === jF-1) || (j === jL+1)) && (i === parseInt(first[0])));
      }
    }
  }

  clickLetter(i:number, j:number) {    
    let address = this.getAddressName(i, j);

    if (!this.isSelected(i, j)) {
      if (!this.isNeighbor(i, j)) { return false; }
    }

    let index = this.selected.indexOf(address);
    if (index > -1) {      
      this.selected.splice(index, 1);
    } else {
      this.selected.push(address);
    }
    this.checkSelected();
  }

  private checkSelected() {
    this.words.forEach((word) => {      
      if (JSON.stringify(word.address.sort()) === JSON.stringify(this.selected.sort())) {
        word.completed = true;
        this.selected = [];
      }    
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subContrast.unsubscribe();
  }

  goNext() { 
    this.gameService.setGameAdditional(this._gameNumber, true);
    this.isPreCompleted = this.gameService.getGameAdditional(this._gameNumber);
    window.scroll(0,0);
  }

  goNext2() {
    this.gameService.setGameCompleted(this._gameNumber);
    this.isCompleted = this.gameService.getGameCompleted(this._gameNumber);
    window.scroll(0,0);
  }

  done = {
    "1": [],
    "2": [],
    "3": [],
    "4": [],
    "5": [],
    "6": []
  };

  drop(event: CdkDragDrop<string[]>) {
    let data = event.previousContainer.data[event.previousIndex]; 
    let answerContainers = ['schody', 'chodnik', 'windy', 'krawężnik', 'podjazd', 'tramwaj'];
    if (answerContainers.includes(event.container.id)) {
      if (event.container.data.length) {
        return false;
      }
    }

    if (data['word'] === event.container.id) {
      this.words.find(i => i.id === parseInt(data['id'])).partCompleted = true;
    }   

    transferArrayItem(event.previousContainer.data,
                      event.container.data,
                      event.previousIndex,
                      event.currentIndex);
  }
}

import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMapsAPIWrapper, AgmMap } from '@agm/core';
import { GameService } from '../../services/game.service';
import { Subscription } from 'rxjs';
import { AccessibilityService } from '../../services/accessibility.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit, OnDestroy {
  @ViewChild('AgmMap') agmMap: AgmMap;  
  @Output() markerClicked: EventEmitter<any> = new EventEmitter<any>();
  public subContrast: Subscription;
  public isHighContrast = false;

  private _getCircle(id: number) {
      return (this.gameService.getGameCompleted(id))? 
        './assets/images/circle_grey.png' : './assets/images/circle.png';
  }

  getClass(point) {
    return "map-point-"+point.id;
  }

  public getPointIcon(point) {
    if (this.isHighContrast) {
      if (point.id === 6) {
        return './assets/images/kropka2.png';
      }
      if (this.gameService.getGameCompleted(point.id)) {
        return './assets/images/kropka1a.png';
      } else {
        return './assets/images/kropka1.png';
      }  
    } else {
      if (point.id === 6) {
        return './assets/images/kropka1.png';
      }
      if (this.gameService.getGameCompleted(point.id)) {
        return './assets/images/kropka2a.png';
      } else {
        return './assets/images/kropka2.png';
      }
    }
  }

  public points = [
    {
      id: 1,
      title: "Liceum Ogólnokształcące",
      lat: 52.237114,
      lng: 21.019072,
      iconUrl: this._getCircle(1)
    },
    {
      id: 2,
      title: "Plac Grzybowski",
      lat: 52.236121, 
      lng: 21.003342,
      iconUrl: this._getCircle(2)
    },
    {
      id: 3,
      title: "Hala Gwardii",
      lat: 52.239412, 
      lng: 21.000179,
      iconUrl: this._getCircle(3)
    },
    {
      id: 4,
      title: "Ogród Saski",
      lat: 52.240843, 
      lng: 21.010508,
      iconUrl: this._getCircle(4)
    },
    {
      id: 5,
      title: "Park Świętokrzyski",
      lat: 52.233408, 
      lng: 21.005188,
      iconUrl: this._getCircle(5)
    },
    {
      id: 6,
      title: "Kamienica Artystyczna",
      lat: 52.233881, 
      lng: 21.021631,
      iconUrl: './assets/images/circle_yellow.png'
    }
  ];

  styles = [
    {
        "featureType": "all",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#e0dfe0"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#a8a9a8"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#5b5b5a"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
  ];

  constructor(
      private gameService: GameService,
      private accessibilityService: AccessibilityService
  ) { 
    this.subContrast = this.accessibilityService.onContrast().subscribe(value => {
      this.isHighContrast = value;      
    });      
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subContrast.unsubscribe();
  }

  ngAfterViewInit() {
    // this.agmMap.mapReady.subscribe(map => {
    //   const bounds = new google.maps.LatLngBounds();
    //   for (const mm of this.points) {
    //     bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
    //   }
    //   map.fitBounds(bounds);
    // });
  }

  pointClick(point) {
    this.markerClicked.emit(point);    
  }

  // markerClick(point) {
  //   this.markerClicked.emit(point);    
  // }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessibilityService } from 'src/app/shared/services/accessibility.service';

@Component({
  selector: 'app-game2019',
  templateUrl: './game2019.component.html',
  styleUrls: ['./game2019.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Game2019Component implements OnInit, OnDestroy {
  public subContrast: Subscription;
  public isHighContrast = false;
  public currentImage = 1;

  constructor(private accessibilityService: AccessibilityService) {
    this.subContrast = this.accessibilityService.onContrast().subscribe(value => {
      this.isHighContrast = value;      
    });
  }

  ngOnDestroy() {
    this.subContrast.unsubscribe();
  }

  showImage(num: number) {
    return (this.currentImage === num);
  }

  setImage(num: number) {
    this.currentImage = num;
  }

  ngOnInit(): void {
  }
}

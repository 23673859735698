import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatGridListModule } from '@angular/material/grid-list';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { LetterComponent } from './home/letter.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { AccessibilityComponent } from './shared/components/accessibility/accessibility.component';
import { LandingComponent } from './landing/landing.component';
import { MapComponent } from './shared/components/map/map.component';
import { MapPageComponent } from './map-page/map-page.component';
import { AgmCoreModule } from '@agm/core';
import { Game1PageComponent } from './game1-page/game1-page.component';
import { Game2PageComponent } from './game2-page/game2-page.component';
import { Game3PageComponent } from './game3-page/game3-page.component';
import { Game4PageComponent } from './game4-page/game4-page.component';
import { Game5PageComponent } from './game5-page/game5-page.component';
import { Game6PageComponent } from './game6-page/game6-page.component';
import { Game1Component } from './shared/components/game1/game1.component';
import { Game2Component } from './shared/components/game2/game2.component';
import { Game3Component } from './shared/components/game3/game3.component';
import { Game4Component } from './shared/components/game4/game4.component';
import { Game5Component } from './shared/components/game5/game5.component';
import { CheckAnswerComponent } from './shared/components/check-answer/check-answer.component';
import { TimerComponent } from './shared/components/timer/timer.component';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { Game6Component } from './shared/components/game6/game6.component';
import { FinishPageComponent } from './finish-page/finish-page.component';
import { EndingPageComponent } from './ending-page/ending-page.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { FormPageComponent } from './form-page/form-page.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FormsModule } from '@angular/forms';
import { LogoComponent } from './shared/components/logo/logo.component';
import { Game2021Component } from './game2021/game2021.component';
import { Landing2020Component } from './shared/components/landing2020/landing2020.component';
import { Landing2019Component } from './shared/components/landing2019/landing2019.component';
import { Landing2018Component } from './shared/components/landing2018/landing2018.component';
import { Landing2017Component } from './shared/components/landing2017/landing2017.component';
import { InfoComponent } from './pages/info/info.component';
import { StypendiaComponent } from './pages/stypendia/stypendia.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { LandingHeaderComponent } from './shared/landing-header/landing-header.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { LandingFooterComponent } from './shared/landing-footer/landing-footer.component';
import { Game2019Component } from './pages/game2019/game2019.component';
import { Game2018Component } from './pages/game2018/game2018.component';
import { Game2017Component } from './pages/game2017/game2017.component';
import { Landing2021Component } from './shared/components/landing2021/landing2021.component';
import { Game2020Component } from './pages/game2020/game2020.component';
import { Game2022Component } from './pages/game2022/game2022.component';
import { Game2023Component } from './pages/game2023/game2023.component';
import { Landing2022Component } from './shared/components/landing2022/landing2022.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LetterComponent,
    PageNotFoundComponent,
    AccessibilityComponent,
    LandingComponent,
    MapComponent,
    MapPageComponent,
    Game1Component,
    Game2Component,
    Game3Component,
    Game4Component,
    Game5Component,
    Game6Component,
    Game1PageComponent,
    Game2PageComponent,
    Game3PageComponent,
    Game4PageComponent,
    Game5PageComponent,
    Game6PageComponent,
    CheckAnswerComponent,
    TimerComponent,
    DialogComponent,
    FinishPageComponent,
    EndingPageComponent,
    FormPageComponent,
    LogoComponent,
    Game2021Component,
    Landing2022Component,
    Landing2020Component,
    Landing2019Component,
    Landing2018Component,
    Landing2017Component,
    Landing2021Component,
    InfoComponent,
    StypendiaComponent,
    KontaktComponent,
    LandingHeaderComponent,
    LandingFooterComponent,
    Game2023Component,
    Game2022Component,
    Game2020Component,
    Game2019Component,
    Game2018Component,
    Game2017Component
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatButtonModule,
    MatGridListModule,
    DragDropModule,
    IvyCarouselModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDKf0ZCsjENuLh5pT2w364-X43XnfvhU1c'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="wykreslanka" *ngIf="!isCompleted && !isPreCompleted" [ngClass]="isMobile ? 'mobile' : ''">
    <h1 title="Park Świętokrzyski - zadanie a">Park Świętokrzyski</h1>
    <ng-container *ngIf="gameType !== 'outdoor_team'">
        <p>
            Twoje zadanie składa się z dwóch części. W pierwszej kolejności rozwiąż wykreślankę, w której musisz 
            znaleźć 6 rzeczowników określających bariery w poruszaniu się osób z niepełnosprawnościami. 
            Następnie Twoim zadaniem będzie dopasowanie ich do odpowiednich określeń, w połączeniu z którymi rzeczowniki 
            stworzą całą nazwę danej bariery. 
        </p>        
    </ng-container>
    <ng-container *ngIf="gameType === 'outdoor_team'">
        <p>
            Wasze zadanie składa się z dwóch części. W pierwszej kolejności rozwiążcie wykreślankę, w której
            musicie znaleźć 6 rzeczowników określających bariery w poruszaniu się osób z
            niepełnosprawnościami. Następnie Waszym zadaniem będzie dopasowanie ich do odpowiednich
            określeń, w połączeniu z którymi rzeczowniki stworzą całą nazwę danej bariery.
        </p>    
    </ng-container>

    <div class="table">
        <div *ngFor="let line of lines; let i = index"
            class="line">
            <div *ngFor="let letter of table[i]; let j = index"
                class="letter"
                [ngClass]="getClass(i,j)"
                (click)="clickLetter(i,j)">
                {{ letter.toUpperCase() }}
            </div>
        </div>
    </div>

    <div class="actions">
        <button *ngIf="gameIsCompleted();" class="button-next" 
            alt="przejście dalej"
            title="przejście dalej"
            mat-raised-button 
            (click)="goNext()"
            color="primary">PRZEJDŹ DALEJ</button>   
    </div>
</div>

<div class="dragdroptask" *ngIf="!isCompleted && isPreCompleted" [ngClass]="isMobile ? 'mobile' : ''">
    <h1 title="Park Świętokrzyski - zadanie b">Park Świętokrzyski</h1>

    <div style="margin-bottom: 3%">
        <div *ngFor="let part of getCompletedParts()"
            class="completed-parts answers-container">
            <div class="column column-image" style="text-align: center;">
                <img src="../../../../assets/images/bariery/bariera_{{part.id}}.png"
                    alt="{{ part.title2 }}"
                    title="{{ part.title2 }}" />
            </div>
            <div class="column column-text">
                <p class="centered">
                    {{part.part}} {{part.word}}
                </p>
            </div>
        </div>
    </div>

    <ng-container *ngIf="getParts().length !== 0">
        <div cdkDropListGroup>
            <div class="words-list">
            <div
                cdkDropList
                [cdkDropListData]="wordscopy"
                class="list"
                (cdkDropListDropped)="drop($event)">
                <div class="box" 
                    *ngFor="let item of wordscopy" 
                    cdkDrag
                    alt="{{ item.title }}"
                    title="{{ item.title }}" >{{item.word}}</div>
            </div>          
            </div>

            <div *ngFor="let part of getParts()" 
                class="answers-container">
                <div class="column" style="text-align: right;">
                    <p>{{part.part}}</p>
                </div>            
                <div class="column" style="text-align: right;">
                    <div class="answer-list" title="miejsce na klocek z tekstem">
                        <div
                            cdkDropList
                            [cdkDropListData]="done[part.id]"
                            id="{{part.word}}"
                            class="list"
                            (cdkDropListDropped)="drop($event)">
                            <div class="box" 
                                *ngFor="let item of done[part.id]" 
                                cdkDrag>{{item.word}}</div>
                        </div>
                    </div>
                </div>
            </div>           
        </div>
    </ng-container>

    <div class="actions">
        <button *ngIf="(getCompletedParts().length === 6)" class="button-next" 
            title="przejście dalej"
            alt="przejście dalej"
            mat-raised-button 
            (click)="goNext2()"
            color="primary">PRZEJDŹ DALEJ</button>   
    </div>
    
</div>

<div *ngIf="isCompleted && isPreCompleted">
    <h1 title="Park Świętokrzyski – podsumowanie">Park Świętokrzyski</h1>
    <div class="column">
        <ng-container *ngIf="gameType !== 'outdoor_team'">
            <p>
                Dobra robota! Dziękuję, że pomogłeś/aś napisać mi raport. W dowód wdzięczności przyjmij ode mnie tę kopertę, 
                którą niedawno ktoś zostawił pod moimi drzwiami. Mam nadzieję, że jej zawartość do czegoś Ci się przyda.    
            </p>
        </ng-container>
        <ng-container *ngIf="gameType === 'outdoor_team'">
            <p>
                Dobra robota! Dziękuję, że pomogliście napisać mi raport. W dowód wdzięczności przyjmijcie ode
                mnie tę kopertę, którą niedawno ktoś zostawił pod moimi drzwiami. Mam nadzieję, że jej zawartość
                do czegoś Wam się przyda.        
            </p>
        </ng-container> 
    </div>
    <div *ngIf="!isMobile" class="column center">
        <img src="../../../../assets/images/game5.png"
            title="grafika przedstawiająca kolorowe zdjęcie mężczyzny na wózku inwalidzkim"
            alt="grafika przedstawiająca kolorowe zdjęcie mężczyzny na wózku inwalidzkim" />
    </div>
    <div class="complete-award">
        <img *ngIf="!isHighContrast" src="../../../../assets/images/nr_7.png"
            title="koperta z żółtym numerem 7"
            alt="koperta z żółtym numerem 7" />
        <img *ngIf="isHighContrast" src="../../../../assets/images/nr_7_u.png"
            title="grafika z numerem 7"
            alt="grafika z numerem 7" />            
    </div>
    <div class="actions">
        <button class="button-next" 
            alt="powrót do mapy"
            title="powrót do mapy"
            mat-raised-button 
            routerLink="/map"
            color="primary">WRÓĆ DO MAPY</button>   
    </div>   
</div>
<div class="game-menu">
    <div class="left-side">
        <h1>Gra o Wolność</h1>
        <h2>edycja 2017</h2>
    </div>
    <div class="center-side">
        <p>
            W ramach przygotowań do świętowania 100. rocznicy odzyskania przez Polskę niepodległości,
            dokładnie rok wcześniej, czyli 11 listopada 2017 r. po raz pierwszy zaprosiliśmy warszawiaków
            do „Gry o Wolność”. Podczas rozgrywki gracze mogli przybliżyć sobie 10 wybranych wydarzeń
            z historii Polski, które wyznaczały drogi Polaków do wolności, m.in. Chrzest Polski,
            Potop Szwedzki, Bitwa Warszawska i Powstanie Warszawskie.
        </p>
    </div>
    <div class="right-side">
        <a mat-raised-button
            class="button"
            routerLink="/gra-2017"
            title="przejście do szerszego opisu edycji"
            alt="przejście do szerszego opisu edycji"
            color="primary">Dowiedz się więcej</a>
    </div>
</div>

<div class="page-game-2022">
    <div class="content-page-header">
        <app-landing-header [showLogo]="false" [isHighContrast]=isHighContrast></app-landing-header>
    </div>
    <div class="content-page main">
        <div class="content-page-image"></div>
        <div class="content-relation">
            <h1>Edycja 2022</h1>
            <p>
              Tematem przewodnim gry była solidarność. Zadanie graczy polegało na wyjaśnieniu Norbertowi – głównemu
              bohaterowi gry – znaczenia tego pojęcia. Gracze mogli odkryć na nowo znaczenie solidarności poprzez
              różnorodne zadania wymagające logicznego myślenia, sprytu, a zarazem refleksji i uważności.
            </p>
            <p>
              Wybrana tematyka gry wiązała się z obchodzoną w 2022 roku 35. rocznicą trzeciej pielgrzymki Jana Pawła II do Polski,
              w czasie której papież w wielu przemówieniach odnosił się do solidarności – zarówno tej pisanej wielką, jak i małą
              literą. Ponadto temat ten stał się dla nas znów bardzo ważny w sytuacji trwającej wojny w Ukrainie.
            </p>
            <p>
              Tegoroczna gra zrealizowana została, 2 latach przerwy, stacjonarnie – w przestrzeni miejskiej i z udziałem animatorów.
              Była przyjazna dla uczestników ukraińskojęzycznych, których również zaproszono do wspólnej zabawy.
            </p>
            <p>
              Gra odbyła się 22 maja 2022 roku w śródmieściu Warszawy.
            </p>
            <p>
              Dziękujemy serdecznie wszystkim instytucjom, które zgodziły się udostępnić swoje pomieszczenia na realizację
              poszczególnych punktów gry. Były to Dom Spotkań z Historią, Państwowe Muzeum Etnograficzne, Polska Akademia Nauk,
              XXXVII Liceum Ogólnokształcące im. Jarosława Dąbrowskiego oraz Stowarzyszenie BORIS.
            </p>
        </div>
        <div class="content-button center">
          <a mat-raised-button
            class="button"
            routerLink="/"
            alt="powrót na stronę główną"
            title="powrót na stronę główną"
            color="primary">Powrót do strony głównej</a>
        </div>

        <div class="video-container">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/jKMnCrQRNlA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div class="content-foto">
            <img *ngIf="showImage(1)" src="./assets/game-2022/1200-1.jpg" alt="Chłopiec trzyma w ręku kartkę z logotypem Gry. Na kartce znajduje się tekst pod tytułem opis Wioli. Przed chłopcem stoi dziewczyna."/>
            <img *ngIf="showImage(2)" src="./assets/game-2022/1200-2.jpg" alt="Klasa szkolna. Uśmiechnięta dziewczyna stoi nad grupą siedzącą przy ławce szkolnej. Jeden z siedzących chłopców z radością rzuca samolotem z papieru."/>
            <img *ngIf="showImage(3)" src="./assets/game-2022/1200-3.jpg" alt="Nad stołem stoi nachylonych dwoje ludzi, którzy rozwiązują rebus. Obok leży broszura Gry o Wolność."/>
            <img *ngIf="showImage(4)" src="./assets/game-2022/1200-4.jpg" alt="Grupa młodych ludzi pochyla się nad porozrzucanymi na podłodze warzywami zawiniętymi w folię i układa je."/>
            <img *ngIf="showImage(5)" src="./assets/game-2022/1200-5.jpg" alt="Grupa sześciu osób przegląda książki, szukając w nich czegoś. W grupie są dorośli oraz dzieci."/>
            <img *ngIf="showImage(6)" src="./assets/game-2022/1200-6.jpg" alt="Na sznurkach wiszą kartki z tekstami dotyczącymi tego jak budować solidarność. Teksty napisane są odręcznie. Przyczepione są do sznurków drewnianymi spinaczami."/>
        </div>
        <div class="content-fotos">
            <div (click)="setImage(1);">
                <img src="./assets/game-2022/400-1.jpg" alt="Chłopiec trzyma w ręku kartkę z logotypem Gry. Na kartce znajduje się tekst pod tytułem opis Wioli. Przed chłopcem stoi dziewczyna."/>
            </div>
            <div (click)="setImage(2);">
                <img src="./assets/game-2022/400-2.jpg" alt="Klasa szkolna. Uśmiechnięta dziewczyna stoi nad grupą siedzącą przy ławce szkolnej. Jeden z siedzących chłopców z radością rzuca samolotem z papieru."/>
            </div>
            <div (click)="setImage(3);">
                <img src="./assets/game-2022/400-3.jpg" alt="Nad stołem stoi nachylonych dwoje ludzi, którzy rozwiązują rebus. Obok leży broszura Gry o Wolność."/>
            </div>
            <div (click)="setImage(4);">
                <img src="./assets/game-2022/400-4.jpg" alt="Grupa młodych ludzi pochyla się nad porozrzucanymi na podłodze warzywami zawiniętymi w folię i układa je."/>
            </div>
            <div (click)="setImage(5);">
                <img src="./assets/game-2022/400-5.jpg" alt="Grupa sześciu osób przegląda książki, szukając w nich czegoś. W grupie są dorośli oraz dzieci."/>
            </div>
            <div (click)="setImage(6);">
                <img src="./assets/game-2022/400-6.jpg" alt="Na sznurkach wiszą kartki z tekstami dotyczącymi tego jak budować solidarność. Teksty napisane są odręcznie. Przyczepione są do sznurków drewnianymi spinaczami."/>
            </div>
        </div>
    </div>
    <div class="content-page footer">
        <img *ngIf="!isHighContrast" src="./assets/game-2022/logotypy.png" />
        <img *ngIf="isHighContrast" src="./assets/game-2022/logotypy_ud.png" />
    </div>
</div>


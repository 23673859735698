<app-logo></app-logo>
<div class="content-map" [ngClass]="isMobile ? 'mobile' : ''">
    <h1>Mapa Wielkiego Miasta</h1>
    <ng-container *ngIf="isDescriptionHidden === 'false'">
        <div *ngIf="gameType === 'online'" class="description">
            <p>
                Podczas podróży poznasz historie kilkorga mieszkańców Miasta, którzy z powodu różnych trudnych
                sytuacji, w których się znaleźli, nie mogą w pełni cieszyć się swoją wolnością. Miejsca, gdzie możesz
                ich spotkać, zaznaczono na mapie <img src="../../assets/images/circle.png" alt="czrwony punkt na mapie z zadaniem" title="czrwony punkt na mapie z zadaniem" />. 
                To Ty decydujesz, w jakiej kolejności się do nich wirtualnie
                udasz. Wybór każdego kolejnego miejsca potwierdź, klikając w odpowiedni punkt na mapie. Pomóż
                napotkanym mieszkańcom Miasta, a otrzymasz klucz, który pozwoli Ci uwolnić Gołębia. Po
                właściwym wykonaniu poszczególnych zadań, otrzymasz od spotkanych osób liczby. Każda będzie
                miała inny kolor.
            </p>
            <p>
                Miejsce, gdzie Gołąb jest więziony oznaczono <img src="../../assets/images/circle_yellow.png"  alt="żółty punkt na mapie z zadaniem finałowym" title="żółty punkt na mapie z zadaniem finałowym"/>. Tutaj należy udać się dopiero po spotkaniu ze
                wszystkimi mieszkańcami Miasta. Zostaniesz wówczas poproszony/a o wpisanie właściwego kodu,
                którego fragmenty będziesz zbierać podczas Twojej podróży, dlatego zapamiętaj kolor i liczbę
                otrzymanych wskazówek.
            </p>
        </div>
        <div *ngIf="gameType === 'outdoor_single'" class="description">
            <p>
                Podczas podróży poznasz historie kilkorga mieszkańców Miasta, którzy z powodu różnych trudnych
                sytuacji, w których się znaleźli, nie mogą w pełni cieszyć się swoją wolnością. Miejsca, gdzie możesz
                ich spotkać, zaznaczono na mapie <img src="../../assets/images/circle.png"  alt="czrwony punkt na mapie z zadaniem" title="czrwony punkt na mapie z zadaniem"/>. To Ty decydujesz, w jakiej kolejności się do nich udasz. Po
                dotarciu do danego miejsca, potwierdź swoją lokalizację, klikając w odpowiedni punkt na mapie.
                Pomóż napotkanym mieszkańcom Miasta, a otrzymasz klucz, który pozwoli Ci uwolnić Gołębia. Po
                właściwym wykonaniu poszczególnych zadań, otrzymasz od spotkanych osób liczby. Każda będzie
                miała inny kolor.
            </p>
            <p>
                Miejsce, gdzie Gołąb jest więziony oznaczono <img src="../../assets/images/circle_yellow.png"  alt="żółty punkt na mapie z zadaniem finałowym" title="żółty punkt na mapie z zadaniem finałowym"/>. Tutaj należy przyjść dopiero po spotkaniu ze
                wszystkimi mieszkańcami Miasta. Na miejscu zostaniesz poproszony/a o wpisanie właściwego kodu,
                którego fragmenty będziesz zbierać podczas Twojej podróży, dlatego zapamiętaj kolor i liczbę
                otrzymanych wskazówek.
            </p>
        </div>
        <div *ngIf="gameType === 'outdoor_team'" class="description">
            <p>
                Podczas podróży poznacie historie kilkorga mieszkańców Miasta, którzy z powodu różnych trudnych
                sytuacji, w których się znaleźli, nie mogą w pełni cieszyć się swoją wolnością. Miejsca, gdzie możecie
                ich spotkać, zaznaczono na mapie <img src="../../assets/images/circle.png"  alt="czrwony punkt na mapie z zadaniem" title="czrwony punkt na mapie z zadaniem"/>. To Wy decydujecie, w jakiej kolejności się do nich udacie. Po
                dotarciu do danego miejsca, potwierdźcie swoją lokalizację, klikając w odpowiedni punkt na mapie.
                Pomóżcie napotkanym mieszkańcom Miasta, a otrzymacie klucz, który pozwoli Wam uwolnić
                Gołębia. Po właściwym wykonaniu poszczególnych zadań, otrzymacie od spotkanych osób liczby.
                Każda będzie miała inny kolor.
            </p>
            <p>
                Miejsce, gdzie Gołąb jest więziony oznaczono <img src="../../assets/images/circle_yellow.png"  alt="żółty punkt na mapie z zadaniem finałowym" title="żółty punkt na mapie z zadaniem finałowym"/>. Tutaj należy przyjść dopiero po spotkaniu ze
                wszystkimi mieszkańcami Miasta. Na miejscu zostaniecie poproszeni o wpisanie właściwego kodu,
                którego fragmenty będziecie zbierać podczas Waszej podróży, dlatego zapamiętajcie kolor i liczbę
                otrzymanych wskazówek.
            </p>
        </div>
        <button mat-raised-button 
            alt="ukryj instrukcję"
            title="ukryj instrukcję" 
            color="primary"
            (click)="hideDescription()">Ukryj instrukcję</button>
    </ng-container>
    <ng-container *ngIf="isDescriptionHidden === 'true'">
        <button mat-raised-button 
            alt="pokaż instrukcję"
            title="pokaż instrukcję" 
            color="primary"
            (click)="showDescription()">Instrukcja</button>
    </ng-container>
    <app-map (markerClicked)="markerClicked($event)"></app-map>    
</div>

<app-dialog *ngIf="showDialogYellow" (isClose)="closeDialogYellow($event)">
    <ng-container *ngIf="gameType !== 'outdoor_team'">
        <p>
            Nie spotkałeś się jeszcze ze wszystkimi mieszkańcami Miasta.
            <br />Znajdź ich.
        </p>
    </ng-container>
    <ng-container *ngIf="gameType === 'outdoor_team'">
        <p>
            Nie spotkaliście się jeszcze ze wszystkimi mieszkańcami Miasta.
            <br />Znajdźcie ich.
        </p>
    </ng-container>   
</app-dialog>

<app-dialog *ngIf="showDialogFinish" (isClose)="closeDialogFinish($event)">
    <ng-container *ngIf="gameType !== 'outdoor_team'">
        <p>
            Gratulacje! Zebrałeś/aś już wszystkie potrzebne elementy do uwolnienia Gołębia. 
            Aby zakończyć grę, udaj się do ostatniego punktu na trasie oznaczonego  
            <img src="../../assets/images/circle_yellow.png" />.
        </p>
    </ng-container>
    <ng-container *ngIf="gameType === 'outdoor_team'">
        <p>
            Gratulacje! Zebraliście już wszystkie potrzebne elementy do uwolnienia Gołębia. 
            Aby zakończyć grę, udajcie się do ostatniego punktu na trasie oznaczonego 
            <img src="../../assets/images/circle_yellow.png" />.
        </p>
    </ng-container>    
</app-dialog>



<div class="game-menu">
    <div class="left-side">
        <h1>Gra o Wolność</h1>
        <h2>edycja 2021</h2>
    </div>
    <div class="center-side">
        <p>
          W grze z 2021 roku gracze zachęceni są do odkrycia wolności jako jednej z dróg do budowania pokoju.
          Mają do wyboru dwie wersje gry: mobilną grę terenową, wymagającą pokonania ściśle określonej trasy w śródmieściu
          Warszawy oraz taką, w którą można zagrać online nie wychodząc z domu.<br />
          Zachęcamy do zapoznania się z <a href="../../assets/regulamin-gra2021.pdf"
          target="_blank">regulaminem Gry</a> i życzymy dobrej zabawy!
        </p>
    </div>
    <div class="right-side">

        <a mat-raised-button
            class="button"
            alt="przejście do gry miejskiej indywidualnej"
            title="przejście do gry miejskiej indywidualnej"
            color="primary"
            (click)="setGameType('outdoor_single')">Gra miejska indywidualna</a>

        <a mat-raised-button
            class="button"
            alt="przejście do gry miejskiej zespołowej"
            title="przejście do gry miejskiej zespołowej"
            color="primary"
            (click)="setGameType('outdoor_team')">Gra miejska zespołowa</a>

        <a mat-raised-button
            class="button"
            alt="przejście do gry online"
            title="przejście do gry online"
            color="primary"
            (click)="setGameType('online')">Gra online</a>

    </div>
</div>

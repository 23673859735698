<div class="content-page-header">
    <app-landing-header [isHighContrast]=isHighContrast></app-landing-header>
    <div class="title title-info">
        <h1></h1>
    </div>
</div>
<div class="content-page">
    <h2>Kontakt</h2>
    <div class="center">
        <p>
            <strong>
                Dział Stypendialno-Edukacyjny<br/> 
                Centrum Myśli Jana Pawła II
            </strong> 
        </p>
        <p>ul. Foksal 11</p>
        <p>00–372 Warszawa</p>
        <p>tel. + 48 22 826 42 22</p>
        <p>stypendia@centrumjp2.pl</p>
    </div>
</div>

<app-landing-footer [isHighContrast]=isHighContrast></app-landing-footer>
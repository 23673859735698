import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ComponentFactoryResolver, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-check-answer',
  templateUrl: './check-answer.component.html',
  styleUrls: ['./check-answer.component.scss']
})
export class CheckAnswerComponent implements OnInit {
  @Input() answer: string;
  @Output() isCorrect: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('input') input: ElementRef; 
  public isMobile: boolean = false;
  
  public answerState: boolean = true;

  constructor(
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
  }

  checkClick() {   
    let answers = this.answer.split('/');
    answers.forEach((value, idx, array) => {
      if (this._normalize(this.input.nativeElement.value) === this._normalize(value)) {
        this.answerState = true;
        this.isCorrect.emit(this.answerState);
        return false;
      } else {
        if (idx === array.length - 1) { 
          this.answerState = false;
          this.isCorrect.emit(this.answerState);
        }
      }
    });
  }

  private _normalize(value: string) {
    if (value === null) { return value; };
    let s = value.toLowerCase();
    s = s.replace(/ę/ig,'e');
    s = s.replace(/ż/ig,'z');
    s = s.replace(/ó/ig,'o');
    s = s.replace(/ł/ig,'l');
    s = s.replace(/ć/ig,'c');
    s = s.replace(/ś/ig,'s');
    s = s.replace(/ź/ig,'z');
    s = s.replace(/ń/ig,'n');
    s = s.replace(/ą/ig,'a');
    return s;
  }  
}

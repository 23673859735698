import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingFooterComponent implements OnInit {
  @Input() isHighContrast: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}

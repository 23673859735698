<div class="game-menu">
    <div class="left-side">
        <h1>Gra o Wolność</h1>
        <h2>edycja 2019</h2>
    </div>
    <div class="center-side">
        <p>
            W 40. rocznicę pierwszej pielgrzymki Jana Pawła II uczestnicy gry mieli okazję lepiej poznać
            nie tylko kontekst historyczny wizyty papieża w czerwcu 1979 roku, ale przede wszystkim
            aktualność słów wypowiedzianych wówczas przez Jana Pawła II i ich znaczenie dla Polski
            i Polaków na przestrzeni ostatnich 40 lat. Gra miała charakter zabawy edukacyjnej rozgrywanej
            za pomocą zamieszczonych na tematycznych planszach kodów QR.
        </p>
    </div>
    <div class="right-side">
        <a mat-raised-button
            class="button"
            routerLink="/gra-2019"
            alt="przejście do szerszego opisu edycji"
            title="przejście do szerszego opisu edycji"
            alt="przejście do szerszego opisu edycji"
            color="primary">Dowiedz się więcej</a>
    </div>
</div>

<div *ngIf="!isCompleted" [ngClass]="isMobile ? 'mobile' : ''">
    <h1 title="Ogród Saski – zadanie">Ogród Saski</h1>
    <ng-container *ngIf="gameType !== 'outdoor_team'">
        <p>
            Wybierz najbardziej przekonujące argumenty, które przemówią do szantażysty, by przestał prześladować kolegę. 
        </p>        
    </ng-container>
    <ng-container *ngIf="gameType === 'outdoor_team'">
        <p>
            Wybierzcie najbardziej przekonujące argumenty, które przemówią do szantażysty, by przestał prześladować kolegę.
        </p>    
    </ng-container>

    <div class="dialog">
        <p *ngFor="let answer of answers"
            class="host">
            {{ answer }}
        </p>
        <ng-container *ngIf="answers.length > 5">
            <p class="host" *ngIf="gameType !== 'outdoor_team'">
                Przemyśl to wszystko i zastanów się poważnie, czy nie powinieneś dać mu spokój. Myślę, że i ty poczujesz się wtedy lepiej! 
            </p>
            <p class="host" *ngIf="gameType === 'outdoor_team'">
                Przemyśl to wszystko i zastanów się poważnie, czy nie powinieneś dać mu spokój. Myślimy, że i ty
                poczujesz się wtedy lepiej!
            </p>        
        </ng-container> 
    </div>
    <div *ngIf="answers.length < 6" class="dialog-item">
        <p class="host">
            {{ getHost(getCurrentDialogItem()) }}
        </p>
        <p *ngFor="let answer of getCurrentDialogItem().answers" 
            class="user"
            title="wybierz właściwy dialog"
            (click)="chooseAnswer(answer)">
            {{ getAnswer(answer) }}
        </p>    
    </div>



    <div class="actions" *ngIf="answers.length > 5">
        <button class="button-next" 
            title="przejście dalej"
            alt="przejście dalej"
            mat-raised-button 
            (click)="goNext()"
            color="primary">ZOBACZ REAKCJĘ PIOTRKA</button>   
    </div>
</div>

<div *ngIf="isCompleted">
    <h1 title="Ogród Saski – podsumowanie">Ogród Saski </h1>
    <div class="column">
        <p>
            <i>{{ getPeterAnswer() }}</i>
        </p>
        <p>
            Po tych słowach Piotrek odszedł, a z jego kieszeni wypadła mała karteczka.            
        </p>
    </div>
    <div *ngIf="!isMobile" class="column center">
        <img src="../../../../assets/images/game4.png" 
            alt="grafika przedstawiająca kolorowe zdjęcie smutnego chłopca"
            title="grafika przedstawiająca kolorowe zdjęcie smutnego chłopca" />
    </div>
    <div class="complete-award">
        <img *ngIf="!isHighContrast" src="../../../../assets/images/nr_16.png" 
            alt="karteczka z fioletowym numerem 16"
            title="karteczka z fioletowym numerem 16" />
        <img *ngIf="isHighContrast" src="../../../../assets/images/nr_16_u.png" 
            alt="grafika z numerem 16"
            title="grafika z numerem 16" />            
    </div>
    <div class="actions">
        <button class="button-next" 
            alt="powrót do mapy"
            title="powrót do mapy"
            mat-raised-button 
            routerLink="/map"
            color="primary">WRÓĆ DO MAPY</button>   
    </div>   
</div>
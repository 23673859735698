import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessibilityService } from '../../services/accessibility.service';
import { GameService } from '../../services/game.service';

@Component({
  selector: 'app-game4',
  templateUrl: './game4.component.html',
  styleUrls: ['./game4.component.scss']
})
export class Game4Component implements OnInit, OnDestroy {  
  private _gameNumber = 4;
  public isMobile: boolean = false;
  public isCompleted = this.gameService.getGameCompleted(this._gameNumber);  
  public gameType: string = this.gameService.getGameType();
  public subContrast: Subscription;
  public isHighContrast = false;

  private points: number = this.gameService.getGameAdditional(this._gameNumber);
  public answers: Array<any> = [];
  public currentDialogItem = 1;
  public dialog = [
    {
      id: 1,      
      host: "Piotrku, słyszałem/am o tym, co jest między tobą a Kubą, czy nie uważasz jednak, że: ",
      hosts: "Piotrku, słyszeliśmy o tym, co jest między tobą a Kubą, czy nie uważasz jednak, że: ",
      answers: [
        {
          id: 1,
          value: "tylko głupki wykorzystują innych?",
          points: 0
        },
        {
          id: 2,
          value: "lepiej dzielić się z innymi, zamiast im zabierać?",
          points: 1
        },
        {
          id: 3,
          value: "takie zachowanie to zdrada kolegi, przyjaciela?",
          points: 2
        }
      ]
    },
    {
      id: 2,
      host: "Co więcej, pamiętaj – ",
      answers: [
        {
          id: 1,
          value: "takie zachowanie z twojej strony jest niemiłe.",
          points: 0
        },
        {
          id: 2,
          value: "jeśli potrzebujesz pieniędzy, powinieneś poprosić rodziców zamiast wymuszać od innych!",
          points: 2
        },
        {
          id: 3,
          value: "Kuba przez to cierpi.",
          points: 1
        }
      ]
    },
    {
      id: 3,
      host: "Może przekona cię to że, ",
      answers: [
        {
          id: 1,
          value: "jeśli zaczniesz życie w ten sposób, w przyszłości skończysz w więzieniu.",
          points: 1
        },
        {
          id: 2,
          value: "przemoc rodzi przemoc.",
          points: 2
        },
        {
          id: 3,
          value: "trzeba być prawdziwą pleciugą, żeby zdradzać tajemnice kolegów.",
          points: 0
        }
      ]
    },
    {
      id: 4,
      host: "Zastanów się też, ",
      answers: [
        {
          id: 1,
          value: "co będzie, jak sam się zakochasz?",
          points: 1
        },
        {
          id: 2,
          value: "co na to wszystko powiedzą twoi rodzice?",
          points: 2
        },
        {
          id: 3,
          value: "przecież taka postawa jest niewłaściwa.",
          points: 0
        }
      ]
    },
    {
      id: 5,
      host: "Myślę, że ",
      hosts: "Myślimy, że ",
      answers: [
        {
          id: 1,
          value: "w ten sposób zarobione pieniądze są \"brudne\".",
          points: 2
        },
        {
          id: 2,
          value: "ja bym na twoim miejscu tak nie zrobił/a.",
          values: "my byśmy na twoim miejscu tak nie zrobili.",
          points: 0
        },
        {
          id: 3,
          value: "samopoczucie Kuby jest ważniejsze od pieniędzy.",
          points: 1
        }
      ]
    },    
    {
      id: 6,
      host: "A tak poza tym, to ",
      answers: [
        {
          id: 1,
          value: "z takim stosunkiem do innych nie będziesz miał w ogóle przyjaciół!",
          points: 2
        },
        {
          id: 2,
          value: "czemu się tak zachowujesz?",
          points: 0
        },
        {
          id: 3,
          value: "nie zapominaj, że sam możesz kiedyś się znaleźć w podobnej sytuacji!",
          points: 1
        }
      ]
    }
  ]

  constructor(
    private breakpointObserver: BreakpointObserver,
    private gameService: GameService,
    private accessibilityService: AccessibilityService
  ) {
    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.subContrast = this.accessibilityService.onContrast().subscribe(value => {
      this.isHighContrast = value;      
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subContrast.unsubscribe();
  }

  getCurrentDialogItem() {    
    return this.dialog.find(i => i.id === this.currentDialogItem);
  }

  getHost(item) {
    if (this.gameType === 'outdoor_team') {
      return (item.hosts !== undefined)? item.hosts : item.host;
    } else {
      return item.host;
    }
  }

  getAnswer(answer) {
    if (this.gameType === 'outdoor_team') {
      return (answer.values !== undefined)? answer.values : answer.value;
    } else {
      return answer.value;
    }
  }

  chooseAnswer(answer) {
    let item = this.getHost(this.getCurrentDialogItem()) + this.getAnswer(answer);
    this.answers.push(item);
    this.points = this.points + answer.points;
    if (this.currentDialogItem < 6) {
      this.currentDialogItem += 1;
    }
  }

  goNext() { 
    this.gameService.setGameAdditional(this._gameNumber, this.points);
    this.gameService.setGameCompleted(this._gameNumber);
    this.isCompleted = this.gameService.getGameCompleted(this._gameNumber);    
    this.points = this.gameService.getGameAdditional(this._gameNumber)
    window.scroll(0,0);
  }

  getPeterAnswer() {
    if (this.gameType === 'outdoor_team') {
      if (this.points <= 7) {
        return "Niech wam będzie... Nie we wszystkim się z wami zgadzam, ale już dam mu spokój, skoro tak się upieracie!"
      }
      if (this.points >= 8 && this.points < 11) {
        return "Po przemyśleniu sprawy uważam, że źle zrobiłem, macie rację. Jakoś to odkręcę..."
      }
      if (this.points >= 11) {
        return "Ojej! Bardzo przepraszam za przysporzenie tylu problemów. Obiecuję go przeprosić i nie robić tak w przyszłości!"
      }      
    } else {
      if (this.points <= 7) {
        return "Niech ci będzie... Nie we wszystkim się z tobą zgadzam, ale już dam mu spokój, skoro tak się upierasz!"
      }
      if (this.points >= 8 && this.points < 11) {
        return "Po przemyśleniu sprawy uważam, że źle zrobiłem, masz rację. Jakoś to odkręcę..."
      }
      if (this.points >= 11) {
        return "Ojej! Bardzo przepraszam za przysporzenie tylu problemów. Obiecuję go przeprosić i nie robić tak w przyszłości!"
      }       
    }
  }
}

<app-logo></app-logo>
<div class="content letter-content {{gameType}}" [ngClass]="isMobile ? 'mobile' : ''">
    <div>
        <div *ngIf="gameType !== 'outdoor_team'">            
            <p>
                Mój drogi/Moja droga,
            </p>
            <p>
                piszę do Ciebie, ponieważ chciałbym poprosić Cię o pomoc. W moim mieście dzieją się bardzo
                niepokojące rzeczy. Opowiem Ci jego historię, żebyś zrozumiał/a, skąd się wzięły nasze problemy i, na
                co mocno liczę, pomógł/pomogła je rozwiązać.
            </p>
            <p>
                Dawno temu nad rzeką założone zostało Wielkie Miasto. Było ono wyjątkowe, ponieważ opiekował
                się nim Gołąb. Mieszkańcy karmili go, dbali o jego gniazdo, a on w zamian za to zapewniał Miastu
                pokój i wolność. Miasto się rozwijało, rodziły się nowe pokolenia. Wszechobecny dobrobyt stał się tak
                oczywisty, że mieszkańcy zapomnieli, komu zawdzięczają szczęście. Zaczęli gonić za swoimi sprawami,
                gromadzić coraz większe bogactwa i przestali dbać o Gołębia. Jedynie najstarsi mieszkańcy jeszcze o
                nim pamiętają, ale jest ich coraz mniej i niekiedy brakuje im już sił, aby codziennie się nim zajmować.
            </p>
            <p>
                Jakiś czas temu zauważyłem, że mieszkańcy Miasta stają się coraz bardziej smutni i kłótliwi. Wczoraj
                udałem się do Gołębia, aby go nakarmić i sprawdzić, czy wszystko z nim w porządku. Niestety, nie
                było go w gnieździe! Pod jego drzewem leżała tylko kartka z mapą. Wtedy zrozumiałem, że Gołąb
                czuwający nad Miastem został uwięziony. Mieszkańcy są tak zajęci sobą, że nawet tego nie zauważyli.
                Ja natomiast jestem już stary i nikt mnie nie słucha. Nie mam też siły, aby ruszyć na poszukiwania
                Gołębia, dlatego potrzebuję Twojej pomocy. Przesyłam Ci mapę, którą znalazłem pod drzewem.
                Myślę, że pomoże w poszukiwaniach.
            </p>
            <p>
                <br />
                Najstarszy Obywatel Miasta
            </p>
        </div>
        <div *ngIf="gameType === 'outdoor_team'">    
            <p>
                Moi drodzy,
            </p>
            <p>
                piszę do Was, ponieważ chciałbym poprosić Was o pomoc. W moim mieście dzieją się bardzo
                niepokojące rzeczy. Opowiem Wam jego historię, żebyście zrozumieli, skąd się wzięły nasze problemy
                i, na co mocno liczę, pomogli je rozwiązać.
            </p>
            <p>
                Dawno temu nad rzeką założone zostało Wielkie Miasto. Było ono wyjątkowe, ponieważ opiekował
                się nim Gołąb. Mieszkańcy karmili go, dbali o jego gniazdo, a on w zamian za to zapewniał Miastu
                pokój i wolność. Miasto się rozwijało, rodziły się nowe pokolenia. Wszechobecny dobrobyt stał się tak
                oczywisty, że mieszkańcy zapomnieli, komu zawdzięczają szczęście. Zaczęli gonić za swoimi sprawami,
                gromadzić coraz większe bogactwa i przestali dbać o Gołębia. Jedynie najstarsi mieszkańcy jeszcze o
                nim pamiętają, ale jest ich coraz mniej i niekiedy brakuje im już sił, aby codziennie się nim zajmować.
            </p>
            <p>
                Jakiś czas temu zauważyłem, że mieszkańcy Miasta stają się coraz bardziej smutni i kłótliwi. Wczoraj
                udałem się do Gołębia, aby go nakarmić i sprawdzić, czy wszystko z nim w porządku. Niestety, nie
                było go w gnieździe! Pod jego drzewem leżała tylko kartka z mapą. Wtedy zrozumiałem, że Gołąb
                czuwający nad Miastem został uwięziony. Mieszkańcy są tak zajęci sobą, że nawet tego nie zauważyli.
                Ja natomiast jestem już stary i nikt mnie nie słucha. Nie mam też siły, aby ruszyć na poszukiwania
                Gołębia, dlatego potrzebuję Waszej pomocy. Przesyłam Wam mapę, którą znalazłem pod drzewem.
                Myślę, że pomoże w poszukiwaniach.
            </p>
            <p>
                <br />
                Najstarszy Obywatel Miasta
            </p>
        </div>  
        <button class="button-next"
            alt="przejście dalej"
            title="przejście dalej" 
            mat-raised-button 
            routerLink="/map"
            color="secondary">PRZEJDŹ DALEJ</button>
    </div>
</div>


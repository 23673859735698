import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EndingPageComponent } from './ending-page/ending-page.component';
import { FormPageComponent } from './form-page/form-page.component';
import { Game1PageComponent } from './game1-page/game1-page.component';
import { Game2PageComponent } from './game2-page/game2-page.component';
import { Game2021Component } from './game2021/game2021.component';
import { Game3PageComponent } from './game3-page/game3-page.component';
import { Game4PageComponent } from './game4-page/game4-page.component';
import { Game5PageComponent } from './game5-page/game5-page.component';
import { Game6PageComponent } from './game6-page/game6-page.component';
import { HomeComponent } from './home/home.component';
import { LetterComponent } from './home/letter.component';
import { LandingComponent } from './landing/landing.component';
import { MapPageComponent } from './map-page/map-page.component';
import { Game2017Component } from './pages/game2017/game2017.component';
import { Game2018Component } from './pages/game2018/game2018.component';
import { Game2019Component } from './pages/game2019/game2019.component';
import { Game2020Component } from './pages/game2020/game2020.component';
import { Game2022Component } from './pages/game2022/game2022.component';
import { Game2023Component } from './pages/game2023/game2023.component';
import { InfoComponent } from './pages/info/info.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { StypendiaComponent } from './pages/stypendia/stypendia.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path: 'deklaracja-dostepnosci',
    component: StypendiaComponent
  },
  {
    path: 'kontakt',
    component: KontaktComponent
  },
  {
    path: 'o-grze-o-wolnosc',
    component: InfoComponent
  },
  {
    path: 'gra-2023',
    component: Game2023Component
  },
  {
    path: 'gra-2022',
    component: Game2022Component
  },
  {
    path: 'gra-2020',
    component: Game2020Component
  },
  {
    path: 'gra-2019',
    component: Game2019Component
  },
  {
    path: 'gra-2018',
    component: Game2018Component
  },
  {
    path: 'gra-2017',
    component: Game2017Component
  },
  // { JEZELI BEDZIE ZGODA TO ROBIMY MODUŁOWO PER ROK GRY
  //   path: 'gra-2021',
  //   component: Game2021Component,
  //   loadChildren: () => import('./game2021/game2021.module').then(m => m.Game2021Module)
  // },
  // {
  //   path: 'home',
  //   component: HomeComponent
  // },
  {
    path: 'letter',
    component: LetterComponent
  },
  {
    path: 'map',
    component: MapPageComponent
  },
  {
    path: 'game-1',
    component: Game1PageComponent
  },
  {
    path: 'game-2',
    component: Game2PageComponent
  },
  {
    path: 'game-3',
    component: Game3PageComponent
  },
  {
    path: 'game-4',
    component: Game4PageComponent
  },
  {
    path: 'game-5',
    component: Game5PageComponent
  },
  {
    path: 'game-6',
    component: Game6PageComponent
  },
  {
    path: 'ending',
    component: EndingPageComponent
  },
  {
    path: 'form',
    component: FormPageComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

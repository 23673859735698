import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessibilityService } from '../../services/accessibility.service';
import { GameService } from '../../services/game.service';

@Component({
  selector: 'app-game1',
  templateUrl: './game1.component.html',
  styleUrls: ['./game1.component.scss']
})
export class Game1Component implements OnInit, OnDestroy {
  public isMobile: boolean = false;
  public isCompleted = this.gameService.getGameCompleted(1);  
  public gameType: string = this.gameService.getGameType();
  public help1: string = "";
  public help2: string = "";
  public help3: string = "";
  public help4: string = "";
  public help5: string = "";
  public help6: string = "";
  public help7: string = "";
  public help8: string = "";
  public help9: string = "";
  public help10: string = "";
  public showGoNext: boolean = false;
  public subContrast: Subscription;
  public isHighContrast = false;

  public password = ['','','','','','','','','','',];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private accessibilityService: AccessibilityService,
    private gameService: GameService
  ) {
    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.subContrast = this.accessibilityService.onContrast().subscribe(value => {
      this.isHighContrast = value;      
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subContrast.unsubscribe();
  }

  changeM() {
    let password:string = '';
    for (const letter of this.password) {    
      password += letter;
    };   

    this.showGoNext = (password.toLowerCase() === 'tolerancja');
  }

  clickHelp(num: number) {
    switch (num) {
      case 1: this.help1 = 'S'; break;
      case 2: this.help2 = 'I'; break;
      case 3: { 
        this.password[2] = 'L';
        this.help3 = 'L'; 
        break;
      }
      case 4: this.help4 = 'M'; break;
      case 5: this.help5 = 'S'; break;
      case 6: this.help6 = 'O'; break;
      case 7: this.help7 = 'S'; break;
      case 8: this.help8 = 'S'; break;
      case 9: {
        this.password[8] = 'J';
        this.help9 = 'J'; 
        break;
      }
      case 10: this.help10 = 'V'; break;
    }
  }

  goNext() {
    this.gameService.setGameCompleted(1);
    this.isCompleted = this.gameService.getGameCompleted(1);
    window.scroll(0,0);
  }

  checkIsCorrect(event: boolean) {      
    if (event === true) {
      this.gameService.setGameCompleted(1);
      this.isCompleted = this.gameService.getGameCompleted(1);
      window.scroll(0,0);
    }
  }
}

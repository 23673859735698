import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GameService {  
  private _games = [
    { id: 1, pre: false, started: false, completed: false, additional: null },
    { id: 2, pre: false, started: false, completed: false, additional: null },
    { id: 3, pre: false, started: false, completed: false, additional: null },
    { id: 4, pre: false, started: false, completed: false, additional: null },
    { id: 5, pre: false, started: false, completed: false, additional: null },
    { id: 6, pre: false, started: false, completed: false, additional: null }
  ];

  isShowedDialog: boolean = false;
  public gamesCompleted() {
    return (this.getGames().filter(i => i.completed === true).length >= 5);
  }
    
  constructor() { }
  
  public getGameType() {
    let gameType = this.read('game_type');
    return (gameType)? gameType : 'outdoor_single';
  }

  // outdoor_single, outdoor_team, online 
  public setGameType(type: string) {
    this.write('game_type', type);
  }

  public getDesctiptionHidden() {
    let gameType = this.read('description_hidden');
    return (gameType)? gameType : 'false';
  }

  public setDescriptionHidden() {
    this.write('description_hidden', 'true');
  }

  public read(key: string) {
    return localStorage.getItem(key);
  }

  public write(key: string, data: any) {
    localStorage.setItem(key, data);
  }

  private setGames(data: any) {    
    this.write('games', JSON.stringify(data))
  }

  private getGames() {
    let games = this.read('games');
    return (JSON.parse(games).length)? JSON.parse(games) : this._games;
  }

  private isOutdoorGame() {
    return ((this.getGameType() === 'outdoor_single') 
      || (this.getGameType() === 'outdoor_team'));
  }

  public showGamePreQuestion(id: number) {
    let game = this.getGame(id);
    return (this.isOutdoorGame() && !game.pre);
  }

  public setGamePreQuestion(id: number) {
    let game = this.getGame(id);
    game.pre = true;
    this.setGame(id, game);
  }
  
  public setGameCompleted(id: number) {
    let game = this.getGame(id);
    game.completed = true;
    this.setGame(id, game);
  }

  public getGameCompleted(id: number) {
    return this.getGame(id).completed;
  }

  public setGameAdditional(id: number, additional: any) {
    let game = this.getGame(id);
    game.additional = additional;
    this.setGame(id, game);
  }

  public getGameAdditional(id: number) {
    return this.getGame(id).additional;
  }

  public setGameStarted(id: number) {
    let game = this.getGame(id);
    game.started = true;
    this.setGame(id, game);
  }

  public getGameStarted(id: number) {
    return this.getGame(id).started;
  }

  private getGame(id: number) {
    let games = this.getGames();
    return games.find(i => i.id === id);
  }

  private setGame(id, updatedGame) {
    let games = this.getGames();
    const index = games.map(e => e.id).indexOf(id);    
    games[index] = updatedGame;
    this.setGames(games);
  }

  public clearGameData() {
    this.setGames([]);
    this.setGames(this._games);
  }
}

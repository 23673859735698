<div class="game-menu">
    <div class="left-side">
        <h1>Gra o Wolność</h1>
        <h2>edycja 2018</h2>
    </div>
    <div class="center-side">
        <p>
            Fabuła Gry została osadzona w realiach XX i XXI wieku. Gracze mogli przybliżyć sobie historię Polski
            widzianą oczami Jana Pawła II przez pryzmat czterech perspektyw, które zostały zdefiniowane
            w hasłach: jednostka, społeczeństwo, naród, państwo.
        </p>
    </div>
    <div class="right-side">
        <a mat-raised-button
            class="button"
            routerLink="/gra-2018"
            alt="przejście do szerszego opisu edycji"
            title="przejście do szerszego opisu edycji"
            color="primary">Dowiedz się więcej</a>
    </div>
</div>

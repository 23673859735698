import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessibilityService } from '../../services/accessibility.service';
import { GameService } from '../../services/game.service';

@Component({
  selector: 'app-game2',
  templateUrl: './game2.component.html',
  styleUrls: ['./game2.component.scss']
})
export class Game2Component implements OnInit, OnDestroy {
  public isMobile: boolean = false;
  public isCompleted = this.gameService.getGameCompleted(2);  
  public gameType: string = this.gameService.getGameType();
  public currentHost: number = 1;
  public subContrast: Subscription;
  public isHighContrast = false;

  public hosts = [
    {
      id: 1,
      host: "Ach, to Pan/i! Oczywiście! Byliśmy umówieni na spotkanie. Dlaczego więc miałbym ich przyjąć?",
      answers: [1, 2]
    },
    {
      id: 2,
      host: "Rozumiem Pana/Panią, ale niestety potrzebuję ludzi znających się na rzeczy. Nie jestem pomocą społeczną. Nie mogę pozwolić, aby moja firma upadła przez dwie niedoświadczone osoby.",
      answers: [3, 4]
    },
    {
      id: 3,
      host: "Zaciekawił/a mnie Pan/i. Czy mógłby/mogłaby Pan/i przedstawić zmiany, jakie Daniela zamierza wprowadzić, ponieważ nie do końca rozumiem, o co chodzi?",
      answers: [5, 6]
    },
    {
      id: 4,
      host: "Wydaje się to intersujące, ale nie jestem pewien, czy to się przyjmie. A co Pan/i powie na temat Samuela?",
      answers: [7, 8]
    },
    {
      id: 5,
      host: "Rozumiem, ale to zbyt mało. Jako dostawca powinien posługiwać się lepiej językiem polskim.",
      answers: [9, 10]
    },
    {
      id: 6,
      host: "Może to prawda. Co więc mam w nim takiego docenić?",
      answers: [8, 11]
    },
    {
      id: 7,
      host: "Rzeczywiście jego wygląd wzbudza zaufanie, ale dostawca musi nie tylko dobrze wyglądać, ale także nienagannie pracować.",
      answers: [11, 12]
    },
    {
      id: 8,
      host: "W takim razie źle się Panu/i wydaje. Ja tu ustalam warunki.",
      answers: [13, 14]
    },
    {
      id: 9,
      host: "Myśli Pan/i, że nie znam Kodeksu pracy? Oczywiście prawo zakazuje dyskryminacji, ale nikt nie powiedział, że musi to być kryterium do zatrudnienia. Nie życzę sobie takich zarzutów! Do widzenia!",
      answers: []
    },
    {
      id: 10,
      host: "Wątpię, by znali się na fachu, gdyż w ich CV nie znalazłem nic godnego zainteresowania.",
      answers: [3, 4]
    },
    {
      id: 11,
      host: "Niestety obecnie moja restauracja przechodzi trudny moment w związku z sytuacją pandemiczną w kraju. Przez to nie mogę przyjmować niepewnych pracowników.",
      answers: [15, 16]
    },
    {
      id: 12,
      host: "Przed chwilą zapewniał/a Pan/i o jej wyjątkowości. Nie widzę natomiast nic wyjątkowego w podstawowych potrawach... A co Pan/i powie na temat Samuela?",
      answers: [8, 7]
    },
    {
      id: 13,
      host: "Sam dobrze ocenię, jak się nim posługuje i sam zdecyduję, czy są to wystarczające umiejętności.",
      answers: [13, 14]
    },
    {
      id: 14,
      host: "Pracował w kopalni? Dlaczego więc opuścił tę pracę?",
      answers: [17, 18]
    },    
    {
      id: 15,
      host: "Przykro mi, ale nie mogę polegać tylko na zapewnieniach i opiniach. A co Pan/i powie na temat Samuela?",
      answers: [7, 8]
    },    
    {
      id: 16,
      host: "Bardzo mi przykro z tego powodu, ale sam jestem w trudnej sytuacji. A co Pan/i powie na temat Samuela?",
      answers: [7, 8]
    },   
    {
      id: 17,
      host: "Dobrze przyjmę ich, ale muszą wiedzieć, że na stałe zatrudnienie muszą uczciwie zapracować. Będę ich miał stale pod nadzorem i niech nie liczą na pobłażliwość. Proszę im przekazać, żeby stawili się dokładnie o 7:00 w poniedziałek. Do widzenia.",
      answers: []
    },   
    {
      id: 18,
      host: "Jeżeli ma doświadczenie w przemyśle, to niech nie szuka pracy w usługach.",
      answers: [19, 13]
    }
  ];

  public answers = [
    {
      id: 1,
      user: "Ta rodzina jest w bardzo trudnym położeniu: są uchodźcami, wychowują trójkę małych dzieci. Potrzebna jest im pomoc finansowa.",
      host: 2
    },
    {
      id: 2,
      user: "To są ludzie, którzy w uczciwy sposób chcą zarobić na życie. Znają się na fachu, więc na pewno nie zawiedzie się Pan na nich.",
      host: 10
    },
    {
      id: 3,
      user: "Mogę zagwarantować, że Daniela posiada niesamowite zdolności kulinarne, wiele osób zachwala jej potrawy. Gdy wraz z rodziną mieszkała na Ukrainie, pracowała w restauracji. Jestem pewien/na, że wniosłaby wiele nowości do Pana menu.",
      host: 3
    },
    {
      id: 4,
      user: "Daniela posiada ukryty talent kulinarny. Sam/a byłem/am miło zaskoczony/a, gdy miałem/am okazję spróbować przygotowanego przez nią dania. U Pana w restauracji mogłaby się rozwinąć.",
      host: 11
    },
    {
      id: 5,
      user: "Daniela znakomicie się zna na kuchni ukraińskiej. Mogłaby zaproponować kilka tamtejszych potraw. Zapewniam, że się Pan nie zawiedzie.",
      host: 4
    },
    {
      id: 6,
      user: " Daniela odkryła swój kulinarny talent właśnie w Polsce, dlatego doskonale zna wszystkie podstawowe potrawy. Swoimi zdolnościami nadałaby im nowy smak.",
      host: 12
    },
    {
      id: 7,
      user: "Jest to porządny człowiek, godny zaufania. Mógłby się nadać na dostawcę. Zna dobrze nazwy i lokalizacje ulic w Warszawie.",
      host: 5
    },
    {
      id: 8,
      user: "Jest to człowiek uprzejmy z dobrą prezencją, dlatego mógłby doskonale reklamować Pańską restaurację.",
      host: 7
    },
    {
      id: 9,
      user: " To prawda, nie mówi po polsku najlepiej, ale proszę spojrzeć na jego inne zalety, na przykład szybko się uczy i dostosowuje do zmian.",
      host: 6
    },
    {
      id: 10,
      user: "Jest w Polsce już od roku, więc potrafi się wystarczająco dobrze posługiwać językiem.",
      host: 13
    },
    {
      id: 11,
      user: "Gwarantuję o uczciwości i pracowitości Samuela. Zanim tu przyjechał, pracował w kopalni węgla na Ukrainie.",
      host: 14
    },
    {
      id: 12,
      user: "Nie wydaje mi się, by praca dostawcy wymagała jakiegoś większego wysiłku.",
      host: 8
    },
    {
      id: 13,
      user: "Nie może być Pan taki bezwzględny wobec nich, to też są ludzie. Zresztą prawo zakazuję takiej dyskryminacji wobec pracowników.",
      host: 9
    },
    {
      id: 14,
      user: "Ma Pan rację. Powinien Pan jednak zauważyć, że jest on inteligentny i silny. Zanim tu przyjechał pracował w kopalni węgla na Ukrainie.",
      host: 14
    },
    {
      id: 15,
      user: "Zapewniam, że Pan się nie zawiedzie. Daniela i Samuel naprawdę są godni zaufania.",
      host: 15
    },
    {
      id: 16,
      user: "Niech Pan pomyśli o ich sytuacji. W przyszłym tygodniu kończy się im zasiłek.",
      host: 16
    },
    {
      id: 17,
      user: "To była bardzo ciężka praca, odbywała się w trudnych i niebezpiecznych warunkach. Był wówczas jedynym żywicielem rodziny. I jeszcze niska pensja. Błagam Pana - niech Pan ich przyjmie.",
      host: 17
    },
    {
      id: 18,
      user: "Kopalnię zamknięto. Musiał szukać nowej pracy.",
      host: 18
    },
    {
      id: 19,
      user: "Naszą przyszłością jest praca w usługach nie w przemyśle. Zresztą nie uważa Pan, że należy dać szansę tak ambitnym ludziom? Gwarantuje Panu, że tak dobrych pracowników nie spotyka się na ulicy.",
      host: 17
    }
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private gameService: GameService,
    private accessibilityService: AccessibilityService
  ) {
    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.subContrast = this.accessibilityService.onContrast().subscribe(value => {
      this.isHighContrast = value;      
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subContrast.unsubscribe();
  }

  getQuestion() {
    return this.hosts.find(i => i.id === this.currentHost);
  }

  getAnswers() {
    let question = this.getQuestion();
    let answers = [];
    if (question.answers) {
      question.answers.forEach((answerId) => {
        answers.push(this.answers.find(i => i.id === answerId));
      });
    }
    return answers;
  }

  chooseAnswer(hostId: number) {
    this.currentHost = hostId;
  }

  goNext() {
      this.gameService.setGameCompleted(2);
      this.isCompleted = this.gameService.getGameCompleted(2);
      window.scroll(0,0);
  }
}

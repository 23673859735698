<div class="accessibility-buttons">
    <button class="letter"
        title="Zwiększ/Zmniejsz Czcionkę"
        alt="Zwiększ/Zmniejsz Czcionkę"
        [ngClass]="letterActive? 'active' : ''"
        (click)="changeLetter()">
    </button>
    <button class="contrast"
        title="Zwiększ/Zmniejsz Kontrast"
        alt="Zwiększ/Zmniejsz Kontrast"
        [ngClass]="contrastActive? 'active' : ''"
        (click)="changeContrast()">
    </button>
</div>
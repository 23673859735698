import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessibilityService } from 'src/app/shared/services/accessibility.service';

@Component({
  selector: 'app-stypendia',
  templateUrl: './stypendia.component.html',
  styleUrls: ['./stypendia.component.scss']
})
export class StypendiaComponent implements OnInit {
  public subContrast: Subscription;
  public isHighContrast = false;

  constructor(private accessibilityService: AccessibilityService) {
    this.subContrast = this.accessibilityService.onContrast().subscribe(value => {
      this.isHighContrast = value;      
    });
  }

  ngOnDestroy() {
    this.subContrast.unsubscribe();
  }
  ngOnInit(): void {
  }

}

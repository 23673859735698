<div class="content-page-header" [ngClass]="isMobile? 'mobile' : ''">
    <app-landing-header [isHighContrast]=isHighContrast></app-landing-header>

    <div class="landing-slider">
        <!-- <carousel cellWidth="100%" cellsToShow="1" adaptiveHeight="true" dots="true"> -->
        <carousel cellWidth="100%" cellsToShow="1" [height]="sliderHeight" adaptiveHeight="true" dots="true">
            <div class="carousel-cell" alt="Napis: Gra o Wolność edycja 2023. Grafika: Motyw graficzny tegorocznej Nocy Muzeów">
              <div class="slider-item slider-item-2023" [ngStyle]="{'height.px': sliderHeight}">
                  <div class="slider-item-info">
                      <h1>Gra o Wolność</h1>
                      <h2>edycja 2023</h2>
                  </div>
              </div>
            </div>
            <div class="carousel-cell" alt="Napis: Gra o Wolność edycja 2022. Grafika: Ręce trzymające logo Gra o Wolność">
              <div class="slider-item slider-item-2022" [ngStyle]="{'height.px': sliderHeight}">
                  <!-- <img src="../../assets/page-landing/slider_gra2022.png" /> -->
                  <div class="slider-item-info">
                      <h1>Gra o Wolność</h1>
                      <h2>edycja 2022</h2>
                  </div>
              </div>
            </div>
            <div class="carousel-cell" alt="Napis: Gra o Wolność edycja 2021. Grafika: Chłopiec trzyma w ręku telefon.">
                <div class="slider-item slider-item-2021" [ngStyle]="{'height.px': sliderHeight}">
                    <!-- <img src="../../assets/page-landing/slider_gra2021.png" /> -->
                    <div class="slider-item-info">
                        <h1>Gra o Wolność</h1>
                        <h2>edycja 2021</h2>
                    </div>
                </div>
            </div>
            <div class="carousel-cell" alt="Napis: Gra o Wolność edycja 2020. Grafika: Fragment mapy Polski z nazwami miejsc: Plac Zwycięstwa, Belweder, Okęcie, Gniezno. Strzałki prowadzą od jednego miejsca do kolejnego.">
                <div class="slider-item slider-item-2020" [ngStyle]="{'height.px': sliderHeight}">
                    <!-- <img src="../../assets/page-landing/slider_gra2019.png" /> -->
                    <div class="slider-item-info">
                        <h1>Gra o Wolność</h1>
                        <h2>edycja 2020</h2>
                    </div>
                </div>
            </div>
            <div class="carousel-cell" alt="Napis: Gra o Wolność edycja 2019. Grafika: Fragment graficznej plansz, na której znajduje się Jan Paweł Drugi, który wchodzi do samolotu, machając ręką. W tle widać park.">
                <div class="slider-item slider-item-2019" [ngStyle]="{'height.px': sliderHeight}">
                    <!-- <img src="../../assets/page-landing/slider_gra2019.png" /> -->
                    <div class="slider-item-info">
                        <h1>Gra o Wolność</h1>
                        <h2>edycja 2019</h2>
                    </div>
                </div>
            </div>
            <div class="carousel-cell" alt="Napis: Gra o Wolność edycja 2018. Grafika: Troje młodych ludzi w strojach z lat trzydziestych dwudziestego wieku siedzi pochylona. Znajdują się w piwnicy.">
                <div class="slider-item slider-item-2018" [ngStyle]="{'height.px': sliderHeight}">
                    <!-- <img src="../../assets/page-landing/slider_gra2018.png" /> -->
                    <div class="slider-item-info">
                        <h1>Gra o Wolność</h1>
                        <h2>edycja 2018</h2>
                    </div>
                </div>
            </div>
            <div class="carousel-cell" alt="Napis: Gra o Wolność edycja 2017. Grafika: Trzyosobowa rodzina stoi i pochyla się nad stołem z biało-czerwoną flagą w ręku.">
                <div class="slider-item slider-item-2017" [ngStyle]="{'height.px': sliderHeight}">
                    <!-- <img src="../../assets/page-landing/slider_gra2018.png" /> -->
                    <div class="slider-item-info">
                        <h1>Gra o Wolność</h1>
                        <h2>edycja 2017</h2>
                    </div>
                </div>
            </div>
        </carousel>
    </div>
    <div class="landing landing-2021">
        <div class="div-left">
            <img *ngIf="!isHighContrast" src="../../assets/images/logo2023.png" title="logo Gra o Wolność" alt="logo Gra o Wolność"/>
            <img *ngIf="isHighContrast" src="../../assets/images/logo2023.png" title="logo Gra o Wolność" alt="logo Gra o Wolność"/>
        </div>
        <div class="div-center">
            <h1>Gra o Wolność</h1>
            <h2>edycja 2023</h2>
            <p>
              W ramach escape roomu pod niepozorną nazwą „Sąsiedzka herbatka” zaprosiliśmy uczestników do pasjonującej podróży
              w poszukiwaniu prawdy o innych i o sobie, do odkrywania potrzeb i skrywanych emocji.
            </p>
        </div>
        <div class="div-right">
            <button mat-raised-button
                alt="przejście do szerszego opisu edycji"
                title="przejście do szerszego opisu edycji"
                color="primary"
                routerLink="/gra-2023">Dowiedz się więcej</button>

            <!-- <button mat-raised-button
                alt="przejście do gry miejskiej zespołowej"
                title="przejście do gry miejskiej zespołowej"
                color="primary"
                (click)="setGameType('outdoor_team')">Gra miejska zespołowa</button>

            <button mat-raised-button
                alt="przejście do gry online"
                title="przejście do gry online"
                color="primary"
                (click)="setGameType('online')">Gra online</button> -->

        </div>
    </div>
</div>
<div class="content-page" [ngClass]="isMobile? 'mobile' : ''">

    <app-landing2022></app-landing2022>
    <hr class="hr-red" />
    <app-landing2021></app-landing2021>
    <hr class="hr-orange" />
    <app-landing2020></app-landing2020>
    <hr class="hr-blue" />
    <app-landing2019></app-landing2019>
    <hr class="hr-red" />
    <app-landing2018></app-landing2018>
    <hr class="hr-orange" />
    <app-landing2017></app-landing2017>
    <hr class="hr-blue" />
</div>

<app-landing-footer [isHighContrast]=isHighContrast></app-landing-footer>

<app-dialog *ngIf="showDialogCookies" (isClose)="closeDialogCookies($event)">
    <p>
        Ta strona wykorzystuje pliki cookies. Pozostawiając w ustawieniach przeglądarki włączoną obsługę
        plików cookies wyrażasz zgodę na ich użycie. Jeśli nie zgadzasz się na wykorzystanie plików cookies,
        nie możesz uczestniczyć w grze. Więcej znajdziesz w
        <a alt="link do polityki prywatności" title="link do polityki prywatności" href="https://www.centrumjp2.pl/graowolnosc2021-polityka-cookies/." target="_blank">Polityce Cookies</a>.
    </p>
</app-dialog>

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingHeaderComponent implements OnInit {
  @Input() isHighContrast: boolean;
  @Input() showLogo: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="content-page-header">
    <app-landing-header [isHighContrast]=isHighContrast></app-landing-header>
    <div class="title title-info">
        <h1></h1>
    </div>
</div>

<div class="content-page">
    <h2>Deklaracja dostępności</h2>
    <p>
        Centrum Myśli Jana Pawła II zobowiązuje się zapewnić dostępność swojej strony internetowej
        zgodnie z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych
        i aplikacji mobilnych podmiotów publicznych. Oświadczenie w sprawie dostępności ma zastosowanie
        do niniejszej strony internetowej.<br/>
        Data publikacji strony internetowej: 2021-05-18<br/>
        Data ostatniej istotnej aktualizacji: 2023-06-28
    </p>
    <p>
        Strona internetowa jest częściowo zgodna z ustawą z dnia 4 kwietnia 2019 r.
        o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych
        z powodu niezgodności lub wyłączeń wymienionych poniżej.
    </p>
    <p><strong>Treści niedostępne:</strong><p>
    <ol>
        <li>Slider, w którym prezentowane są zdjęcia na stronie głównej nie jest dostępny.</li>
        <li>Wersja miejska "Gry o Wolność - edycja 2021" jest niedostępna.</li>
    </ol>
    <p><strong>Wyłączenia:</strong></p>
    <p>
        Oświadczenie sporządzono dnia: 2023-07-04<br />
        Deklarację sporządzono na podstawie samooceny przeprowadzonej przez podmiot publiczny.
    </p>
    <p>
        <strong>Informacje zwrotne i dane kontaktowe</strong><br/>
        W przypadku problemów z dostępnością strony internetowej prosimy o kontakt z działem
        stypendialno-edukacyjnym, stypendia@centrumjp2.pl.<br />
        Kontaktować można się także dzwoniąc na numer telefonu +48 22 277 53 00.<br />
        Tą samą drogą można składać wnioski o udostępnienie informacji niedostępnej oraz składać skargi na brak
        zapewnienia dostępności. “Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony
        internetowej, aplikacji mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji
        za pomocą alternatywnego sposobu dostępu, na przykład przez odczy- tanie niedostępnego cyfrowo dokumentu,
        opisanie zawartości filmu bez audiodeskrypcji itp. Żądanie powinno zawierać dane osoby zgłaszającej żądanie,
        wskazanie, o którą stronę internetową lub aplikację mobilną chodzi oraz sposób kontaktu. Jeżeli osoba żądająca
        zgłasza potrzebę otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna także określić dogodny
        dla niej sposób przedstawienia tej informacji. Podmiot publiczny powinien zrealizować żądanie niezwłocznie, nie
        później niż w ciągu 7 dni od dnia wystąpienia z żądaniem. Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot
        publiczny niezwłocznie informuje o tym wnoszącego żądanie, kiedy realizacja żądania będzie możliwa, przy czym termin
        ten nie może być dłuższy niż 2 miesiące od dnia wy- stąpienia z żądaniem. Jeżeli zapewnienie dostępności cyfrowej nie
        jest możliwe, podmiot publiczny może zaproponować alternatyw- ny sposób dostępu do informacji. W przypadku,
        gdy podmiot publiczny odmówi realizacji żądania zapewnienia dostępności lub alter- natywnego sposobu dostępu do
        informacji, wnoszący żądanie możne złożyć skargę w sprawie zapewniana dostępności cyfrowej strony internetowej,
        aplikacji mobilnej lub elementu strony internetowej, lub aplikacji mobilnej.]
        Po wyczerpaniu wskazanej wyżej procedury można także złożyć wniosek do Rzecznika Praw Obywatelskich.”
    </p>
    <p>
        <strong>Dostępność architektoniczna</strong><br />
        Opis dostępności architektonicznej budynku, w którym mieści się siedziba Centrum Myśli Jana Pawła II<br />
        Budynek będący pod zarządem ZGN mieści się przy ulicy Foksal 11. Centrum Myśli Jana Pawła II wynajmuje lokale na I i III
        piętrze budynku.<br />
        Budynek nie posiada podjazdu dla wózków inwalidzkich, jednak aby pokonać wysoki próg wejścia do klatki chodowej należy
        zadzwonić pod numer telefonu 533318505, aby pracownicy Centrum Myśli Jana Pawła II pomogli wjechać osobie niepełnosprawnej
        do windy. Różnice poziomów można pokonać za pomocą specjalnie w tym celu rozkładanych szyn podjazdowych dla wózków inwalidzkich.<br />
        Wyznaczone miejsca parkingowe dla osób z niepełnosprawnościami znajdują się naprzeciwko budynku przy ul. Foksal 11.<br />
        Po wejściu do lokalu zarówno na I jak i na III piętrze wózek inwalidzki może swobodnie poruszać się po korytarzach.
        Na obu piętrach zarówno na I jak i na III piętrze znajduje się toaleta dostosowana dla osób niepełnosprawnych.
        Winda osobowa ma przyciski oznaczone alfabetem Braille’a.<br />
        Dojazd transportem publicznym od ul. Nowy Świat (przystanek Foksal). Trzeba dojść kilkaset metrów do samego budynku.
    </p>
</div>

<app-landing-footer [isHighContrast]=isHighContrast></app-landing-footer>

<div class="content-container">  
    <app-logo></app-logo>  
    <div class="content content-ending">    
        <div>
            <h1 title="Formularz danych">
                Formularz danych
            </h1>
            <form (ngSubmit)="onSubmit()" #heroForm="ngForm">          
                <p *ngIf="gameType === 'outdoor_team'">
                    <strong>DANE KAPITANA ZESPOŁU</strong>
                </p>
                <div class="form">                        
                    <input type="text" id="player0_firstName"
                        required
                        placeholder="imię"
                        [(ngModel)]="player0.firstName" name="player0_firstName">
                    <input type="text" id="player0_lastName"
                        required
                        placeholder="nazwisko"
                        [(ngModel)]="player0.lastName" name="player0_lastName">
                    <input type="email" id="player0_email"
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                        placeholder="e-mail"
                        [(ngModel)]="player0.email" name="player0_email">
                    <input type="text" id="player0_phone"
                        required
                        placeholder="telefon"
                        [(ngModel)]="player0.phone" name="player0_phone">                        
                </div>

                <div *ngIf="gameType === 'outdoor_team'" class="form form-team-name">  
                    <input type="text" id="teamName"
                        required
                        placeholder="nazwa zespołu"
                        [(ngModel)]="teamName" name="teamName">
                </div>   

                <div class="form-checkbox">
                    <label>
                        <input type="checkbox" id="agreements1"
                            required
                            [(ngModel)]="agreements.zgoda1" name="agreements1" />
                        (wymagane) 
                        Akceptuję postanowienia Regulaminu organizacyjnego gry mobilnej pt. "Gra o Wolność
                        2021" oraz Politykę cookies serwisu internetowego „Gra o wolność 2021” dostępną pod
                        adresem: <a alt="link do polityki prywatności" title="link do polityki prywatności" href="https://www.centrumjp2.pl/graowolnosc2021-polityka-cookies/" 
                        target="_blank">https://www.centrumjp2.pl/graowolnosc2021-polityka-cookies/</a>.
                    </label>                    
                    <ng-container *ngIf="gameType !== 'outdoor_team'">
                        <label>
                            <input type="checkbox" id="agreements2"
                                required
                                [(ngModel)]="agreements.zgoda2" name="agreements2"/>
                            (wymagane) 
                            Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w formularzu w
                            celach kontaktowych, przygotowania i przekazania dyplomu uczestnictwa, opublikowania na
                            liście zwycięzców oraz przekazania nagrody w „Grze o Wolność 2021” (dalej: „Gra”),
                            organizowanej przez Centrum Myśli Jana Pawła II z siedzibą w Warszawie (00-372), ul. Foksal
                            11. Jestem świadom/świadoma, iż moja zgoda może być odwołana w każdym czasie przez
                            wysłanie wiadomości o treści „REZYGNACJA” na adres e-mail: stypendia@centrumjp2.pl, co
                            skutkować będzie usunięciem moich danych z zasobów Centrum Myśli Jana Pawła II.
                            Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na
                            podstawie zgody przed jej wycofaniem. Dane podaję dobrowolnie, niemniej oświadczam, iż
                            wiem, że bez ich podania nie jest możliwe otrzymanie dyplomu oraz nagrody za udział w
                            Grze.
                        </label>
                    </ng-container>
                    <ng-container *ngIf="gameType === 'outdoor_team'">
                        <label>
                            <input type="checkbox" id="agreements2"
                                required
                                [(ngModel)]="agreements.zgoda2" name="agreements2"/>
                            (wymagane) 
                            Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w formularzu w celu
                            przygotowania i przekazania dyplomu uczestnictwa, opublikowania na liście zwycięzców oraz
                            przekazania nagrody w „Grze o Wolność 2021” (dalej: „Gra”), organizowanej przez Centrum
                            Myśli Jana Pawła II z siedzibą w Warszawie (00-372), ul. Foksal 11. Jestem
                            świadom/świadoma, iż moja zgoda może być odwołana w każdym czasie przez wysłanie
                            wiadomości o treści „REZYGNACJA” na adres e-mail: stypendia@centrumjp2.pl, co skutkować

                            będzie usunięciem moich danych z zasobów Centrum Myśli Jana Pawła II. Wycofanie zgody
                            nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody
                            przed jej wycofaniem. Dane podaję dobrowolnie, niemniej oświadczam, iż wiem, że bez ich
                            podania nie jest możliwe otrzymanie dyplomu oraz nagrody za udział w Grze.
                        </label>    
                        <label>
                            <input type="checkbox" id="agreements3"
                                required
                                [(ngModel)]="agreements.zgoda3" name="agreements3" />
                            (wymagane) 
                            Oświadczam, że dysponuję zgodą na udostępnienie Organizatorowi mobilnej gry miejskiej
                            „Gra o Wolność 2021” i przetwarzanie przez niego danych członków Zespołu, w tym
                            niepełnoletnich członków Zespołu, na zasadach opisanych w Regulaminie organizacyjnym
                            mobilnej gry miejskiej pt. "Gra o Wolność 2021" 
                        </label>                                            
                    </ng-container>                                       
                </div>

                <ng-container *ngIf="gameType === 'outdoor_team'">
                    <div class="form-team">
                        <p><strong>DANE CZŁONKÓW ZESPOŁU</strong></p>
                        <div class="form">                        
                            <input type="text" id="player1_firstName"
                                placeholder="imię"
                                [(ngModel)]="player1.firstName" name="player1_firstName">
                            <input type="text" id="player1_lastName"
                                placeholder="nazwisko"
                                [(ngModel)]="player1.lastName" name="player1_lastName">
                        </div>
                        <div class="form">                        
                            <input type="text" id="player2firstName"
                                placeholder="imię"
                                [(ngModel)]="player2.firstName" name="player2_firstName">
                            <input type="text" id="player2_lastName"
                                placeholder="nazwisko"
                                [(ngModel)]="player2.lastName" name="player2_lastName">
                        </div>
                        <div class="form">                        
                            <input type="text" id="player3_firstName"
                                placeholder="imię"
                                [(ngModel)]="player3.firstName" name="player3_firstName">
                            <input type="text" id="player3_lastName"
                                placeholder="nazwisko"
                                [(ngModel)]="player3.lastName" name="player3_lastName">
                        </div>
                        <div class="form">                        
                            <input type="text" id="player4_firstName"
                                placeholder="imię"
                                [(ngModel)]="player4.firstName" name="player4_firstName">
                            <input type="text" id="player4_lastName"
                                placeholder="nazwisko"
                                [(ngModel)]="player4.lastName" name="player4_lastName">
                        </div>
                        <div class="form">                        
                            <input type="text" id="player5_firstName"
                                placeholder="imię"
                                [(ngModel)]="player5.firstName" name="player5_firstName">
                            <input type="text" id="player5_lastName"
                                placeholder="nazwisko"
                                [(ngModel)]="player5.lastName" name="player5_lastName">
                        </div>
                    </div>
                </ng-container>  
                
                <div class="actions">
                    <button 
                        alt="wysłanie danych i pobranie dyplomu"
                        title="wysłanie danych i pobranie dyplomu"
                        type="submit"
                        [disabled]="!heroForm.form.valid"
                        mat-raised-button 
                        color="primary">WYŚLIJ DANE I POBIERZ DYPLOM</button>
                </div> 
            </form>
        </div>
    </div>
</div>
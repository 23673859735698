<div class="content-container">
    <!-- <div class="content-pre-head" *ngIf="showPre"></div> -->
    <app-logo></app-logo>
    <div class="content content-pre" *ngIf="showPre">    
        <div>
            <h1 title="Plac Grzybowski – zadanie lokalizacyjne">Plac Grzybowski</h1>
            <ng-container *ngIf="gameType !== 'outdoor_team'">
                <p>
                    Dochodzisz do uroczego skwerku. Nagle podbiega do Ciebie małe dziecko i z uśmiechem na ustach pyta:
                </p>
                <p>
                    <strong>– Wiesz, ile mostków znajduje się nad wodą?</strong>
                </p>
            </ng-container>
            <ng-container *ngIf="gameType === 'outdoor_team'">
                <p>
                    Dochodzicie do uroczego skwerku. Nagle podbiega do Was małe dziecko i z uśmiechem na ustach pyta:
                </p>
                <p>
                    <strong>– Wiecie, ile mostków znajduje się nad wodą?</strong>
                </p>
            </ng-container>

            <app-check-answer answer='2/dwa'
                (isCorrect)="checkIsCorrect($event)"></app-check-answer>
        </div>
    </div>
    
    <div class="content"*ngIf="!showPre && !started">
        <div>
            <h1 title="Plac Grzybowski – wprowadzenie">Plac Grzybowski</h1>
            <div class="column center" *ngIf="isMobile">
                <img src="../../assets/images/game2_black.png" />
            </div>   
            <div class="column">
                <ng-container *ngIf="gameType !== 'outdoor_team'">
                    <p>
                        Ostatnio poznałeś/aś historię Danieli i Samuela, pamiętasz? Są małżeństwem i mają trójkę dzieci. Pochodzą z Ukrainy. 
                        Przez kilka lat mieszkali w ośrodku dla uchodźców, gdzie całą 5-osobową rodziną musieli się zmieścić w małym pokoju. 
                        Dzieci bardzo to przeżyły. Po opuszczeniu ośrodka rodzina przeniosła się do Wielkiego Miasta. Obecnie Samuel i Daniela 
                        dostali status uchodźców oraz małe mieszkanie komunalne, przyznano im niewielki zasiłek. Niestety, mimo udzielonej pomocy, 
                        rodzice mają problem ze znalezieniem jakiejkolwiek pracy poza dorywczymi zajęciami, dzięki którym stać ich na utrzymanie 
                        całej rodziny. Prace, których się chwytali, byleby móc cokolwiek zarobić, były przeróżne – od gotowania poprzez sprzątanie,
                         aż po chwilową pracę w kopalni, której podjął się Samuel. Praca w kopalni przyniosła mu oraz jego rodzinie chwilową 
                         stabilizację, jednak okazała się zbyt forsująca zdrowie Samuela, które już wcześniej było znacząco podniszczone
                          życiem w ośrodku. Obecnie para stara się o nową pracę. Udało im się znaleźć obiecującą ofertę pracy w restauracji,
                           jednak rozmowa z potencjalnym pracodawcą nie poszła zbyt dobrze.
                    </p>
                    <p>
                        Poruszyła Cię ich historia, dlatego postanowiłeś/aś im pomóc. Umówiłeś/aś się na spotkanie z właścicielem restauracji.    
                    </p>
                </ng-container>

                <ng-container *ngIf="gameType === 'outdoor_team'">
                    <p>
                        Ostatnio poznaliście historię Danieli i Samuela, pamiętacie? Są małżeństwem i mają trójkę dzieci.
                        Pochodzą z Ukrainy. Przez kilka lat mieszkali w ośrodku dla uchodźców, gdzie całą 5-osobową rodziną
                        musieli się zmieścić w małym pokoju. Dzieci bardzo to przeżyły. Po opuszczeniu ośrodka rodzina
                        przeniosła się do Wielkiego Miasta. Obecnie Samuel i Daniela dostali status uchodźców oraz małe
                        mieszkanie komunalne, przyznano im niewielki zasiłek, który miał im pomóc rozpocząć życie w
                        nowym kraju. Niestety, mimo udzielonej pomocy, rodzice mają problem ze znalezieniem
                        jakiejkolwiek pracy poza dorywczymi zajęciami, dzięki którym stać ich na utrzymanie całej rodziny.
                        Prace, których się chwytali, byleby móc cokolwiek zarobić, były przeróżne – od gotowania poprzez
                        sprzątanie, aż po chwilową pracę w kopalni, której podjął się Samuel. Praca w kopalni przyniosła mu
                        oraz jego rodzinie chwilową stabilizację, jednak okazała się zbyt forsująca zdrowie Samuela, które już
                        wcześniej było znacząco podniszczone życiem w ośrodku. Obecnie para stara się o nową pracę. Udało
                        im się znaleźć obiecującą ofertę pracy w restauracji, jednak rozmowa z potencjalnym pracodawcą nie
                        poszła zbyt dobrze.
                    </p>
                    <p>
                        Poruszyła Was ich historia, dlatego postanowiliście im pomóc. Umówiliście się na spotkanie z
                        właścicielem restauracji.
                    </p>
                </ng-container>
            </div>    
            <div class="column center" *ngIf="!isMobile">
                <img src="../../assets/images/game2_black.png" 
                    alt="grafika przedstawiająca czarno-białe zdjęcie małżeństwa"
                    title="grafika przedstawiająca czarno-białe zdjęcie małżeństwa" />
            </div>            
            <div class="actions">
                <button (click)="startGame()" 
                    alt="przejście dalej"
                    title="przejście dalej"
                    mat-raised-button 
                    color="primary">PRZEJDŹ DALEJ</button>
            </div>
        </div>
    </div>
    
    <div class="content" *ngIf="!showPre && started">
        <app-game2></app-game2>
    </div>
</div>
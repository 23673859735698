import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { GameService } from '../shared/services/game.service';

@Component({
  selector: 'app-game3-page',
  templateUrl: './game3-page.component.html',
  styleUrls: ['./game3-page.component.scss']
})
export class Game3PageComponent implements OnInit {
  private _gameNumber = 3;
  public gameType: string = this.gameService.getGameType();
  public showPre: boolean = this.gameService.showGamePreQuestion(this._gameNumber);
  public started: boolean = this.gameService.getGameStarted(this._gameNumber);
  public isMobile: boolean = false;

  constructor(
    private gameService: GameService,
    private renderer: Renderer2,
    public breakpointObserver: BreakpointObserver
  ) {
    this.renderer.addClass(document.body, 'game-page');
    breakpointObserver.observe([
      '(max-width: 900px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
  }

  checkIsCorrect(event) {      
    if (event === true) {
      this.gameService.setGamePreQuestion(this._gameNumber);
      this.showPre = this.gameService.showGamePreQuestion(this._gameNumber);
    }
  }

  startGame() {
    window.scroll(0,0);
    this.gameService.setGameStarted(this._gameNumber);
    this.started = this.gameService.getGameStarted(this._gameNumber);
  }
}

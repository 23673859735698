<div class="content-container">
    <!-- <div class="content-pre-head" *ngIf="showPre"></div> -->
    <app-logo></app-logo>
    <div class="content content-pre" *ngIf="showPre">    
        <div>
            <h1 title="Park Świętokrzyski - zadanie lokalizacyjne">Park Świętokrzyski</h1>
            <ng-container *ngIf="gameType !== 'outdoor_team'">
                <p>
                    Przy pomniku obok fontanny widzisz dziadka z wnukiem.
                </p>
                <p>
                    <strong>– Dziadku, kim jest ten pan otoczony dziećmi?</strong>
                    <br />
                    <strong>– Ojej, nie wziąłem okularów, nie mogę przeczytać. Może Ty mógłbyś nam powiedzieć, jak brzmi jego nazwisko?</strong>
                </p>
            </ng-container>
            <ng-container *ngIf="gameType === 'outdoor_team'">
                <p>
                    Przy pomniku obok fontanny widzicie dziadka z wnukiem.
                </p>
                <p>
                    <strong>– Dziadku, kim jest ten pan otoczony dziećmi?</strong>
                    <br />
                    <strong>– Ojej, nie wziąłem okularów, nie mogę przeczytać. Może Ty mógłbyś nam powiedzieć, jak brzmi jego nazwisko?</strong>
                </p>
            </ng-container>

            <app-check-answer answer='korczak'
                (isCorrect)="checkIsCorrect($event)"></app-check-answer>
        </div>
    </div>
    
    <div class="content"*ngIf="!showPre && !started">
        <div>
            <h1 title="Park Świętokrzyski - wprowadzenie">Park Świętokrzyski</h1>
            <div class="column center" *ngIf="isMobile">
                <img src="../../assets/images/game5_black.png"
                    alt="grafika przedstawiająca czarno-białe zdjęcie mężczyzny na wózku inwalidzkim"
                    title="grafika przedstawiająca czarno-białe zdjęcie mężczyzny na wózku inwalidzkim" />
            </div>   
            <div class="column">
                <ng-container *ngIf="gameType !== 'outdoor_team'">
                    <p>
                        Spacerując po parku, spostrzegasz mężczyznę na wózku kłócącego się z ochroniarzem. 
                        Kiedy podchodzisz bliżej, słyszysz, że mężczyzna musi się dostać na 2. piętro Pałacu Kultury i Nauki, 
                        aby odebrać formularze z fundacji, jednak winda jest tymczasowo nieczynna, a ochroniarz nie chce mu pomóc. 
                        Gdy kłótnia dobiega końca, a zrezygnowany mężczyzna kieruje się w Twoją stronę, pytasz, czy możesz mu 
                        jakoś pomóc. Uradowany mężczyzna przedstawia się jako Wojciech i opowiada Ci o swoim problemie.
                    </p>
                    <p>
                        Pan Wojtek ma trudności z poruszaniem się, ponieważ kilka lat temu ucierpiał w wypadku samochodowym. 
                        Po wypadku jego sytuacja zmieniła się o 180 stopni. Ma uszkodzony kręgosłup, więc utracił możliwość 
                        chodzenia. Musiał przesiąść się na wózek inwalidzki i nauczyć się poruszać z jego pomocą. Aktualnie 
                        mieszka sam i ma duże trudności z przemieszczaniem się po mieście. Jego życie stało dosyć nudne i chciał 
                        je nieco urozmaicić. Pan Wojtek, chcąc doświadczyć czegoś nowego w życiu, zaangażował się w działalność na 
                        rzecz fundacji wspierającej osoby niepełnosprawne. Został poproszony o napisanie raportu, który ma wskazać 
                        bariery architektoniczne utrudniające osobom z niepełnosprawnościami poruszanie się w przestrzeni miejskiej. 
                    </p>
                    <p>
                        Na koniec pan Wojtek prosi Cię o przyniesienie potrzebnych formularzy i pomoc w napisaniu raportu.
                    </p>  
                </ng-container>

                <ng-container *ngIf="gameType === 'outdoor_team'">
                    <p>
                        Spacerując po parku, spostrzegacie mężczyznę na wózku kłócącego się z ochroniarzem. Kiedy
                        podchodzicie bliżej, słyszycie, że mężczyzna musi się dostać na 2. piętro Pałacu Kultury i Nauki, aby
                        odebrać formularze z fundacji, jednak winda jest tymczasowo nieczynna, a ochroniarz nie chce mu
                        pomóc. Gdy kłótnia dobiega końca, a zrezygnowany mężczyzna kieruje się w Waszą stronę, pytacie,
                        czy możecie mu jakoś pomóc. Uradowany mężczyzna przedstawia się jako Wojciech i opowiada Wam
                        o swoim problemie.
                    </p>
                    <p>
                        Pan Wojtek ma trudności z poruszaniem się, ponieważ kilka lat temu ucierpiał w wypadku
                        samochodowym. Po wypadku jego sytuacja zmieniła się o 180 stopni. Ma uszkodzony kręgosłup, więc
                        utracił możliwość chodzenia. Musiał przesiąść się na wózek inwalidzki i nauczyć się poruszać z jego
                        pomocą. Aktualnie mieszka sam i ma duże trudności z przemieszczaniem się po mieście. Jego życie
                        stało dosyć nudne i chciał je nieco urozmaicić. Pan Wojtek, chcąc doświadczyć czegoś nowego w
                        życiu, zaangażował się w działalność na rzecz fundacji wspierającej osoby niepełnosprawne. Został
                        poproszony o napisanie raportu, który ma wskazać bariery architektoniczne utrudniające osobom z
                        niepełnosprawnościami poruszanie się w przestrzeni miejskiej. 
                    </p>
                    <p>
                        Na koniec pan Wojtek prosi Was o przyniesienie potrzebnych formularzy i pomoc w napisaniu
                        raportu.                    
                    </p>                    
                </ng-container>
            </div>    
            <div class="column center" *ngIf="!isMobile">
                <img src="../../assets/images/game5_black.png" />
            </div>              
            <div class="actions">                
                <button (click)="startGame()" 
                    alt="przejście dalej"
                    title="przejście dalej"                
                    mat-raised-button 
                    color="primary">PRZEJDŹ DALEJ</button>
            </div>
        </div>
    </div>
    
    <div class="content" *ngIf="!showPre && started">
        <app-game5></app-game5>
    </div>
</div>
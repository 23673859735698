<div *ngIf="!isCompleted" [ngClass]="isMobile ? 'mobile' : ''">
    <h1 title="Hala Gwardii – zadanie">Hala Gwardii</h1>
    <ng-container *ngIf="gameType !== 'outdoor_team'">
        <p>
            Niestety okazuje się, że nie zabrałeś/aś karty płatniczej z domu, a w portfelu masz tylko 25 zł. 
            Łącznie macie więc 35 zł. Lista zakupów, którą ma przy sobie staruszka, jest zbyt długa, 
            aby wszystkie produkty były dobrej jakości. Pani Frania, dodaje, że chciałaby zjeść coś zdrowego,
             gdyż lekarz zaleca jej spożywanie indyka oraz szparagów. 
        </p>
        <p>
            Rozglądając się, zaczynasz szukać składników. Na półkach stoją produkty, które mogą się przydać do 
            zrobienia smacznego obiadu dla gości. Jeśli przekroczysz budżet, będziesz musiał/a wybrać 
            tańsze produkty. Pamiętaj też, aby wybrać przynajmniej jeden produkt z każdej półki. 
        </p>
    </ng-container>
    <ng-container *ngIf="gameType === 'outdoor_team'">
        <p>
            Niestety okazuje się, że nie zabraliście karty płatniczej z domu, a w portfelu macie tylko 25 zł. Łącznie
            macie więc 35 zł. Lista zakupów, którą ma przy sobie staruszka, jest zbyt długa, aby wszystkie
            produkty były dobrej jakości. Pani Frania, dodaje, że chciałaby zjeść coś zdrowego, gdyż lekarz zaleca
            jej spożywanie indyka oraz szparagów.
        </p>
        <p>
            Rozglądając się, zaczynacie szukać składników. Na półkach stoją produkty, które mogą się przydać do
            zrobienia smacznego obiadu dla gości. Jeśli przekroczycie budżet, będziecie musieli wybrać tańsze
            produkty. Pamiętajcie też, aby wybrać przynajmniej jeden produkt z każdej półki.
        </p>    
    </ng-container>

    <div class="flex-container">
        <div class="column">
            <div class="products"  title="półka sklepowa z umieszczonymi na niej produktami">
                <div class="wrapper">
                    <div *ngFor="let group of [1,2,3,4]"
                        class="group">
                        <div *ngFor="let product of getProductsGroup(group)"
                            class="product"
                            alt="{{ product.alt }}"
                            title="{{ product.title }}"
                            (click)="clickAdd(product.id)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="cart-products" title="koszyk sklepowy">
                <div class="wrapper">
                    <div *ngFor="let product of cart; let i = index"
                        class="product"
                        alt="{{ product.alt }}"
                        title="{{ product.title }}"
                        (click)="clickRemove(i)">
                        <img src="../../../../assets/images/products/product_{{product.id}}.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="actions">
        <div>
            <p *ngIf="message" class="incorrect">{{message}}</p>
        </div>
        <button class="button-next"
            alt="zakup produktów"
            title="zakup produktów" 
            mat-raised-button 
            (click)="goNext()"
            color="primary">KUP PRODUKTY</button>   
    </div>
</div>

<div *ngIf="isCompleted">
    <h1 title="Hala Gwardii – podsumowanie">Hala Gwardii</h1>
    <div class="column">
        <ng-container *ngIf="gameType !== 'outdoor_team'">
            <p>W podziękowaniu za pomoc kilka dni później pani Frania przesłała Ci list, napisany odręcznie na pięknej, perfumowanej papeterii.</p>
            <p class="italic">Mój drogi/Moja droga,</p>
            <p class="italic">
                dziękuję Ci za pomoc, nie wiem jak Ci się odwdzięczę! Dawno tak dobrze się nie bawiłam. To były urodziny moich marzeń! 
                Choć nie udało Ci się kupić wszystkich produktów zgodnie z moimi wskazówkami i tak przyjęcie było udane, 
                bo byli ze mną Ci, których kocham.  
            </p>
            <p class="italic">
                Niedawno dostałam tajemniczy liścik. Nie było nadawcy, tylko dopisek, abym wręczyła go osobie, która mi bezinteresownie pomoże... 
                Myślę, że chodziło o Ciebie! Wiesz, nie każdy ma w sobie tyle wrażliwości, żeby pomagać biedniejszym, a szkoda, 
                bo przecież nie wszystkich stać na produkty podstawowej potrzeby, a co dopiero na jakieś dodatkowe rzeczy! 
                Ty swoją postawą pokazałeś/aś, że można być dobrym. Nie wiem, co jest w liściku, ale mam nadzieję, że będzie to coś, co Ci pomoże!
            </p>
            <p class="italic">
                Ściskam Cię ciepło!<br />
                Franciszka            
            </p>
        </ng-container>
        <ng-container *ngIf="gameType === 'outdoor_team'">
            <p>W podziękowaniu za pomoc kilka dni później pani Frania przesłała Wam list, napisany odręcznie na pięknej, perfumowanej papeterii.</p>
            <p class="italic">Moi kochani,</p>
            <p class="italic">
                dziękuję Wam za pomoc, nie wiem jak się Wam odwdzięczę! Dawno tak dobrze się nie bawiłam. To
                były urodziny moich marzeń! Choć nie udało Wam się kupić wszystkich produktów zgodnie z moimi
                wskazówkami i tak przyjęcie było udane, bo byli ze mną Ci, których kocham.
            </p>
            <p class="italic">
                Niedawno dostałam tajemniczy liścik. Nie było nadawcy, tylko dopisek, abym wręczyła go osobie,
                która mi bezinteresownie pomoże... Myślę, że chodziło o Was! Wiecie, nie każdy ma w sobie tyle
                wrażliwości, żeby pomagać biedniejszym, a szkoda, bo przecież nie wszystkich stać na produkty
                podstawowej potrzeby, a co dopiero na jakieś dodatkowe rzeczy! Wy swoją postawą pokazaliście, że
                można być dobrym. Nie wiem, co jest w liściku, ale mam nadzieję, że będzie to coś, co Wam pomoże!
            </p>
            <p class="italic">
                Ściskam Was ciepło!<br />
                Franciszka            
            </p>
        </ng-container>       
    </div>
    <div *ngIf="!isMobile" class="column center">
        <img src="../../../../assets/images/game3.png" 
            alt="grafika przedstawiająca kolorowe zdjęcie starszej kobiety opierającej się o stół"
            title="grafika przedstawiająca kolorowe zdjęcie starszej kobiety opierającej się o stół" />
    </div>
    <div class="complete-award">
        <img *ngIf="!isHighContrast" src="../../../../assets/images/nr_8.png" 
            alt="karteczka z niebieskim numerem 8"
            title="karteczka z niebieskim numerem 8" />
        <img *ngIf="isHighContrast" src="../../../../assets/images/nr_8_u.png" 
            alt="grafika z numerem 8"
            title="grafika z numerem 8" />
    </div>
    <div class="actions">
        <button class="button-next" 
            alt="powrót do mapy"
            title="powrót do mapy"
            mat-raised-button 
            routerLink="/map"
            color="primary">WRÓĆ DO MAPY</button>   
    </div>   
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
  
  public minutes: string = "0";
  public seconds: string = "00";
  public divader: string = ":";

  private time: number = 0;
  private timer: any;

  constructor() { }

  ngOnInit(): void {
    // init time - get from cache for game
    this.timer = setInterval(() => {      
      this.time++;
      this._secondsToTimer(this.time);
    }, 1000);
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  private _secondsToTimer(sec: number) {
    let seconds = Math.ceil(sec % 60);    
    this.minutes = (Math.floor(sec / 60)).toString();    
    this.seconds = (seconds < 10)? "0" + seconds.toString() : seconds.toString()
  }
}

<div class="content-container">    
    <!-- <div class="content-pre-head" *ngIf="showPre"></div> -->
    <app-logo></app-logo>
    <div class="content content-pre" *ngIf="showPre">    
        <div>
            <h1 title="Kamienica artystyczna - zadanie lokalizacyjne">Kamienica artystyczna</h1>
            <ng-container *ngIf="gameType !== 'outdoor_team'">
                <p>
                    Przy bramie do kamienicy widzisz ucznia siedzącego na plecaku. Jest zdołowany. Podnosi oczy i pyta Cię: 
                </p>
                <p>
                    <strong>
                        – Kolejna pała z fizyki. W dodatku Anka mnie rzuciła. Przyszedłem tu, bo moi starzy zawsze powtarzają, 
                        że w tej kamienicy znaleźli doskonałą receptę na życie. Nie wiem, gdzie jej szukać. Może Ty ją znajdziesz 
                        i powiesz mi, jak ona brzmi?
                    </strong>
                </p>
            </ng-container>
            <ng-container *ngIf="gameType === 'outdoor_team'">
                <p>
                    Przy bramie do kamienicy widzicie ucznia siedzącego na plecaku. Jest zdołowany. Podnosi oczy i pyta Was:
                </p>
                <p>
                    <strong>
                        Kolejna pała z fizyki. W dodatku Anka mnie rzuciła. Przyszedłem tu, bo moi
                        starzy zawsze powtarzają, że w tej kamienicy znaleźli doskonałą receptę na życie. Nie wiem, gdzie jej
                        szukać. Może Wy ją znajdziecie i powiecie mi, jak ona brzmi?
                    </strong>
                </p>
            </ng-container>

            <app-check-answer answer='zyj kolorowo'
                (isCorrect)="checkIsCorrect($event)"></app-check-answer>
        </div>
    </div>

    <div class="content" *ngIf="!showPre && started">
        <app-game6></app-game6>
    </div>
</div>
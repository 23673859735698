<div *ngIf="!isCompleted" [ngClass]="isMobile ? 'mobile' : ''">
    <h1 title="Liceum Ogólnokształcące – zadanie">Liceum Ogólnokształcące</h1>
    <p *ngIf="gameType !== 'outdoor_team'">
        Poznaj kilka opowieści z życia Nastolatka i rozwiąż krzyżówkę. Hasła wpisz w mianowniku. Odgadnij hasło końcowe. 
        Jeżeli będziesz mieć trudności z odgadnięciem poszczególnych haseł, użyj znaku zapytania. Odkryje się wówczas 
        pierwsza litera w haśle. Postaraj się nie szukać odpowiedzi w Internecie.
    </p>
    <p *ngIf="gameType === 'outdoor_team'">
        Poznajcie kilka opowieści z życia Nastolatka i rozwiążcie krzyżówkę. Hasła wpiszcie w mianowniku.
        Odgadnijcie hasło końcowe. Jeżeli będziecie mieć trudności z odgadnięciem poszczególnych haseł,
        użyjcie znaku zapytania. Odkryje się wówczas pierwsza litera w haśle. Postarajcie się nie szukać
        odpowiedzi w Internecie.
    </p>
    <p>
        Pytania:
    </p>
    <ol>
        <li>
            Pamiętam, że gdy miałem 6 lat i bawiłem się na podwórku, nagle strasznie rozbolał mnie brzuch. 
            Z trudem wróciłem do domu. Rodzice też początkowo nie wierzyli, że sprawa jest poważna, ale w końcu zadzwonili po karetkę. 
            Pamiętam dziwne korytarze, jakichś ludzi rozmawiających nerwowo nade mną, a potem maskę i ciemność. 
            Gdy się obudziłem, nie wiedziałem, gdzie jestem ani co się stało. Długo czekałem, aż ktoś się mną zajmie i cokolwiek wytłumaczy. 
            Okazało się, że musieli wyciąć mi wyrostek robaczkowy. A byłem na jednej z sal, tutaj naprzeciwko szkoły. 
            Tak się wtedy bałem, że do dziś trudno mi się wymawia tę nazwę... To był... Warszawski... dla Dzieci. 
            Nie powiem tego słowa w środku, nie mogę. Ale wiesz, o co chodzi, prawda?
        </li>
        <li>
            Do mojej klasy chodzi taki chłopak, który jest punkiem. To jeden z tych, którzy się ze mnie naśmiewają i mi dokuczają. 
            Ma bardzo nietypową fryzurę... Jego włosy są zaczesane do góry, tworzą wąski pasek biegnący od czoła aż do karku. 
            Ta fryzura się jakoś nazywa, ale nie pamiętam jak? 
        </li>
        <li>
            Każdego dnia od poniedziałku do piątku muszę wstawać i tam przychodzić. Mam dość, nie lubię tego miejsca… Wolę uczyć się sam w domu. 
            Pandemia stała się więc dla mnie wybawieniem! Przynajmniej w domu nikt mi nie dokucza. O jakim miejscu mówię?
        </li>
        <li>
            Mój najbardziej znienawidzony przedmiot. Najbardziej nie znoszę tych zadań arytmetycznych! Mam z nimi duże trudności. 
            Innym w klasie idzie to lepiej. Z ostatniego sprawdzianu z arytmetyki jako jedyny w klasie dostałem jedynkę! Niestety, 
            nie ma mi kto pomóc… Z nerwów aż zapomniałem nazwy tego przedmiotu...
        </li>
        <li>
            Bardzo się denerwuję przed sprawdzianami i kartkówkami. Towarzyszy mi tak ogromny..., że aż nie umiem tego opisać. Nie radzę sobie z nim.
        </li>
        <li>
            Potrzebuję jej, żeby postępować zgodnie ze swoimi przekonaniami. Muszę się nią wykazać, szczególnie wtedy, 
            kiedy nawet rodzice mnie nie wspierają.
        </li>
        <li>
            Nie mam specjalnie znajomych w szkole. Prawie nikt w klasie mnie nie akceptuje, ponieważ bardzo lubię muzykę poważną, a w mojej 
            klasie praktycznie wszyscy słuchają rapu. Poza tym trochę inaczej się ubieram, w przeciwieństwie do rówieśników nie mam 
            markowych ubrań. Na koniec tego wszystkiego nie umiem się z nimi dogadać, bo mam zupełnie inne zainteresowania. 
            Chłopcy mnie odrzucają, bo nie gram w piłkę nożną, natomiast jestem dobry w piłkę wodną, której nikt z nich nie zna... 
            Oni nawet nie umieją pływać. W mojej sytuacji czuję się bardzo odosobniony. Odczuwam... 
        </li>
        <li>
            Jestem odrzucony przez klasę. Jest mi z tym bardzo źle. Rodzice ignorują moje problemy. Przez to wszystko mam bardzo zaniżoną...
        </li>
        <li>
            Stoisz obok mojej szkoły. Patronem jej jest Dąbrowski. Tylko jak on miał na imię?
        </li>
        <li>
            Tak jak już wspomniałem, uwielbiam muzykę poważną. Jednym z moich ulubionych dzieł jest cykl koncertów skrzypcowych pt. 
            “Cztery pory roku”. Czy wiesz, kto je skomponował? 
        </li>    
    </ol>

    <div class="cross">
        <div class="cross-row margin-1">
            <div class="cross-cell counter">1.</div>
            <div class="cross-cell"><input maxlength="1" type="text" value="{{ help1 }}"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell highlight"><input [(ngModel)]="password[0]" (ngModelChange)="changeM()" title="miejsce na wpisanie hasła"  maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell help" (click)="clickHelp(1);">
                <input maxlength="1" type="text" value="?" title="podpowiedź" readonly="readonly"/>
            </div>
        </div>  
        <div class="cross-row margin-3">
            <div class="cross-cell counter">2.</div>
            <div class="cross-cell"><input maxlength="1" type="text" value="{{ help2 }}"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell highlight"><input [(ngModel)]="password[1]"  maxlength="1" title="miejsce na wpisanie hasła" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell help" (click)="clickHelp(2);">
                <input maxlength="1" type="text" value="?" title="podpowiedź" readonly="readonly"/>
            </div>
        </div>
        <div class="cross-row margin-5">
            <div class="cross-cell counter">3.</div>
            <div class="cross-cell highlight"><input [(ngModel)]="password[2]" (ngModelChange)="changeM()"  title="miejsce na wpisanie hasła"  maxlength="1" type="text" value="{{ help3 }}"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell help" (click)="clickHelp(3);">
                <input maxlength="1" type="text" value="?" title="podpowiedź" readonly="readonly"/>
            </div>
        </div>
        <div class="cross-row margin-2">
            <div class="cross-cell counter">4.</div>
            <div class="cross-cell"><input maxlength="1" type="text" value="{{ help4 }}"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell highlight"><input [(ngModel)]="password[3]" (ngModelChange)="changeM()"  title="miejsce na wpisanie hasła" maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell help" (click)="clickHelp(4);">
                <input maxlength="1" type="text" value="?" title="podpowiedź" readonly="readonly"/>
            </div>
        </div>
        <div class="cross-row margin-3">
            <div class="cross-cell counter">5.</div>
            <div class="cross-cell"><input maxlength="1" type="text" value="{{ help5 }}"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell highlight"><input [(ngModel)]="password[4]" (ngModelChange)="changeM()"  title="miejsce na wpisanie hasła" maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell help" (click)="clickHelp(5);">
                <input maxlength="1" type="text" value="?" title="podpowiedź" readonly="readonly"/>
            </div>
        </div>
        <div class="cross-row margin-2">
            <div class="cross-cell counter">6.</div>
            <div class="cross-cell"><input maxlength="1" type="text" value="{{ help6 }}"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell highlight"><input [(ngModel)]="password[5]" (ngModelChange)="changeM()"  title="miejsce na wpisanie hasła"  maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell help" (click)="clickHelp(6);">
                <input maxlength="1" type="text" value="?" title="podpowiedź" readonly="readonly"/>
            </div>        
        </div>
        <div class="cross-row">
            <div class="cross-cell counter">7.</div>
            <div class="cross-cell"><input maxlength="1" type="text" value="{{ help7 }}"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell highlight"><input [(ngModel)]="password[6]" (ngModelChange)="changeM()"  title="miejsce na wpisanie hasła" maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell help" (click)="clickHelp(7);">
                <input maxlength="1" type="text" value="?" title="podpowiedź" readonly="readonly"/>
            </div>
        </div>
        <div class="cross-row">
            <div class="cross-cell counter">8.</div>
            <div class="cross-cell"><input maxlength="1" type="text" value="{{ help8 }}"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell highlight"><input [(ngModel)]="password[7]"(ngModelChange)="changeM()"  title="miejsce na wpisanie hasła" title="miejsce na wpisanie hasła"  maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell help" (click)="clickHelp(8);">
                <input maxlength="1" type="text" value="?" title="podpowiedź" readonly="readonly"/>
            </div>
        </div>
        <div class="cross-row margin-5">
            <div class="cross-cell counter">9.</div>
            <div class="cross-cell highlight"><input [(ngModel)]="password[8]" (ngModelChange)="changeM()" title="miejsce na wpisanie hasła"  maxlength="1"  value="{{ help9 }}" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell help" (click)="clickHelp(9);">
                <input maxlength="1" type="text" value="?" readonly="readonly"/>
            </div>
        </div>
        <div class="cross-row margin-2">
            <div class="cross-cell counter">10.</div>
            <div class="cross-cell"><input maxlength="1" type="text" value="{{ help10 }}"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell highlight"><input [(ngModel)]="password[9]" (ngModelChange)="changeM()" maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell"><input maxlength="1" type="text"/></div>
            <div class="cross-cell help" (click)="clickHelp(10);">
                <input maxlength="1" type="text" value="?" title="podpowiedź" readonly="readonly"/>
            </div>
        </div>
    </div>

    <div class="actions" *ngIf="showGoNext">
        <button class="button-next" 
            mat-raised-button 
            (click)="goNext()"
            color="primary">PRZEJDŹ DALEJ</button>   
    </div>   

</div>

<div *ngIf="isCompleted">
    <h1 title="Liceum Ogólnokształcące – podsumowanie">Liceum Ogólnokształcące</h1>
    <div class="column">
        <p *ngIf="gameType !== 'outdoor_team'">
            Krzyżówka rozwiązana! Cieszę się, że poświęciłeś/aś mi chwilę. Poznałeś/aś trochę to, z czym się mierzę. 
            Hasło tej krzyżówki jest dla mnie bardzo ważne. Brakuje jej w moim otoczeniu. Proszę, abyś Ty o tej 
            ważnej wartości nie zapominał/a! A, jeszcze jedno! Kilka dni temu dostałem dziwnego smsa z kolorową 
            liczbą. Może będziesz wiedzieć, co z nią zrobić.
        </p>
        <p *ngIf="gameType === 'outdoor_team'">
            Krzyżówka rozwiązana! Cieszę się, że poświęciliście mi chwilę. Poznaliście trochę to, z czym się
            mierzę. Hasło tej krzyżówki jest dla mnie bardzo ważne. Brakuje jej w moim otoczeniu. Proszę,
            abyście Wy o tej ważnej wartości nie zapominali! A, jeszcze jedno! Kilka dni temu dostałem dziwnego
            smsa z kolorową liczbą. Może będziecie wiedzieć, co z nią zrobić.
        </p>        
    </div>
    <div *ngIf="!isMobile" class="column center">
        <img src="../../../../assets/images/game1.png"
            alt="grafika przedstawiająca kolorowe zdjęcie smutnego nastolatka, w tle śmiejąca się z niego dziewczyna"
            title="grafika przedstawiająca kolorowe zdjęcie smutnego nastolatka, w tle śmiejąca się z niego dziewczyna" />
    </div>
    <div class="complete-award">
        <img *ngIf="!isHighContrast" class="phone" src="../../../../assets/images/nr_10.png" 
            alt="grafika przedstawiająca telefon z wyróżnioną cyfrą 10 w kolorze zielonym"
            title="grafika przedstawiająca telefon z wyróżnioną cyfrą 10 w kolorze zielonym" />
        <img *ngIf="isHighContrast" class="phone" src="../../../../assets/images/nr_10_u.png" 
            alt="grafika przedstawiająca cyfrę 10"
            title="grafika przedstawiająca cyfrę 10" />
    </div>
    <div class="actions">
        <button class="button-next" 
            alt="powrót do mapy"
            title="powrót do mapy"
            mat-raised-button 
            routerLink="/map"
            color="primary">WRÓĆ DO MAPY</button>   
    </div>   
</div>
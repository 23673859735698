<div class="content-container">
    <!-- <div class="content-pre-head" *ngIf="showPre"></div> -->
    <app-logo></app-logo>
    <div class="content content-pre" *ngIf="showPre">
        <div>
            <h1 title="Hala Gwardii – zadanie lokalizacyjne">Hala Gwardii</h1>
            <ng-container *ngIf="gameType !== 'outdoor_team'">
                <p>
                    Zatrzymujesz się przy jednym ze straganów. Sprzedawca siedzi na stołku i rozwiązuje krzyżówkę. Nagle Cię spostrzega i pyta:
                </p>
                <p>
                    <strong>– Wisi nad wejściem do Hali, potrzebna w wybieraniu, jedzeniu i liczeniu. Co to może być?</strong>
                </p>
            </ng-container>
            <ng-container *ngIf="gameType === 'outdoor_team'">
                <p>
                    Zatrzymujecie się przy jednym ze straganów. Sprzedawca siedzi na stołku i rozwiązuje krzyżówkę. Nagle Was spostrzega i pyta:
                </p>
                <p>
                    <strong>– Wisi nad wejściem do Hali, potrzebna w wybieraniu, jedzeniu i liczeniu. Co to może być?</strong>
                </p>
            </ng-container>

            <app-check-answer answer='glowa'
                (isCorrect)="checkIsCorrect($event)"></app-check-answer>
        </div>
    </div>

    <div class="content"*ngIf="!showPre && !started">
        <div>
            <h1 title="Hala Gwardii – wprowadzenie">Hala Gwardii</h1>
            <div class="column center" *ngIf="isMobile">
                <img src="../../assets/images/game3_black.png"
                    alt="grafika przedstawiająca czarno-białe zdjęcie starszej kobiety opierającej się o stół"
                    title="grafika przedstawiająca czarno-białe zdjęcie starszej kobiety opierającej się o stół"/>
            </div>
            <div class="column">
                <ng-container *ngIf="gameType !== 'outdoor_team'">
                    <p>
                        Wchodząc do jednego ze sklepów, spotykasz panią Franię, staruszkę mieszkającą w okolicy.
                        Jest bardzo smutna. Podchodzisz do niej i pytasz, co się stało. Pani Frania opowiada,
                        że w niedzielę będzie obchodzić 80. urodziny i chciała z tej okazji wyprawić obiad,
                        na który zaprosiłaby ukochanego wnuka z rodzicami. Miała odłożone jakieś pieniądze.
                        Niestety lekarz przepisał jej nowe leki na serce i prawie wszystko wydała na lekarstwa.
                        W portfelu zostało jej tylko 10 zł.  Chcesz pomóc pani Frani, decydujesz się podzielić
                        z nią swoimi pieniędzmi.
                    </p>
                </ng-container>

                <ng-container *ngIf="gameType === 'outdoor_team'">
                    <p>
                        Wchodząc do jednego ze sklepów, spotykacie panią Franię, staruszkę mieszkającą w okolicy. Jest
                        bardzo smutna. Podchodzicie do niej i pytacie, co się stało. Pani Frania opowiada, że w niedzielę
                        będzie obchodzić 80. urodziny i chciała z tej okazji wyprawić obiad, na który zaprosiłaby ukochanego
                        wnuka z rodzicami. Miała odłożone jakieś pieniądze. Niestety lekarz przepisał jej nowe leki na serce i
                        prawie wszystko wydała na lekarstwa. W portfelu zostało jej tylko 10 zł. Chcecie pomóc pani Frani,
                        decydujecie się podzielić z nią Waszymi pieniędzmi.
                    </p>
                </ng-container>
            </div>
            <div class="column center" *ngIf="!isMobile">
                <img src="../../assets/images/game3_black.png"
                    alt="grafika przedstawiająca czarno-białe zdjęcie starszej kobiety opierającej się o stół"
                    title="grafika przedstawiająca czarno-białe zdjęcie starszej kobiety opierającej się o stół" />
            </div>
            <div class="actions">
                <button (click)="startGame()"
                    alt="przejście dalej"
                    title="przejście dalej"
                    mat-raised-button
                    color="primary">PRZEJDŹ DALEJ</button>
            </div>
        </div>
    </div>

    <div class="content" *ngIf="!showPre && started">
        <app-game3></app-game3>
    </div>
</div>

<div class="content-container">
    <!-- <div class="content-pre-head" *ngIf="showPre"></div> -->
    <app-logo></app-logo>
    <div class="content content-pre" *ngIf="showPre">
        <div>
            <h1 title="Ogród Saski – zadanie lokalizacyjne">Ogród Saski</h1>
            <ng-container *ngIf="gameType !== 'outdoor_team'">
                <p>
                    W Ogrodzie Saskim mijasz malarza, który uwiecznia pędzlem fontannę. Nagle krzyczy do Ciebie:
                </p>
                <p>
                    <strong>– Pomożesz mi? Możesz sprawdzić, ile jest figurek, z których tryska woda? Muszę je zaznaczyć na obrazie, a nie mogę odejść, by nie stracić perspektywy!</strong>
                </p>
            </ng-container>
            <ng-container *ngIf="gameType === 'outdoor_team'">
                <p>
                    W Ogrodzie Saskim mijacie malarza, który uwiecznia pędzlem fontannę. Nagle krzyczy do Was:
                </p>
                <p>
                    <strong>– Pomożecie mi? Możecie sprawdzić, ile jest figurek, z których tryska woda? Muszę je zaznaczyć na obrazie, a nie mogę odejść, by nie stracić perspektywy!</strong>
                </p>
            </ng-container>

            <app-check-answer answer='cztery/4'
                (isCorrect)="checkIsCorrect($event)"></app-check-answer>
        </div>
    </div>

    <div class="content"*ngIf="!showPre && !started">
        <div>
            <h1 title="Ogród Saski – wprowadzenie">Ogród Saski</h1>
            <div class="column center" *ngIf="isMobile">
                <img src="../../assets/images/game4_black.png"
                    alt="grafika przedstawiająca czarno-białe zdjęcie smutnego chłopca"
                    title="grafika przedstawiająca czarno-białe zdjęcie smutnego chłopca" />
            </div>
            <div class="column">
                <ng-container *ngIf="gameType === 'outdoor_team'">
                    <p>
                        Stojąc przy ławce, usłyszeliście rozmowę dwóch szóstoklasistów – Kuby i Piotrka. Kilka dni temu Kuba
                        opowiedział Piotrkowi o tym, że zakochał się w ich koleżance z klasy. Teraz wstydzi się tego i żałuje,
                        że opowiedział o tym koledze. Piotrek grozi Kubie, że wygada to klasie, jak Kuba mu nie zapłaci.
                    </p>
                    <p>
                        Kuba nie może opowiedzieć o tym ani rodzicom, ani nauczycielom, bo inaczej Piotrek będzie chciał się
                        zemścić lub go wyśmieje. Zmartwiony sytuacją Kuba ucieka, a na ławce zostaje sam Piotrek.
                    </p>
                    <p>
                        Postanawiacie zainterweniować.
                    </p>
                </ng-container>

                <ng-container *ngIf="gameType !== 'outdoor_team'">
                    <p>
                        Stojąc przy ławce, usłyszałeś/aś rozmowę dwóch szóstoklasistów – Kuby i Piotrka. Kilka dni temu Kuba
                        opowiedział Piotrkowi o tym, że zakochał się w ich koleżance z klasy. Teraz wstydzi się tego i
                        żałuje, że opowiedział o tym koledze.  Piotrek grozi Kubie, że wygada to klasie, jak Kuba mu nie zapłaci.
                    </p>
                    <p>
                        Kuba nie może opowiedzieć o tym ani rodzicom, ani nauczycielom, bo inaczej Piotrek będzie chciał się
                        zemścić lub go wyśmieje. Zmartwiony sytuacją Kuba ucieka, a na ławce został sam Piotrek.
                    </p>
                    <p>
                        Postanawiasz zainterweniować.
                    </p>
                </ng-container>
            </div>
            <div class="column center" *ngIf="!isMobile">
                <img src="../../assets/images/game4_black.png"
                    alt="grafika przedstawiająca czarno-białe zdjęcie smutnego chłopca"
                    title="grafika przedstawiająca czarno-białe zdjęcie smutnego chłopca"/>
            </div>
            <div class="actions">
                <button (click)="startGame()"
                    alt="przejście dalej"
                    title="przejście dalej"
                    mat-raised-button
                    color="primary">PRZEJDŹ DALEJ</button>
            </div>
        </div>
    </div>

    <div class="content" *ngIf="!showPre && started">
        <app-game4></app-game4>
    </div>
</div>

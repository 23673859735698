<div class="content-page-header">
    <app-landing-header [isHighContrast]=isHighContrast></app-landing-header>
    <div class="title title-info">
        <h1></h1>
    </div>
</div>
<div class="content-page">
    <h2>O Grze o Wolność</h2>
    <p>
        Gra o Wolność to projekt edukacyjny, popularyzujący wiedzę o Janie Pawle II oraz jego 
        wkładzie w kształtowanie się wolnej Polski i świata, realizowany dorocznie w formule gry 
        miejskiej lub online.<br />
        Korzenie Gry o Wolność sięgają jesieni 2017 roku. Wtedy to, z okazji 99. rocznicy odzyskania 
        Niepodległości, jako Centrum Myśli Jana Pawła II zaprosiliśmy Stypendystów m. st. Warszawy im. 
        Jana Pawła II do stworzenia gry miejskiej dla warszawiaków, która miała być okazją 
        do wspólnego uczczenia tej ważnej dla Polaków daty. Stypendyści dzięki projektowi mogli 
        nabyć cenne umiejętności pracy w grupie i metodą projektu. Był to też doskonały sposób 
        na integrację grupy. Ze względu na bardzo dobry odbiór projektu zarówno przez jego twórców 
        – Stypendystów, jak i warszawiaków, którzy uczestniczyli w grze postanowiliśmy nie poprzestać 
        na jednej edycji. Tak zrodziła się tradycja dorocznej Gry o Wolność, realizowanej przez 
        Stypendystów dla mieszkańców Warszawy i nie tylko, edycje gry z lat 2020 i 2021 dostępne 
        były online, a więc ich zasięg sięgał daleko poza Warszawę.<br />
        Choć temat przewodnim Gry niezmiennie jest wolność, co roku Stypendyści podejmują 
        nad nim rozważania z nieco innej perspektywy. Za każdym razem nowa grupa Stypendystów 
        od początku do końca planuje i tworzy grę, zaczynając od fabuły, poprzez mechanikę gry, 
        aż po animację poszczególnych stacji. Jakie były tematy szczegółowe każdej z gier? 
        Można zobaczyć na stronie Gry.
    </p>
</div>

<app-landing-footer [isHighContrast]=isHighContrast></app-landing-footer>
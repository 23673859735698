import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessibilityService {  
  public className = new Subject<string>();
  public contrastSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public setContrast(value: boolean) {
    this.contrastSubject.next(value);
  }

  onContrast(): Observable<any> {
    return this.contrastSubject.asObservable();
  }

  public read(key: string) {
    let read = localStorage.getItem(key);
    if (read === undefined) {
      return false;
    } 
    return (read === 'true');
  }

  public write(key: string, data: any) {
    localStorage.setItem(key, data);
  }

  changeClassName(name: string) {
    this.className.next(name);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-game2021',
  templateUrl: './game2021.component.html',
  styleUrls: ['./game2021.component.scss']
})
export class Game2021Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="game-menu">
    <div class="left-side">
        <h1>Gra o Wolność</h1>
        <h2>edycja 2020</h2>
    </div>
    <div class="center-side">
        <p>
            Rozszerzona wersja Gry z 2019 roku dostępna w wersji online, która poza okazją do poznania wydarzeń podróży
            papieskiej do Polski z 1979 roku pozwala graczom również usłyszeć samego Jana Pawła II i zobaczyć jak
            na jego słowa wówczas reagowaliśmy.
        </p>
    </div>
    <div class="right-side">
      <a mat-raised-button
        class="button"
        routerLink="/gra-2020"
        alt="przejście do szerszego opisu edycji"
        title="przejście do szerszego opisu edycji"
        alt="przejście do szerszego opisu edycji"
        color="primary">Dowiedz się więcej</a>
        <!-- <a mat-raised-button
            class="button"
            href="http://edycja2020.graowolnosc.pl/"
            alt="przejście do gry edycja 2020"
            title="przejście do gry edycja 2020"
            color="primary"
            target="_blank">Przejdź do gry</a> -->
    </div>
</div>
